import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';

//CSS
import '../../../assets/css/ProductoStandart.css';


//Componentes
import Consulta from '../../elementos/Consulta';
import Footer from '../../layout/Footer';

//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto.png';
import piloto from '../../../assets/img/productos/piloto.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import hr from '../../../assets/img/productos/hr.png';

class ProductoStandart extends Component {
    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={piloto} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>Piloto Automático</h1>
                                    <img src={hr} alt="" style={{marginTop: "10px"}}/>
                                    <p>Más tecnología, más rendimiento, más confiable.</p>
                                    <p className="text-small">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor
                                        incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute
                                        iure reprehenderit in voluptate velit esse cillum dolore.
                                    </p>
                                </div>

                            </Col>
                        </Row>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={dobleProducto} alt=""/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>Precisión</h3>
                                        <br/>
                                        <img src={hr} alt=""/>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
                                            assumenda
                                            cum
                                            cupiditate dolorum ducimus eligendi quia, quibusdam quis ullam vel! A
                                            aliquam
                                            esse
                                            libero, minima officia ullam. Aspernatur, dolorum, exercitationem!
                                        </p>
                                    </div>

                                    <div className="caracteristicas-descripcion" style={{marginTop:"60px"}}>
                                        <h3>Precisión</h3>
                                        <br/>
                                        <img src={hr} alt=""/>
                                        <p className="sinBorde">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
                                            assumenda
                                            cum
                                            cupiditate dolorum ducimus eligendi quia, quibusdam quis ullam vel! A
                                            aliquam
                                            esse
                                            libero, minima officia ullam. Aspernatur, dolorum, exercitationem!
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="descargas bgRed">
                            <Row>
                                <Col md={6} style={{borderRight: "1px solid white"}}>
                                    <div className="btnDescarga">
                                        <img src={btnDescarga} alt=""/>
                                        <div className="dscDescarga">
                                            <h3>Guía Rápida</h3>
                                            <h5>Mapeador / Corte automático de secciones</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="btnDescarga">
                                        <img src={btnDescarga} alt=""/>
                                        <div className="dscDescarga">
                                            <h3>Manual de Uso</h3>
                                            <h5>Piloto automático y computadora de aplicación.</h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <Consulta bgColor="white" color="#ec0928"/>

                </section>
            </div>
        )
    }
}

export default ProductoStandart;