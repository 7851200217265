import React, {Component} from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Scrollchor from 'react-scrollchor';
import '../../../assets/css/Producto.css';
//Import Plugins
import {Row, Col, Button, Accordion, Panel} from 'react-bootstrap';
import {Link} from 'react-router-dom';


//Import Images
import hr from '../../../assets/img/productos/hr.png';
import piloto from '../../../assets/img/productos/piloto.png';
import dwWing from '../../../assets/img/dwWing.png';
import logo25 from '../../../assets/img/logo25prod.png';


import icon1 from '../../../assets/img/productos/piloto/icon1.png';
import icon2 from '../../../assets/img/productos/piloto/icon2.png';
import icon3 from '../../../assets/img/productos/piloto/icon3.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/piloto/foto1.png';
import foto2 from '../../../assets/img/galeria/piloto/foto2.png';
import foto3 from '../../../assets/img/galeria/piloto/foto3.png';
import foto4 from '../../../assets/img/galeria/piloto/foto4.png';

//Import Components
import Galeria from '../../elementos/Galeria';

import SliderFinan from "../../elementos/SliderFinan";
import Iconos from "../../elementos/Iconos";
import Faqs from "../../elementos/Faqs";


import tractor from '../../../assets/img/valores/pilotoAutomatico/tractor.mp4';
import cosecha from '../../../assets/img/valores/pilotoAutomatico/cosecha.mp4';
import pulveri from '../../../assets/img/valores/pilotoAutomatico/pulve.mp4';
import pilot2 from '../../../assets/img/valores/pilotoAutomatico/valor2.gif';
import valor3 from '../../../assets/img/valores/pilotoAutomatico/valor3.jpg';
import valor3_1 from '../../../assets/img/valores/pilotoAutomatico/valor3_1.jpg';


import video from '../../../assets/img/valores/pilotoAutomatico/video.mp4';
import play from '../../../assets/img/play.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaGrey from '../../../assets/img/productos/btnDescargaGrey.png';
import BarraOportunidad from "../../elementos/oportunidad/BarraOportunidad";
import ModalOportunidad from "../../elementos/oportunidad/ModalOportunidad";
import BannerFinanciacion from "../../elementos/financiacion/BannerFinanciacion";
import ModalFinanciacion from "../../elementos/financiacion/ModalFinanciacion";
import Dudas from "../../elementos/dudas/Dudas";
import ModalDudas from "../../elementos/dudas/ModalDudas";


const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];


class PilotoAutomatico extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombre: "Tractor",
            // link: "https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-b6faf52a-9c14-4332-a23b-2cec32dd9942",
            precio: "U$D 10.900",
            video: cosecha,
            showModalOportunidad: false,
            showModalFinanciacion: false,
            showModalDudas: false,
        }
    }


    componentDidMount() {
        document.title = "Piloto automático - Sensor Tecnología";
        window.scrollTo(0, 0);
    }


    changePrecio(tipo) {
        this.refs.precio.classList.remove("wow");
        this.refs.precio.classList.remove("animated");
        this.refs.precio.classList.remove("fadeIn");
        this.refs.precio.classList.add("desaparecer");
        setTimeout(() => {
            this.refs.precio.classList.remove("desaparecer");
            this.refs.precio.classList.add("wow");
            this.refs.precio.classList.add("animated");
            this.refs.precio.classList.add("fadeIn");
        }, 100);

        switch (tipo) {
            case 1:
                this.setState({
                    nombre: "Tractor",
                    // link: "https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-b6faf52a-9c14-4332-a23b-2cec32dd9942",
                    precio: "U$D 10.900"
                });
                break;
            case 2:
                this.setState(
                    {
                        nombre: "Cosechadora",
                        // link: "https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-0d6ed4f2-246b-4601-8972-67b4e856508a",
                        precio: "U$D 10.900"
                    });
                break;
            case 3:
                this.setState(
                    {
                        nombre: "Pulverizadora",
                        // link: "https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-4b5169a8-3555-4919-9f26-a38dae28ad81",
                        precio: "U$D 7.900"
                    });
                break;
        }
    }

    changeVideo(e, tipo) {
        if (e != null) {
            e.preventDefault();
        }
        switch (tipo) {
            case 1:
                this.setState({
                    video: cosecha
                });
                break;
            case 2:
                this.setState(
                    {
                        video: tractor
                    });
                break;
            case 3:
                this.setState(
                    {
                        video: pulveri
                    });
                break;
        }
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    obtengaElSuyo() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }


    handleCloseOportunidad() {
        this.setState({showModalOportunidad: false});
    }

    handleShowOportunidad() {
        this.setState({showModalOportunidad: true});
    }

    handleCloseFinanciacion() {
        this.setState({showModalFinanciacion: false});
    }

    handleShowFinanciacion() {
        this.setState({showModalFinanciacion: true});
    }

    handleCloseDudas() {
        this.setState({showModalDudas: false});
    }

    handleShowDudas() {
        this.setState({showModalDudas: true});
    }

    render() {
        let maquina = this.state;

        //Faqs
        let faqs = [];
        faqs.push({
            pregunta: this.context.t('¿Se puede comprar el producto sin precisión y luego comprar la precisión aparte?'),
            respuesta: this.context.t('Si, puede comprar la precisión aparte.')
        });
        faqs.push({
            pregunta: this.context.t('¿Cómo se puede adquirir el abono de precisión?'),
            respuesta: this.context.t('El servicio puede ser adquirido de forma trimestral, semestral o anual.')
        });
        faqs.push({
            pregunta: this.context.t('¿Qué alteraciones se le deben hacer a la máquina para colocar el producto?'),
            respuesta: this.context.t('Ninguna, la máquina queda 100% original, solamente se agregan partes que pueden retirarse a voluntad.')
        });
        faqs.push({
            pregunta: this.context.t('¿El mejor piloto automático es el eléctrico?'),
            respuesta: this.context.t('No, las grandes empresas fabricantes de máquinas de todo el mundo están optando y adoptando el uso de pilotos automáticos hidráulicos, debido a la facilidad de operación, los excelentes resultados de funcionamiento y el bajo costo de mantenimiento.')
        });
        faqs.push({
            pregunta: this.context.t('¿El piloto hidráulico es caro?'),
            respuesta: this.context.t('No, el sistema hidráulico es más barato que los mejores sistemas eléctricos, y su funcionamiento es superior en muchos aspectos, como la durabilidad, simplicidad de operación y funcionamiento en general.')
        });
        faqs.push({
            pregunta: this.context.t('¿Se puede trasladar el piloto automático de un implemento al otro?'),
            respuesta: this.context.t('Si, pero lo más viable es sólo trasladar partes móviles (pantalla y antena) porque es rápido y no requiere de regulaciones y calibraciones extra. Pero para llegar a este nivel es necesario adquirir lo que se denomina “kit para traslado”.')
        });
        faqs.push({
            pregunta: this.context.t('¿En qué me beneficia trabajar con un piloto automático?'),
            respuesta: this.context.t('El rendimiento productivo y la calidad de trabajo siempre mejoran utilizando esta herramienta. Inclusive se puede calcular y cuantificar los ahorros que produce de manera tal que usted puede saber cuando amortiza la inversión.')
        });
        faqs.push({
            pregunta: this.context.t('¿El piloto hidráulico funciona MEJOR?'),
            respuesta: this.context.t('Si, las diferencias técnicas en lo referido a la operatividad son las siguientes:' + '<br/><ul><li>Sus regulaciones y calibraciones no se limitan al giro del volante.</li>' +
                '<li>Sus maniobras son mecánicamente más precisas y suaves.</li>' +
                '<li>La respuesta es más exacta y suave.</li>' +
                '<li>Produce un menor desgaste de componentes.</li>' +
                '<li>Requiere menor interacción con partes dentro de cabina.</li>' +
                '</ul>')
        });
        faqs.push({
            pregunta: this.context.t('El valor del producto varía dependiendo de la máquina en dónde lo ponés'),
            respuesta: this.context.t('No. Existe una confusión sobre este tema, que radica en la precisión de la señal.\n' +
                'La precisión es uno de los determinantes de valor más importantes que los sistemas poseen.\n')
        });

        faqs.push({
            pregunta: this.context.t('¿La señal del piloto funciona en toda la Argentina?'),
            respuesta: this.context.t('Si, existen lugares que por cuestiones geográficas reciben una señal de menor potencia, pero nunca sin señal.')
        });
        faqs.push({
            pregunta: this.context.t('¿El piloto automático deja sin trabajo a la gente?'),
            respuesta: this.context.t('No, el piloto automático es una herramienta, no un robot. Al no funcionar de manera totalmente autónoma en todo el proceso, no reemplaza al usuario. El piloto automático no sabe sembrar. Existe un operador que debe indicarle cómo hacer el trabajo, configurarlo y cargarle información para que funcione correctamente, activarlo y desactivarlo cuando sea necesario.')
        });

        return (
            <div>
                <section className="productos">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={piloto} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Piloto Automático')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Más tecnología, más precisión, más resultados.')}
                                    </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Sistema automático de control, monitoreo y conducción.')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('Es un sistema de simple operación, cómodo y de gran ayuda en la tareas diarias. Diseñado con los recursos más innovadores del mundo, aplicando lo último en tecnología. Su máquina no será alterada, ni deberá adquirir ningún otro accesorio.')}
                                    </p>
                                    {/*<p className="seleccion-maquina">Seleccione el tipo de máquina</p>*/}

                                    {/*<div className="selector-precio limite-tit">*/}
                                        {/*<Col xs={4} md={4}>*/}
                                            {/*<div>*/}
                                                {/*<p onClick={() => this.changePrecio(1)}*/}
                                                   {/*style={maquina.nombre == "Tractor" ? {*/}
                                                       {/*color: "red",*/}
                                                       {/*fontWeight: "500"*/}
                                                   {/*} : {color: "", fontWeight: "100"}}>*/}
                                                    {/*Tractor*/}
                                                {/*</p>*/}
                                            {/*</div>*/}
                                        {/*</Col>*/}
                                        {/*<Col xs={4} md={4} className="no-border-left">*/}
                                            {/*<div>*/}
                                                {/*<p onClick={() => this.changePrecio(2)}*/}
                                                   {/*style={maquina.nombre == "Cosechadora" ? {*/}
                                                       {/*color: "red",*/}
                                                       {/*fontWeight: "500"*/}
                                                   {/*} : {color: "", fontWeight: "100"}}>*/}
                                                    {/*Cosechadora*/}
                                                {/*</p>*/}
                                            {/*</div>*/}
                                        {/*</Col>*/}
                                        {/*<Col xs={4} md={4}>*/}
                                            {/*<div>*/}
                                                {/*<p onClick={() => this.changePrecio(3)}*/}
                                                   {/*style={maquina.nombre == "Pulverizadora" ? {*/}
                                                       {/*color: "red",*/}
                                                       {/*fontWeight: "500"*/}
                                                   {/*} : {color: "", fontWeight: "100"}}>*/}
                                                    {/*Pulverizador*/}
                                                {/*</p>*/}
                                            {/*</div>*/}
                                        {/*</Col>*/}
                                    {/*</div>*/}
                                    <div className="precios limite-tit">
                                        <Row>
                                            {/*<Col sm={6} md={6} style={{padding: "0px"}}>*/}
                                                {/*<h2 className="precio" ref="precio"*/}
                                                    {/*data-wow-duration="3s">{this.context.t('Desde ')}*/}
                                                    {/*<span>{this.context.t(maquina.precio)}</span>*/}
                                                {/*</h2>*/}
                                                {/*<p className="referencia">*/}
                                                    {/*<i>*/}
                                                        {/*+IVA. Los valores expresados están sujetos a modificaciones según corresponda.*/}
                                                    {/*</i>*/}
                                                {/*</p>*/}
                                            {/*</Col>*/}
                                            <Col xs={12} md={6} className="no-padding-right">
                                                <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                                    <Button style={{marginTop: "20px"}}>
                                                        {this.context.t('Realice su consulta')}
                                                    </Button>
                                                </Link>
                                                <Scrollchor to="financiacion" disableHistory={true}
                                                            beforeAnimate={() => this.obtengaElSuyo()}>
                                                    <p className="sub-button" style={{
                                                        color: "#676868",
                                                        fontFamily: "Myriad Pro",
                                                        fontWeight: "100",
                                                        fontSize: "17px"
                                                    }}>
                                                        Obtenga el suyo
                                                    </p>
                                                </Scrollchor>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    {/*<ModalOportunidad*/}
                        {/*show={this.state.showModalOportunidad}*/}
                        {/*onHide={() => this.handleCloseOportunidad()}*/}
                    {/*/>*/}
                    {/*<BarraOportunidad onClick={() => this.handleShowOportunidad()}/>*/}
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>

                    <div id="caracteristicas" className="productos-caracteristicas destacado">
                        <div className="container-limit">
                            <Row>
                                <Col md={8}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Precisión')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Permite elegir y ajustar precisiones con un margen de error desde 15 hasta 2 cm. siendo posible utilizar abono bajo la modalidad PPU “Pague por uso”.')}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="caracteristicas-descripcion section-video">
                                <Row>
                                    <Col md={3} sm={6} xs={12} className="border-box col-md-push-7 col-sm-push-6">
                                        <ul className="selector-video">
                                            <li className={maquina.video == cosecha ? "video-selected" : ""}>
                                                <a href="" onClick={(e) => this.changeVideo(e, 1)}>
                                                    COSECHADORA
                                                </a>
                                            </li>
                                            <li className={maquina.video == tractor ? "video-selected" : ""}>
                                                <a href=""
                                                   onClick={(e) => this.changeVideo(e, 2)}>
                                                    TRACTOR
                                                </a>
                                            </li>
                                            <li className={maquina.video == pulveri ? "video-selected" : ""}>
                                                <a href=""
                                                   onClick={(e) => this.changeVideo(e, 3)}>
                                                    PULVERIZADOR
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>

                                    <Col md={7} sm={6} xs={12} className="video-container col-md-pull-3 col-sm-pull-6">
                                        <video className="img-responsive" preload autoPlay loop src={maquina.video}
                                               type="video/mp4">
                                        </video>
                                    </Col>

                                </Row>
                            </div>


                            <div className="red-line3" style={{margin: "80px auto"}}></div>


                            <Row>
                                <Col md={6} sm={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Adaptabilidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Adaptable a todo tipo de máquinas (cosechadoras, tractores, pulverizadores, fertilizadores, picadoras).')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={pilot2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <div className="red-line3" style={{margin: "80px auto"}}></div>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Innovación')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Sistema electrohidráulico TRASLADABLE, el sistema más preciso utilizado y recomendado en el mundo.')}
                                        </p>
                                        <p>
                                            {this.context.t('Permite su instalación sin necesidad de alterar la máquina o adquirir ningún otro accesorio (como motores o engranajes).')}
                                        </p>
                                        <p>
                                            {this.context.t('Incluye todo lo necesario, como mangueras, acoples y soportes.')}
                                        </p>

                                        <Row>
                                            <Col md={8} className="center-block">
                                                <img className="img-responsive" src={valor3_1} alt=""/>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>


                        </div>
                        <Iconos
                            icon1={icon1}
                            icon2={icon2}
                            icon3={icon3}
                            text1={this.context.t('Ahorro de hasta un 20% de costos de insumos, por campaña.')}
                            text2={this.context.t('Mayor precisión en las maniobras y operaciones.')}
                            text3={this.context.t('Retorno de la inversión en sólo un año de trabajo (ahorro por cada hectárea realizada).')}
                        />
                    </div>


                    <Row>
                        <div className="video-guia" style={{display: "flex"}}>
                            <Col md={7} className="film-container">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()} controls={true}
                                       type="video/mp4"
                                       style={{minHeight: "474px"}}>
                                </video>
                            </Col>

                            <Col md={5}>
                                <div className="descargas bgGrey" style={{height: "100%"}}>
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/piloto/MM-BRCH-000006 - Brochure PC-MODU-VTWAVECAP.V.00 - ESP.pdf"}>
                                        <div style={{height: "50%"}} className="btnDescarga">
                                            <img src={btnDescargaGrey} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Piloto Automático')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/piloto/MM-MANU-000029 - Manual de Usuario T-Wave (Completo) Tractor.pdf"}>
                                        <div style={{height: "50%"}} className="bgGrisOsc">
                                            <div className="btnDescarga">
                                                <img src={btnDescarga} alt=""/>
                                                <div className="dscDescarga">
                                                    <h3>{this.context.t('Manual de Uso')}</h3>
                                                    <h5>{this.context.t('Piloto Automático')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>
                    <Faqs faqs={faqs} noMostrarConsulta={false}/>
                    {/*<ModalDudas show={this.state.showModalDudas}*/}
                    {/*onHide={() => this.handleCloseDudas()}/>*/}
                    {/*<Row style={{backgroundColor: "#676868"}}>*/}
                    {/*<div className="container-limit">*/}
                    {/*<Dudas openModalLlamamos={() => this.handleShowDudas()}/>*/}
                    {/*</div>*/}
                    {/*</Row>*/}
                </section>
                <ModalFinanciacion
                    show={this.state.showModalFinanciacion}
                    onHide={() => this.handleCloseFinanciacion()}
                />
                <BannerFinanciacion id="financiacion" onClick={() => this.handleShowFinanciacion()}/>

                {/*<SliderFinan id="financiacion" bgColor="white" link={this.state.link} color="#eb0029"/>*/}
                {/*<Consulta bgColor="white" color="#ec0928"/>*/}
            </div>

        )
            ;
    }
}

export default PilotoAutomatico;