import React, {Component} from 'react';
import {Row, Col, FormGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import $ from 'jquery';
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';

import '../../assets/css/Soporte.css';

import FieldGroup from '../../componentes/camposTexto/FieldGroup';
import FieldSelect from '../../componentes/camposTexto/FieldSelect';

import wasap from "../../assets/img/iconWhite/wasap.png";
import tel from "../../assets/img/iconWhite/tel.png";
import face from "../../assets/img/iconWhite/face.png";
import spinner from '../../assets/img/spinner.gif';
class Soporte extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form:{
                origen:"Soporte",
                nombre: "",
                email: "",
                consulta: "",
            },
            error:null,
            enviado:null,
            spinner:null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    componentDidMount() {
        document.title = "Soporte - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/sendMail.php",
            // url: 'http://localhost/sensorNew/php/sendMail.php',
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType:'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function () {
                this.setState({spinner:true});
            }.bind(this),
            cache: false,
            success: function(data) {
                this.setState({enviado:true});
                this.setState({error:false});
                this.setState({spinner:false});
                var datos =  {
                    content_category: 'contacto',
                    content_name: 'formulario-enviado'
                };
                ReactPixel.track( 'Lead', datos );

                ReactGA.event({
                    action:'consultar',
                    category: 'contacto',
                    label: 'formulario-enviado'
                });
            }.bind(this),
            // Fail..
            error: function(xhr, status, err) {
                this.setState({error:true});
                this.setState({enviado:false});
                this.setState({spinner:false});
            }.bind(this)
        });
    }


    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        return (
            <div className="soporte-page">
                <section className="soporte-home">
                    <Row>
                        <Col md={6} sm={8} xs={10} className="soporte-text">
                            <div className="padding-limit">
                                <h1>Soporte y Respaldo</h1>
                                <p>Soporte técnico directo de fábrica, en todo el país.</p>
                                <div className="red-line2"></div>
                            </div>
                        </Col>
                        <Col md={6} sm={4} xs={2} className="soporte-img">
                        </Col>
                    </Row>
                </section>
                <section className="soporte-consulta">
                    <div className="container-limit">
                        <h2>Utilice los siguientes canales de comunicación para contactarse con nuestro
                            equipo técnico, de manera
                            <sb>&nbsp;directa&nbsp;</sb>
                            y
                            <sb>&nbsp;práctica.&nbsp;</sb>
                        </h2>
                        <Row className="linea-sup">
                            <Col md={3} className="linea-right center-text">
                                <li><img src={wasap} alt=""/><span>3476692615</span></li>
                            </Col>
                            <Col md={3} className="linea-right center-text">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                                        <img src={face} alt=""/><span>Sensor Tecnología</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={6} className="center-text">
                                <li>
                                    <a href="tel:+543476461416">
                                        <img src={tel} alt=""/><span>Líneas rotativas +54-3476 461 416/370/358</span>
                                    </a>
                                </li>
                            </Col>
                        </Row>
                        <Row className="linea-bottom center-text">
                            <Col md={12}>
                                <li>
                                    <a href="mailto:ventas@sensortecnologia.com">
                                        <span><b>ventas@sensortecnologia.com</b></span>
                                    </a>
                                </li>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="contacto">
                    <div className="container-limit">
                        <div className="form-limit">
                            <form
                                onSubmit={(e) => this.submitForm(e)}
                            >
                                <FieldGroup
                                    id="nombre"
                                    type="text"
                                    placeholder="Nombre"
                                    disabled={false}
                                    value={this.state.form.nombre}
                                    onChange={(e) => this.changeForm(e)}
                                    required={true}
                                />
                                <FieldGroup
                                    id="email"
                                    type="email"
                                    placeholder="E-mail"
                                    disabled={false}
                                    value={this.state.form.email}
                                    onChange={(e) => this.changeForm(e)}
                                    required={true}
                                />
                                {/*<FieldSelect*/}
                                {/*id="sector"*/}
                                {/*type="text"*/}
                                {/*select="Sector"*/}
                                {/*/>*/}
                                {/*<FieldSelect*/}
                                {/*id="sector"*/}
                                {/*type="text"*/}
                                {/*select="Producto"*/}
                                {/*/>*/}
                                <br/>
                                <FormGroup controlId="consulta">
                                    <FormControl componentClass="textarea"
                                                 placeholder="Consulta"
                                                 style={{height: '150px'}}
                                                 value={this.state.form.consulta}
                                                 onChange={(e) => this.changeForm(e)}
                                                 required={true}
                                    />
                                </FormGroup>
                                {this.state.spinner ? <img src={spinner}  style={{display:"block",margin:"20px auto"}} alt=""/>:
                                    <Button className="center-block" type="submit">
                                        Enviar
                                    </Button>
                                }
                                <br/>
                                <b hidden={!this.state.error} style={{color:"red"}}>Ocurrió un error, el mensaje no se ha podido enviar</b>
                                <b hidden={!this.state.enviado} style={{color:"red"}}>Mensaje enviado correctamente</b>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Soporte;