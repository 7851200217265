import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/solucionesElectricas/1.png';
import icon_2 from '../../assets/img/categorias/solucionesElectricas/2.png';
import icon_3 from '../../assets/img/categorias/solucionesElectricas/3.png';
import foto_1 from '../../assets/img/categorias/solucionesFabricantes/foto_1.png';
import foto_2 from '../../assets/img/categorias/solucionesElectricas/foto_2.png';
import foto_3 from '../../assets/img/categorias/solucionesElectricas/foto_3.png';


class SolucionesElectricas extends Component {

    componentDidMount() {
        document.title = "Soluciones de precisión - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">


                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h5>{this.context.t('Transporte')}</h5>
                        <h3>{this.context.t('Soluciones Eléctricas')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Diseñamos y desarrollamos a demanda arneses eléctricos y unidades de potencia para diversos mercados y máquinas, brindando una solución integral innovadora y de calidad.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Cada una de nuestras soluciones cuenta con un alto nivel de calidad')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Nuestros desarrollos son producidos bajo Norma Automotriz - Agrícola.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Nuestros productos son diseñados y confirgurados a demanda.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/cableado-electrico"><p>Cableados eléctricos</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/cableado-electrico"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/teclados-paneles"><p>Teclados</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/teclados-paneles"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="img-categ">
                                <Link to="/productos/relayteras"><p>Relayteras</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/relayteras"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default SolucionesElectricas;