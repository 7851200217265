import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';



//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/vision.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/vision/foto1.png';
import foto2 from '../../../assets/img/galeria/vision/foto2.png';
import foto3 from '../../../assets/img/galeria/vision/foto3.png';
import foto4 from '../../../assets/img/galeria/vision/foto4.png';

import valor1 from '../../../assets/img/valores/vision/valor1.png';
import valor2 from '../../../assets/img/valores/vision/valor2.png';

import video from '../../../assets/img/valores/vision/video.mp4';
import play from '../../../assets/img/play.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class SistemaVisionNoc extends Component {

    componentDidMount() {
        document.title = "Sistema de visión nocturna - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1 style={{fontSize:"66px", lineHeight:"69px"}}>
                                        <span>{this.context.t('Sistema de Visión Nocturna')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más seguridad, más productividad, más tecnología')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema diseñado para facilitar y simplificar la conducción nocturna')}</p>
                                    <p className="dsc">{this.context.t('Este sistema cuenta con una cámara térmica que genera una visión termográfica de alto alcance, capaz de aumentar la visibilidad y reducir la posibilidad de accidentes por colisión, permitiendo además, que el conductor realice maniobras anticipadas y seguras en situaciones de baja luminosidad o encandilamiento.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Productividad')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Este sistema facilita y simplifica la conducción nocturna, además de mejorar el rendimiento del chofer evitando accidentes que generen lucro cesante. \n' +
                                                'La capacidad de visión de largo alcance implica una ganancia de tiempo en comparación con las luces de la carretera, duplicando el alcance visual de las luces altas del vehículo y quintuplicando en el caso de circular con luces bajas. \n' +
                                                'Este sistema permite visualizar con nitidez una persona a 300 metros y objetos de 2,3 x 2,3 metros a más de 800 metros de distancia.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>

                            </Row>
                            <br/><br/><br/>
                            <Row>
                                <Col md={6}>

                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Máxima visibilidad')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            {this.context.t('La cámara es de sistema pasivo (no necesita una fuente de luz) por lo tanto, supera a otras tecnologías y mejora la visibilidad en condiciones de niebla, lluvia o humo. Este disposito posee un sistema desempañador integrado con encendido y apagado automático, capaz de eliminar una capa de 2mm. de hielo en 15 minutos aún con viento de 100 Km/h a -30ºC.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    </div>
                    <Row>
                        <div className="video-guia">
                            <Col xs={12} md={7} className="film-container bgGrey2">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()}
                                       type="video/mp4" controls={true}
                                       style={{margin:"0 auto", maxHeight:"474px",height:"474px"}}
                                >
                                </video>
                            </Col>

                            <Col xs={12} md={5} className="bgGrey">
                                <div className="descargas" style={{height: "100%", display:"flex", alignItems:"center"}}>
                                    <a target="_blank" className="incompleto"
                                       href={process.env.PUBLIC_URL + "/archivos/vision/catalogo-Vision-Nocturna.pdf"}>
                                        <div className="btnDescarga">
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Sistema de Visión Nocturna')}</h5>
                                            </div>

                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default SistemaVisionNoc;