import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/multiplex20.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/multiplex20/foto1.png';
import foto2 from '../../../assets/img/galeria/multiplex20/foto2.png';
import foto3 from '../../../assets/img/galeria/multiplex20/foto3.png';
import foto4 from '../../../assets/img/galeria/multiplex20/foto4.png';

import valor1 from '../../../assets/img/valores/multiplex20/valor1.png'
import valor2 from '../../../assets/img/valores/multiplex20/valor2.png'
const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Multiplex20 extends Component {

    componentDidMount() {
        document.title = "Multiplex 2.0 - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Multiplex 2.0')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más facilidad, rapidez y control.')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de tecnológico básico para control y gestión on board.')}</p>
                                    <p className="dsc">{this.context.t('El Multiplex 2.0 permite controlar todas las funciones del interior de la carrocería, a través de un único sistema, compacto, múltiplex y automotivo industrial. Este producto cuenta con un panel de formato encastrable, a través de pinzas de seguridad, puede ser instalado sin necesidad de amurarlo.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Seguridad y poder de procesamiento')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Este sistema controla funciones on off y PWM a través de módulos de estado sólido, de esta forma simplifica la aplicación y configuración del producto, evitando accidentes eléctricos, causados por manipulación no autorizada y protegiendo la integridad del sistema.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>


                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Reducción de costos')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            {this.context.t('La aplicación del sistema 2.0, simplifica la estética del coche, reduce costos vinculados a los insumos eléctricos, y permite acceder a información de tiempo de uso de todo el sistema como de cada función.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                </section>

            </div>
        )
    }
}

export default Multiplex20;