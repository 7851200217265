import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/cableado.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/cableado/foto1.png';
import foto2 from '../../../assets/img/galeria/cableado/foto2.png';
import foto3 from '../../../assets/img/galeria/cableado/foto3.png';
import foto4 from '../../../assets/img/galeria/cableado/foto4.png';
import valor1 from '../../../assets/img/valores/cableado/valor1.png';
import valor2 from '../../../assets/img/valores/cableado/valor2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class CableadoElectrico extends Component {

    componentDidMount() {
        document.title = "Cableados Eléctricos - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">

                    <Row>
                        <Col md={6} style={{paddingLeft: "0", marginTop: "-140px"}}>
                            <div className="img-producto">
                                <img style={{marginLeft: "0"}} className="img-responsive" src={portada} alt=""/>
                            </div>
                        </Col>
                        <div className="home-producto">
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Cableados Eléctricos')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Más calidad, más confiabilidad, más simplicidad.')}
                                    </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Sistemas eléctricos integrales diseñados a demanda')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('Diseñamos y fabricamos arneses eléctricos desarrollados a partir de diferentes conceptos tecnológicos. Nos especializamos en instalaciones multiplexadas y en instalaciones en formato estándar. Estos dispositivos son comercializados a demanda, y se adaptan a todo tipo de máquinas.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </div>
                    </Row>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas" style={{paddingBottom: "0"}}>
                        <div className="caracteristica-cables">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Alto nivel de Calidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            <ul className="lista-trilla">
                                                <li>{this.context.t('Material de Normas Automotivas internacionales.')}</li>
                                                <li>{this.context.t('Alta flexibilidad.')}</li>
                                                <li>{this.context.t('Resistencia a rayos UV.')}</li>
                                                <li>{this.context.t('Resistencia a hidrocarburos.')}</li>
                                                <li>{this.context.t('Antiflama.')}</li>
                                                <li>{this.context.t('Derivaciones soldadas por ultrasonido.')}</li>
                                                <li>{this.context.t('Terminales prensados a máquina.')}</li>
                                                <li>{this.context.t('Cables codificados o coloridos.')}</li>
                                                <li>{this.context.t('Control de calidad realizados por dispositivos electrónicos.')}</li>
                                                <li>{this.context.t('Sello de trazabilidad incorporado en producto.')}</li>
                                            </ul>
                                        </p>
                                    </div>

                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Estética innovadora')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            <ul className="lista-trilla">
                                                <li>{this.context.t('Utilización de corrugado cerrado')}</li>
                                                <li>{this.context.t('Utilización de derivaciones plásticas')}</li>
                                                <li>{this.context.t('Conectores coloreados')}</li>
                                                <li>{this.context.t('Identificación escrita de funciones')}</li>

                                            </ul>
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                </section>

            </div>
        )
    }
}

export default CableadoElectrico;