import React, {Component} from 'react';
import {Row, Col, Accordion, Panel, Button} from 'react-bootstrap';
import WOW from 'wowjs';

//CSS
import '../../assets/css/Empresa.css';
import 'animate.css/animate.css';


//IMAGENES
import rampa from '../../assets/img/features/rampa.png';
import camion from '../../assets/img/features/camion.png';
import valvula from '../../assets/img/features/valvula.png';
import tractor from '../../assets/img/features/tractor.png';
import panel from '../../assets/img/features/panel.png';
import valor1 from '../../assets/img/empresa/valor1.png';
import valor2 from '../../assets/img/empresa/valor2.png';
import valor3 from '../../assets/img/empresa/valor3.png';
import valor4 from '../../assets/img/empresa/valor4.png';
import valor5 from '../../assets/img/empresa/valor5.png';

import slide1 from '../../assets/img/empresa/slide/slide1.png';
import slide2 from '../../assets/img/empresa/slide/slide2.png';
import slide3 from '../../assets/img/empresa/slide/slide3.png';
import slide4 from '../../assets/img/empresa/slide/slide4.png';
import slide5 from '../../assets/img/empresa/slide/slide5.png';
import slide6 from '../../assets/img/empresa/slide/slide6.png';
import slide7 from '../../assets/img/empresa/slide/slide7.png';
import slide8 from '../../assets/img/empresa/slide/slide8.png';


import video from '../../assets/img/dron-empresa.mp4';
import play from '../../assets/img/play.png';

//COMPONENTES
import Clientes from '../../componentes/elementos/Clientes';
import OwlCarousel from 'react-owl-carousel';
import Footer from '../../componentes/layout/Footer';

class Empresa extends Component {

    componentDidMount() {
        document.title = "Sobre Sensor - Sensor Tecnología";
        window.scrollTo(0, 0);
        new WOW.WOW().init();
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");

    }

    render() {
        return (
            <div className="empresa">
                <section className="empresa-portada">
                    <div className="container-limit">
                        <Row>
                            <Col md={6}>
                                <h1>La Empresa</h1>
                            </Col>
                            <Col md={6}>
                                <p>
                                    Somos una empresa Argentina, fundada en 1993, que desarrolla produce y comercializa
                                    soluciones electrónicas a demanda. Nos especializamos en hardware, software,
                                    sensores, mecánica y eléctrica, haciendo foco en la integración de todos estos
                                    campos y apostando a la creatividad y al dominio del conocimiento.
                                    <br/>
                                    Como resultado de esta alquimia, logramos productos adaptables, flexibles,
                                    trasladables a diversos mercados, de gran potencia y capacidad de procesamiento.
                                </p>
                                <div className="miniHr"></div>
                            </Col>
                        </Row>
                    </div>
                </section>
                {/*<section className="separador-img">*/}

                {/*</section>*/}
                <section className="video-dron">
                    <Row style={{padding: "0px"}}>
                        <Col md={12} style={{padding: "0px"}}>
                            <div ref="playContainer" className="play-container">
                                <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                     alt=""/>
                            </div>
                            <video controls ref="film" className="img-responsive" preload src={video}
                                   onEnded={() => this.videoEnded()}
                                   type="video/mp4"
                                   style={{width: "100%"}}>
                            </video>
                        </Col>
                    </Row>
                </section>
                <section className="ing-nacional">
                    <div className="container-limit">
                        <Row>
                            <Col md={6}>
                                <h2>Ingeniería</h2>
                                <h3>Nacional</h3>
                            </Col>
                            <Col md={6}>
                                <p>
                                    Con una infraestructura de 8000 m2 cubiertos, somos capaces de responder a medias y
                                    altas demandas productivas, nuestra especialización nos permite actuar con solidez y
                                    experiencia en diversos mercados, como Agricultura, transporte, náutica,
                                    transformación vehicular, domótica y máquinas viales, brindando soluciones ``all in
                                    one`` de 360 grados para todos nuestros clientes.
                                    Al actuar en diversos mercados Sensor posee la particularidad de conocer las máximas
                                    exigencias de cada sector, lo que genera una retroalimentación que beneficia la
                                    calidad final de todos nuestros productos.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="lg-line"></div>
                </section>

                {/*<section className="features">*/}
                {/*<div className="container-limit">*/}
                {/*<div className="items">*/}
                {/*<div className="item-feature">*/}
                {/*<img className="img-responsive" src={rampa} alt=""/>*/}
                {/*<p>Diseño y desarrollo de automatización para maquinas agrícolas, viales y equipos*/}
                {/*móviles*/}
                {/*en general.</p>*/}
                {/*</div>*/}
                {/*<div className="item-feature">*/}
                {/*<img className="img-responsive" src={camion} alt=""/>*/}
                {/*<p>Desarrollo de sensorizaciones.</p>*/}
                {/*</div>*/}
                {/*<div className="item-feature">*/}
                {/*<img className="img-responsive" src={valvula} alt=""/>*/}
                {/*<p>Instalaciones eléctricas integrales para máquinas auto motivas.</p>*/}
                {/*</div>*/}
                {/*<div className="item-feature">*/}
                {/*<img className="img-responsive" src={tractor} alt=""/>*/}
                {/*<p>Agricultura de precisión.</p>*/}
                {/*</div>*/}
                {/*<div className="item-feature">*/}
                {/*<img className="img-responsive" src={panel} alt=""/>*/}
                {/*<p>Tableros, módulos de control, consolas, y joysticks a pedido del cliente para los más*/}
                {/*diversos usos.</p>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*</section>*/}
                <section className="innovacion">
                    <Row>
                        <Col md={8} className="center-block">
                            <h3>Nuestra Especialización</h3>

                            <Col md={7} className="center-block wow animated fadeIn" data-wow-duration="2s">
                                <img className="img-responsive valor" src={valor1} alt=""/>
                            </Col>
                            <p className="wow animated fadeIn" data-wow-duration="1s">Diseño y desarrollo de
                                automatización para maquinas agrícolas,
                                viales y equipos móviles en general.</p>
                            <div className="sm-line"></div>
                            <Col md={7} className="center-block wow animated fadeIn" data-wow-duration="2s">
                                <img className="img-responsive valor" src={valor2} alt=""/>
                            </Col>
                            <p className="wow animated fadeIn" data-wow-duration="1s">Instalaciones eléctricas
                                integrales para máquinas autopropulsadas.</p>
                            <div className="sm-line"></div>
                            <Col md={7} className="center-block wow animated fadeIn" data-wow-duration="2s">
                                <img className="img-responsive valor" src={valor3} alt=""/>
                            </Col>
                            <p className="wow animated fadeIn" data-wow-duration="1s">Tableros, módulos de control,
                                consolas, y joysticks a pedido del cliente
                                para los más diversos usos.</p>
                            <div className="sm-line"></div>
                            <Col md={10} className="center-block wow animated fadeIn" data-wow-duration="2s">
                                <img className="img-responsive valor" src={valor4} alt=""/>
                            </Col>
                            <p className="wow animated fadeIn" data-wow-duration="1s">Desarrollo de sensorizaciones.</p>
                            <div className="sm-line"></div>
                            <Col md={7} className="center-block wow animated fadeIn" data-wow-duration="2s">
                                <img className="img-responsive valor" src={valor5} alt=""/>
                            </Col>
                            <p className="wow animated fadeIn" data-wow-duration="1s">Agricultura de precisión.</p>
                            <div className="sm-line"></div>
                        </Col>
                    </Row>
                </section>

                <section className="galeria">
                    <OwlCarousel
                        ref="slider2"
                        className="owl-theme"
                        loop={true}
                        nav={false}
                        navText=""
                        dots={false}
                        margin={0}
                        autoplay={true}
                        smartSpeed={3000}
                        autoplayHoverPause={false}
                        responsive={
                            {
                                0: {
                                    items: 3
                                },
                                // breakpoint from 480 up
                                480: {
                                    items: 3
                                },
                                // breakpoint from 768 up
                                768: {
                                    items: 3
                                },
                                // breakpoint from 768 up
                                991: {
                                    items: 4
                                }
                            }
                        }
                    >
                        <div className="item">
                            <img className="img-responsive" src={slide1} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide2} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide3} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide4} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide5} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide6} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide7} alt=""/>
                        </div>
                        <div className="item">
                            <img className="img-responsive" src={slide8} alt=""/>
                        </div>
                    </OwlCarousel>
                </section>
                <section className="mvo">
                    <div className="container-limit">
                        <Accordion>
                            <Panel header="Visión" eventKey="1">
                                Ser los mejores proveedores de soluciones tecnológicas basadas en el dominio del
                                conocimiento, en el compromiso y creatividad del equipo humano
                                que integramos.

                            </Panel>
                            <Panel header="Misión" eventKey="2">
                                Mejorar constantemente la calidad de nuestros productos y procesos.
                                Desarrollar proyectos que nos coloquen en línea con el mundo y un paso
                                delante de lo ya conocido. <br/>
                                Velar para que cada uno de nuestros usuarios se encuentre satisfecho.
                                Cuidar del presente y del futuro de cada integrante de nuestro grupo humano.
                                Aportar para que la sociedad en la que vivimos y vivirán nuestros descendientes,
                                sea cada vez mejor.
                            </Panel>
                            <Panel header="Objetivos" eventKey="3">
                                Desarrollar productos tecnológicos que generen un valor agregado a
                                nuestros clientes, ayudándolos a:
                                <br/>
                                • Mejorar la calidad
                                <br/>
                                • Mejorar costos productivos
                                <br/>
                                • Resolver problemas técnicos, tecnológicos y/o productivos
                                <br/>
                                • Aumentar su participación en el mercado
                                <br/>
                            </Panel>
                        </Accordion>
                    </div>
                </section>
                <section className="separador-img">

                </section>
                <Clientes/>

                {/*<section className="testimonio">*/}
                {/*<OwlCarousel*/}
                {/*className="owl-theme"*/}
                {/*loop*/}
                {/*margin={10}*/}
                {/*nav={false}*/}
                {/*items={1}*/}
                {/*>*/}
                {/*<div class="item">*/}
                {/*<div className="testimonio-item">*/}
                {/*<img src={slide1} alt=""/>*/}
                {/*<div className="testimonio-text">*/}
                {/*<h2>“Testimonio clientes seleccionados*/}
                {/*en slider con foto”</h2>*/}
                {/*<div className="miniHr center"></div>*/}
                {/*<p><i>Gerente de Ventas, Metalsur</i></p>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*</div>*/}
                {/*<div class="item">*/}

                {/*</div>*/}
                {/*<div class="item">*/}

                {/*</div>*/}
                {/*</OwlCarousel>*/}
                {/*</section>*/}

                <section className="libro-oro">
                    <div className="container-limit">
                        <Row>
                            <Col md={6}>
                                <h1>Libro de Oro</h1>
                                <p>
                                    Descargue la reseña de nuestra historia y organización institucional, nuestros
                                    objetivos y desarrollos.
                                </p>
                                <a target="_blank"
                                   href={process.env.PUBLIC_URL + "/archivos/empresa/libroOro.pdf"}>
                                    <Button>Descargar</Button>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
}

export default Empresa