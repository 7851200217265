import React from 'react';
import {
    FormGroup,
    ControlLabel,
    HelpBlock,
    FormControl,
    InputGroup,
} from 'react-bootstrap';

function FieldSelect({id, label,opciones, select, ...props}) {
    return (

    <FormGroup controlId={id}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl {...props} componentClass="select">
            <option value="select">{select}</option>
            {opciones}
        </FormControl>
    </FormGroup>
    );
}
export default FieldSelect;