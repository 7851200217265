import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {
    Header, Contenido, P,
    Button, Info, Input, Footer, Cerrar
} from "./../oportunidad/EstilosOportunidad";
import styled from "styled-components";
import $ from 'jquery';

import logo25 from '../../../assets/img/oportunidad/logo25bg.png';
import logoSensor from '../../../assets/img/oportunidad/logo.png';
import piloto from '../../../assets/img/oportunidad/footer_piloto.png';
import bgHeader from '../../../assets/img/dudas/bgModal.png';
import '../../../assets/css/loader.css';
import ReactPixel from "react-facebook-pixel";

const Container = {
    background: '#545555',
    width: '100%',
    padding: '0'

};

const HeaderImg = styled(Header)`
    background-image: url(${bgHeader});
    background-repeat: no-repeat;
    background-size: cover;
`;

class ModalDudas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                origen: "Aun no resolvió sus dudas?",
                telefono: "",
                consulta: ""
            },
            error: null,
            enviado: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);

    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/sendMail.php",
            // url: 'http://localhost/Sensor/php/sendMail.php',
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function (data) {
                this.setState({spinner: true});
                this.setState({consulta: "Llamar a: " + data.telefono})
            }.bind(this),
            cache: false,
            success: function () {
                // console.log("success");
                this.setState({enviado: true});
                this.setState({error: false});
                this.setState({spinner: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                // console.log(err);
                this.setState({error: true});
                this.setState({enviado: false});
                this.setState({spinner: false});
            }.bind(this)
        });
    }

    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        //chau scroll de la pagina de atras
        document.getElementsByTagName('html')[0].style.overflow = this.props.show ? "hidden" : "auto";
        return (
            <div>
                <Modal
                    {...this.props}
                    bsSize="large"
                >
                    <div onClick={this.props.onHide} style={Cerrar}>&#x2715;</div>
                    <Modal.Body style={Container}>
                        <HeaderImg style={{paddingBottom: "100px"}}>
                            <img src={logoSensor} style={{alignSelf: "center", marginLeft: "50px"}} alt=""/>
                            <img className="logo25" src={logo25} style={{marginLeft: "auto", height: "155px"}} alt=""/>
                        </HeaderImg>

                        <Contenido>
                            <div hidden={!this.state.enviado}>
                                <h3 style={{fontSize: "30px"}}>
                                    Tus datos fueron enviados exitosamente.
                                </h3>
                                <P style={{marginBottom: "40px"}}>Un asistente te llamará a la brevedad</P>
                            </div>

                            <div hidden={!this.state.error}>
                                <h3 style={{fontSize: "30px"}}>
                                    Ocurrio un error.
                                </h3>
                                <P style={{marginBottom: "40px"}}>Intente nuevamente mas tarde</P>
                            </div>

                            <div hidden={this.state.enviado || this.state.error == true}>
                                <h2 style={{marginTop: "-68px"}}>¡HOLA!</h2>
                                <P>
                                    Para comenzar a <b>hablar con un asistente</b> confirmá tu identidad ingresando tu
                                    teléfono
                                </P>
                                <form
                                    onSubmit={(e) => this.submitForm(e)}
                                >
                                    <Input id="telefono" type="number" placeholder="Teléfono o WhatsApp*" required={true}
                                           onChange={(e) => this.changeForm(e)}/>
                                    {this.state.spinner ?
                                        <div className="lds-dual-ring"></div>
                                        :
                                        <Button type="submit">Enviar datos</Button>
                                    }
                                </form>
                                <Info>
                                    *La información requerida es obligatoria para que se pueda establecer el
                                    contacto. <br/>
                                    Los datos personales no serán difundidos.
                                </Info>
                            </div>
                        </Contenido>

                        <Footer hidden={this.state.enviado || this.state.error == true}>
                            <img src={piloto}/>
                            <div>
                                <p>
                                    PILOTO AUTOMÁTICO.
                                </p>
                                <b>ÚTIL, ACTIVO, <u>VERDADERO.</u></b>
                            </div>
                        </Footer>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalDudas;