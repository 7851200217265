import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import styled from 'styled-components';
import chatea from '../../../assets/img/dudas/whatsapp.png';
import llamamos from '../../../assets/img/dudas/llamamos.png';
import contact from '../../../assets/img/dudas/contact.png';

import iconWhatsApp from '../../../assets/img/dudas/iconWhastApp.png';
import iconLlamamos from '../../../assets/img/dudas/iconLlamamos.png';
import iconContact from '../../../assets/img/dudas/iconContact.png';

import iconFace from '../../../assets/img/dudas/iconFace.png';
import iconLinkIn from '../../../assets/img/dudas/iconLinkeIn.png';
import iconIg from '../../../assets/img/dudas/iconIg.png';

const Container = styled.section`
    background-color: #676868;
    width: 100%;
    padding-bottom: 80px;

    
    @media(max-width: 542px){
        padding-top: 60px;
    }
    
    a{
        color: white;
        text-decoration: none;
    }
    a:hover{
        color: white;
        text-decoration: none;
    }
    a:focus{
        color:white;
        outline:none;
        text-decoration: none;
    }
`;

const H3 = styled.h3`
    color: white;
    margin-top:0px;
    font-size: 35px;
    font-family: 'Myriad Pro';
    text-align: center;
    font-weight: 100;
    b {
        font-family: 'Myriad Pro';
        font-weight: 300; 
    }
   
    @media(max-width: 554px){
        font-size: 25px;
    }
`;

const ContainerDivConImg = styled.div`
    margin-top: 50px;
    display: flex;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    div{
        background-repeat: no-repeat;
        background-size: cover;
        flex: 1 1 0;
        height: 115px;
    }
    a{
        flex:1 1 0;
    }
    @media(max-width: 554px){
        flex-wrap: wrap;
        
        div{
            flex: inherit;
            width: 100%;
        }
    }
    
`;
const DivChatea = styled.div`
    background-image: url(${chatea});
    
`;

const DivLlamamos = styled.div`
    background-image: url(${llamamos});
    border-left: 1px solid white;
    @media(max-width: 554px){
        border-left: none;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
    }
    cursor: pointer;
`;

const DivContact = styled.div`
    background-image: url(${contact});
    border-left: 1px solid white;
    @media(max-width: 554px){
        border-left: none;
    }
`;
const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    span{
        padding-left: 10px;
        color: white;
        font-family: "Myriad Pro";
        font-weight: 500;
        width: 100%;
        max-width: 155px;
        font-size: 15px;
        text-shadow: 1px 1px 2px black;
    }
    
`;

const ContainerSubIcon = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    div{
        padding: 15px;
        width: 100%;
        max-width: 170px;
    }
    span{
        padding-left: 7px;
    }    
`;

class Dudas extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <Container>
                <H3>¿Aún no resolviste tu <b>duda</b>?</H3>
                <ContainerDivConImg>
                    <a href="https://api.whatsapp.com/send?phone=5493476649439">
                        <DivChatea>
                            <ContainerIcon>
                                <img src={iconWhatsApp} alt=""/>
                                <span>Chateá con nosotros</span>
                            </ContainerIcon>
                        </DivChatea>
                    </a>
                    <DivLlamamos onClick={() => this.props.openModalLlamamos()}>
                        <ContainerIcon>
                            <img src={iconLlamamos} alt=""/>
                            <span>Hacé click para que te llamemos</span>
                        </ContainerIcon>
                    </DivLlamamos>
                    <Link to="/contacto">
                        <DivContact>
                            <ContainerIcon>
                                <img src={iconContact} alt=""/>
                                <span>Contactanos</span>
                            </ContainerIcon>
                        </DivContact>
                    </Link>
                </ContainerDivConImg>

                <ContainerSubIcon>
                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                        <div>
                            <img src={iconFace} alt=""/>
                            <span>Sensor Tecnología</span>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/16217851/">
                        <div>
                            <img src={iconLinkIn} alt=""/>
                            <span>Sensor Tecnología</span>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/sensortecnologia/">
                        <div>
                            <img src={iconIg} alt=""/>
                            <span>sensortecnologia</span>
                        </div>
                    </a>
                </ContainerSubIcon>


            </Container>
        );
    }
}

export default Dudas;