import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

//import '../../assets/css/Iconos.css';
import dwBtnDisabled from '../../assets/img/dw-btn-disabled.png';

class BotonDescargaDisabled extends Component {
    render() {
        const {texto} = this.props;
        return (
            <div className="container-btn-descarga">
                <div className="btn-descarga btn-descarga-disabled">
                    <img src={dwBtnDisabled} alt=""/>
                    <a>{texto}</a>
                </div>
            </div>

        );
    }
}

export default BotonDescargaDisabled;