import React, {Component} from 'react';
import {Row, Col, Button, Accordion, Panel} from 'react-bootstrap';
import Scrollchor from 'react-scrollchor';
import '../../../assets/css/Producto.css';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//Import Images
import hr from '../../../assets/img/productos/hr.png';
import portada from '../../../assets/img/productos/multiplex30.png';
import dwWing from '../../../assets/img/dwWing.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';


import icon1 from '../../../assets/img/productos/multiplex30/icon1.png';
import icon2 from '../../../assets/img/productos/multiplex30/icon2.png';
import icon3 from '../../../assets/img/productos/multiplex30/icon3.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/multiplex30/foto1.png';
import foto2 from '../../../assets/img/galeria/multiplex30/foto2.png';
import foto3 from '../../../assets/img/galeria/multiplex30/foto3.png';
import foto4 from '../../../assets/img/galeria/multiplex30/foto4.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import VideoGuia from '../../elementos/VideoGuia';
import SliderFinan from "../../elementos/SliderFinan";

//Import Valores

import valor1 from '../../../assets/img/valores/multiplex30/valor1.png';
import valor2 from '../../../assets/img/valores/multiplex30/valor2.png';
import valor3 from '../../../assets/img/valores/multiplex30/valor3.png';
import valor4 from '../../../assets/img/valores/multiplex30/valor4.png';
import Iconos from "../../elementos/Iconos";

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];


class Multiplex30 extends Component {

    componentDidMount() {
        document.title = "Multiplex 3.0 - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    consultenos(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-consultenos'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-consultenos'
        });
    }
    render() {
        return (
            <div>
                <section className="productos">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Multiplex 3.0')}</span>
                                        <img src={logo25} alt=""/>

                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Mayor dominio, gestión y practicidad')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de control y gestión on board, de alto nivel tecnológico.')}</p>
                                    <p className="dsc">
                                        {this.context.t('El Multiplex 3.0 permite automatizar las funciones de las carrocerías y de los chasis de ómnibus. Puede aplicarse en: ómnibus de media y larga distancia, ambulancias y embarcaciones.')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('Entre las prestaciones podemos destacar: luces, motores eléctricos, electroválvulas, A/A, cartel de ruta/itinerario, audio y video.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>
                    <div id="caracteristicas" className="productos-caracteristicas destacado">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Confiabilidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Sistema derivado de exitosa plataforma tecnológica TEVIS, ' +
                                                'creada por Sensor Tecnología  en 2004, es flexible, potente y virtualmente ' +
                                                'amigable, su software es propietario y muy confiable. Capaz de controlar y' +
                                                ' gestionar todas las funciones y actividades de una' +
                                                'máquina compleja. Esta plataforma está disponible hoy para el mercado' +
                                                ' del transporte de pasajeros.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <img className="img-responsive" src={valor1} alt=""/>
                                </Col>
                            </Row>


                            <div className="red-line3" style={{margin: "80px auto"}}></div>


                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Sistema configurable')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('El sistema trabaja bajo un método centralizado, BUS CAN J1832, ' +
                                                'brindando diversos módulos de entradas/salidas disponibles de acuerdo a la necesidad de la máquina a controlar.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <img className="img-responsive" src={valor2} alt=""/>
                                </Col>
                            </Row>
                            <br/><br/>


                            <div className="red-line3" style={{margin: "80px auto"}}></div>

                            <Row>
                                <Col md={8}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Interfaz gráfica amigable')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Debido a la diversidad de máquinas y mercados de actuación, el sistema posee una interfaz gráfica de utilización muy intuitiva y adaptable a la necesidad del cliente.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={12} className="center-block">
                                    <img className="img-responsive" src={valor3} alt=""/>
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <p>
                                            <b>{this.context.t('Aplicaciones incluidas:')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>Llamador de camarera.</li>
                                            <li>Visualización de hasta 3 cámaras interiores.</li>
                                            <li>Sensores de estacionamiento.</li>
                                            <li>Sistema de conteo de pasajeros.</li>
                                            <li>GPS incorporado.</li>
                                            <li>Sistema de protección eléctrica inteligente.</li>
                                            <li>Activación de funciones por voz.</li>
                                            <li>Bluetooth.</li>
                                            <li>Wi-fi.</li>
                                            <li>Registro de eventos con geo.</li>
                                            <li>Referencia, medición e histórico de tiempo de uso de funciones.</li>
                                            <li>Sistema CAN J1932.</li>
                                            <li>Climatizador.</li>
                                            {/*<li>Control de itinerario.</li>*/}
                                            {/*<li>Alarmas por uso indebido.</li>*/}
                                            {/*<li>Alarmas por robo de combustible.</li>*/}
                                            {/*<li>Alarma por modalidad de conducción inadecuada.</li>*/}
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <img className="img-responsive" src={valor4} alt=""/>
                                </Col>
                            </Row>


                            <br/><br/>
                        </div>

                        <Iconos
                            icon1={icon1}
                            icon2={icon2}
                            icon3={icon3}
                            text1={this.context.t('Hasta un 30% de reducción en los costos productivos y operativos.')}
                            text2={this.context.t('Mayor simplicidad y facilidad de uso.')}
                            text3={this.context.t('Mayor control a través de los registros generados.')}
                        />
                    </div>
                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={12} className="bgGrey">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/multiplex30/Multiplex3-0.pdf"}>
                                        <div className="btnDescarga" style={{paddingTop: "5%", paddingBottom: "5%"}}>
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Multiplex 3.0')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                    <Row>
                        <div className="faq">
                            <div className="container-limit">
                                <div style={{
                                    width: "80px",
                                    height: "2px",
                                    backgroundColor: "white",
                                    margin: "0 auto"
                                }}></div>
                                <h2>{this.context.t('Preguntas Frecuentes')}</h2>
                                <Row>
                                    <Col md={6}>
                                        <Accordion>
                                            <Panel
                                                header={this.context.t('¿De cuantas pulgadas es la pantalla?')}
                                                eventKey="1">
                                                {this.context.t('La medida de la pantalla es 10,5”.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Es touch screen?')}
                                                eventKey="2">
                                                {this.context.t('Si, es touch screen.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Se pueden conectar cámaras y verlas desde este dispositivo?')}
                                                eventKey="3">
                                                {this.context.t('Si, el sistema incluye entradas para hasta 3 videocámaras analógicas.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Tiene GPS?')}
                                                eventKey="4">
                                                {this.context.t('Si, pero se utiliza para georeferenciar eventos y ubicar el vehículo.')}
                                            </Panel>
                                        </Accordion>
                                    </Col>
                                    <Col md={6}>
                                        <Accordion>
                                            <Panel
                                                header={this.context.t('¿El sistema tiene modo de visión nocturna?')}
                                                eventKey="1">
                                                {this.context.t('Si, el sistema posee modo de visión nocturna.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Las funciones poseen activación por voz?')}
                                                eventKey="2">
                                                {this.context.t('El sistema informa funciones activadas y desactivadas de manera oral.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Se puede colocar en cualquier carrocería?')}
                                                eventKey="3">
                                                {this.context.t('Si,  el sistema puede colocarse en cualquier carrocería previo relevamiento.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Posee climatizador?')}
                                                eventKey="4">
                                                {this.context.t('Si. el sistema tiene control de clima incorporado.')}
                                            </Panel>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <Col md={7} className="center-block">
                                <h6>¿Aún no resolvió sus dudas?</h6>
                                <Link to="/contacto" onClick={() => this.consultenos()}>
                                    <Button>
                                        Consúltenos
                                    </Button>
                                </Link>
                            </Col>
                        </div>
                    </Row>

                </section>
            </div>

        );
    }
}

export default Multiplex30;