import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/agriculturaPrecision/1.png';
import icon_2 from '../../assets/img/categorias/agriculturaPrecision/2.png';
import icon_3 from '../../assets/img/categorias/agriculturaPrecision/3.png';
import foto_1 from '../../assets/img/categorias/agriculturaPrecision/foto_1.png';
import foto_2 from '../../assets/img/categorias/agriculturaPrecision/foto_2.png';
import foto_3 from '../../assets/img/categorias/agriculturaPrecision/foto_3.png';
import foto_4 from '../../assets/img/categorias/agriculturaPrecision/foto_4.png';


class AgriculturaPrecision extends Component {

    componentDidMount() {
        document.title = "Agricultura de precisión - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">
                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h5>{this.context.t('Agricultura')}</h5>
                        <h3>{this.context.t('Agricultura de Precisión')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Desarrollamos sistemas tecnológicos utilizados para realizar operaciones agrícolas de ' +
                            'manera eficiente, aumentando la productividad, simplificando el trabajo, mejorando la calidad' +
                            ' de los productos y ahorrando dinero.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p>{this.context.t('Nuestros sistemas son 100% nacionales.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p>{this.context.t('Cuentan con comercialización y soporte técnico directo de fábrica.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p>{this.context.t('Productos adaptable a todo tipo de maquinaria agrícola.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6} sm={6}>
                            <div className="img-categ">
                                <Link to="/productos/piloto-automatico"><p>Piloto Automático</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/piloto-automatico"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6} sm={6}>
                            <div className="img-categ">
                                <Link to="/productos/dat"><p>Detector de Alta Tensión</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/dat"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6} sm={6}>
                            <div className="img-categ">
                                <Link to="/productos/monitor-rendimiento"><p>Monitor de Rendimiento</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/monitor-rendimiento"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6} sm={6}>
                            <div className="img-categ">
                                <Link to="/productos/sistema-wave"><p>Sistema Wave</p></Link>
                                <img src={foto_4} className="img-responsive" alt=""/>
                                <Link to="/productos/sistema-wave"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default AgriculturaPrecision;