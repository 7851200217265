import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/solucionesElectricas/1.png';
import icon_2 from '../../assets/img/categorias/solucionesElectricas/2.png';
import icon_3 from '../../assets/img/categorias/solucionesElectricas/3.png';
import foto_1 from '../../assets/img/categorias/solucionesFabricantes/foto_3.png';
import foto_2 from '../../assets/img/categorias/solucionesEspeciales/foto_2.png';
import foto_3 from '../../assets/img/categorias/solucionesEspeciales/foto_3.png';
import foto_4 from '../../assets/img/categorias/productosAutonomos/foto_3.png';


class SolucionesEspeciales extends Component {

    componentDidMount() {
        document.title = "Soluciones especiales - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">


                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h3>{this.context.t('Soluciones Especiales')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Diseñamos y desarrollamos productos a demanda, capaces de cumplir con los requerimientos técnicos y los niveles de complejidad de cada mercado específico.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Cada una de nuestras soluciones cuenta con un alto nivel de calidad')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Nuestras soluciones cuentan con alta modularidad.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Nuestros productos son configurables a demanda.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/joysticks"><p>Joysticks</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/joysticks"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/consolas"><p>Consolas</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/consolas"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/sensores"><p style={{maxWidth:"377px", lineHeight:"36px"}}>Sensor electrónico indicador de nivel</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/sensores"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/sistema-rec"><p>Sistema REC</p></Link>
                                <img src={foto_4} className="img-responsive" alt=""/>
                                <Link to="/productos/sistema-rec"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default SolucionesEspeciales;