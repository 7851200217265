import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import video from '../../assets/img/productos/video.png';
import btnDescarga from '../../assets/img/productos/btnDescarga.png';
import btnDescargaGrey from '../../assets/img/productos/btnDescargaGrey.png';

class VideoGuia extends Component {
    render() {
        return (
            <section>
                <Row>
                    <div className="video-guia">
                        <Col md={7}>
                            <img style={{width: "100%"}} src={video} alt=""/>
                        </Col>

                        <Col md={5}>
                            <div className="descargas" style={{height: "100%"}}>
                                <div style={{height: "50%"}} className="btnDescarga">
                                    <img src={btnDescargaGrey} alt=""/>
                                    <div className="dscDescarga" style={{color:"#676868"}}>
                                        <h3>{this.context.t('Guía Rápida')}</h3>
                                        <h5>{this.context.t('Mapeador / Corte automático de secciones')}</h5>
                                    </div>
                                </div>
                                <div style={{height: "50%"}} className="bgGrisOsc">
                                    <div className="btnDescarga">
                                        <img src={btnDescarga} alt=""/>
                                        <div className="dscDescarga">
                                            <h3>{this.context.t('Manual de Uso')}</h3>
                                            <h5>{this.context.t('Piloto automático y computadora de aplicación.')}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Row>
            </section>
        );
    }
}

export default VideoGuia