import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import portada from '../../../assets/img/productos/monitor.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/monitor/foto1.png';
import foto2 from '../../../assets/img/galeria/monitor/foto2.png';
import foto3 from '../../../assets/img/galeria/monitor/foto3.png';
import foto4 from '../../../assets/img/galeria/monitor/foto4.png';

import valor1 from '../../../assets/img/valores/monitor/valor1.png';
import valor2 from '../../../assets/img/valores/monitor/valor2.png';

import video from '../../../assets/img/valores/monitor/video.mp4';
import play from '../../../assets/img/play.png';
import logo25 from '../../../assets/img/logo25prod.png';


const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class MonitorRendimiento extends Component {

    componentDidMount() {
        window.scroll(0, 0);
        document.title = "Monitor rendimiento - Sensor Tecnología";
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    obtengaElSuyo(){
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Monitor de Rendimiento')}</span>
                                        <img src={logo25} alt=""/>

                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Más información, más prestaciones, más economía.')}
                                    </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Gestiona la información del lote realizado de manera inmediata y precisa.')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('El Monitor de Rendimiento es un sistema que le permite al productor gestionar la información de lotes de manera rápida y sencilla, y al mismo tiempo registrar de forma georeferenciada la variabilidad de rendimiento del cultivo cosechado.')}
                                    </p>


                                    {/*<Col md={5} style={{padding: "0px"}}>*/}
                                        {/*<h2>{this.context.t('Desde')} <span>{this.context.t('U$D 6.500')}</span></h2>*/}
                                        {/*<p className="referencia">*/}
                                            {/*<i>*/}
                                                {/*+ IVA. Incluye instalación del producto.*/}
                                                {/*Los valores expresados están sujetos a modificaciones según corresponda.*/}
                                            {/*</i>*/}
                                        {/*</p>*/}
                                    {/*</Col>*/}
                                    <Col md={5}>
                                        <Scrollchor to="financiacion" disableHistory={true} beforeAnimate={() => this.obtengaElSuyo()}>
                                            <Button style={{marginTop: "20px"}}>
                                                {this.context.t('Obtenga el suyo')}
                                            </Button>
                                        </Scrollchor>
                                        <p className="sub-button">
                                            <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>Realice su consulta</Link>
                                        </p>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Información')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El Sistema 2.5 gestiona la información de rendimiento en tiempo real con un margen de error inferior al 5%, registrando de manera georeferenciada toda la información vinculada al rendimiento del cultivo cosechado.')}
                                        </p>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Precisión')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El sensor de rendimiento utilizado es de tipo placa de impacto y está diseñado a medida de cada modelo de máquina,  garantizando un margen de error inferior al 5%, con fácil calibración y mantenimiento.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>
                    <Row>
                        <div className="video-guia">
                            <Col xs={12} md={7} className="film-container bgGrey2">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()}
                                       type="video/mp4" controls={true}
                                       style={{margin:"0 auto", maxHeight:"474px"}}
                                >
                                </video>
                            </Col>

                            <Col xs={12} md={5} className="bgGrey">
                                <div className="descargas" style={{height: "100%", display:"flex", alignItems:"center"}}>
                                    <a target="_blank" className="incompleto"
                                       href={process.env.PUBLIC_URL + "/archivos/monitor/sensor-tecnologia-sr2-5.pdf"}>
                                        <div className="btnDescarga">
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Monitor de Rendimiento')}</h5>
                                            </div>

                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>
                    <SliderFinan id="financiacion" bgColor="white"
                                 // link="https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-06e86b20-d76f-45ff-8282-33e347188983"
                                 color="#eb0029"/>

                </section>

            </div>
        )
    }
}

export default MonitorRendimiento;