import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import '../../assets/css/Galeria.css';
import OwlCarousel from "react-owl-carousel";

class Galeria extends Component {

    constructor(props) {
        super(props);
        this.state = {currentImage: 0};
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);

    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        let lsb = [];
        let msb = [];
        // console.log(msb);
        // console.log(lsb);
        this.props.photos.some(
            (photo, i) => {
                if (i < 2) {
                    lsb.push(photo);
                } else {
                    msb.push(photo);
                }

            }
        );

        var imagenes = [];
        imagenes = this.props.photos;
        //condicion para galeria de imagenes con mas de 4, no aparecen en la preview pero si cuando haces click
        if (this.props.lightbox && this.props.lightbox.length > 0)
            imagenes = this.props.photos.concat(this.props.lightbox);

        return (

            <div className="gallery-component">
                <div style={{
                    textAlign: "center",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    backgroundColor: "#555656"
                }}>
                    <div className="hidden-xs">
                        <Gallery columns={4} margin="0" photos={this.props.photos} onClick={this.openLightbox}/>
                    </div>
                    <Lightbox images={imagenes}
                              onClose={this.closeLightbox}
                              onClickPrev={this.gotoPrevious}
                              onClickNext={this.gotoNext}
                              currentImage={this.state.currentImage}
                              isOpen={this.state.lightboxIsOpen}
                    />
                </div>
                <OwlCarousel
                    className="owl-theme hidden-md hidden-sm hidden-lg"
                    loop={true}
                    nav={true}
                    autoplay={true}
                    navText=""
                    dots={false}
                    margin={0}
                    autoplayHoverPause={false}
                    items={1}
                >
                    <div className="item">
                        <Gallery columns={2} margin="0" photos={lsb} onClick={this.openLightbox}/>
                    </div>
                    <div className="item">
                        <Gallery columns={2} margin="0" photos={msb} onClick={this.openLightbox}/>
                    </div>
                </OwlCarousel>

            </div>
        );
    }
}

export default Galeria;