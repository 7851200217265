import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Scrollchor from 'react-scrollchor';
import OwlCarousel from 'react-owl-carousel';


//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/hololed.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import logo25 from '../../../assets/img/logo25prod.png';
import dwWing from '../../../assets/img/dwWing.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/hololed/foto1.jpg';
import foto2 from '../../../assets/img/galeria/hololed/foto2.jpg';
import foto3 from '../../../assets/img/galeria/hololed/foto3.jpg';
import foto4 from '../../../assets/img/galeria/hololed/foto4.jpg';

import valor1 from '../../../assets/img/valores/hololed/valor1.png';
import valor2 from '../../../assets/img/valores/hololed/valor2.png';
import valor3_1 from '../../../assets/img/valores/hololed/valor3_1.png';
import valor3_2 from '../../../assets/img/valores/hololed/valor3_2.png';
import valor3_3 from '../../../assets/img/valores/hololed/valor3_3.png';
import valor3_4 from '../../../assets/img/valores/hololed/valor3_4.png';



const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Hololed extends Component {
    componentDidMount() {
        document.title = "Sensores - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Hololed')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más creatividad, más promoción, más tecnología')}</p>
                                    <p className="tit-dsc">{this.context.t('Pantalla 3D Holográfica.')}</p>
                                    <p className="dsc">{this.context.t('Hololed es una increíble pantalla 3D holográfica, compuesta por una hélice con una línea de luces LED de alta densidad. Este dispositivo al girar se convierte en un visualizador 3D capaz de formar imágenes, animaciones y videos sin bordes ni fondo, que parecen flotar en el aire de manera atractiva, sorprendente y realista, captando la atención de los consumidores.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>
                    <div id="caracteristicas" className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 style={{fontSize: "50px"}}>{this.context.t('Una solución inteligente y rentable')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Hololed es ideal para promocionar y publicitar productos, servicios o producciones artísticas de manera creativa y sin límites, mostrándolos a través de imágenes, animaciones y videos originales. Esta pantalla es apta para colocar en paredes, vidrieras y/o espacios varios gracias a la simpleza de su diseño, y es también una alternativa económica y rentable.')}
                                        </p>
                                        <br/>
                                        <p>
                                            <b>{this.context.t('Ideal para generar impacto en:')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Centros comerciales')}</li>
                                            <li>{this.context.t('Tiendas de ropa.')}</li>
                                            <li>{this.context.t('Museos.')}</li>
                                            <li>{this.context.t('Restaurantes.')}</li>
                                        </ul>
                                    </div>

                                </Col>
                                <Col md={6} style={{paddingTop: "60px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                        <div className="caracteristicas-descripcion"
                                             style={{position: "relative", top: "-50px"}}>
                                            <p>
                                                <b>{this.context.t('Características:')}</b>
                                            </p>

                                            <ul className="lista-trilla nonBullets">
                                                <li>{this.context.t('Tamaño: 60 cm.')}</li>
                                                <li>{this.context.t('Resolución: 512 x 512 Px.')}</li>
                                                <li>{this.context.t('Color del dispositivo: negro.')}</li>
                                                <li>{this.context.t('Potencia: 24V, 3A (suministro de CA-100~200 V y 50/60 Hz).')}</li>
                                                <li>{this.context.t('Capacidad jpg, jpeg, gif, mp4, avi, rmvb y otros formatos.')}</li>
                                                <li>{this.context.t('50.000 horas de vida útil.')}</li>
                                                <li>{this.context.t('Aplicación disponible para iOS, Android, MAC y PC.')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 style={{fontSize: "50px"}}>{this.context.t('Un dispositivo, múltiples posibilidades')}</h3>
                                        <div className="red-line3"></div>

                                        <p>Cuando el dispositivo se pone en funcionamiento, la pantalla muestra imágenes
                                            tridimensionales de alta resolución que parecen suspendidas en el aire, con
                                            una excelente calidad y gran nivel de detalle, permitiendo que puedan verse
                                            de manera correcta a cierta distancia sin perder la definición, aún en
                                            ambientes totalmente iluminados. Además, la pantalla cuenta con un amplio
                                            ángulo de visión, pudiendo visualizarse de manera correcta desde todos los
                                            ángulos y alturas.</p>
                                        <br/>
                                        <p>
                                            <b>{this.context.t('Beneficios:')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Generar alto impacto visual a través de imágenes, animaciones y videos con calidad extraordinaria.')}</li>
                                            <li>{this.context.t('Atraer y captar la atención del público.')}</li>
                                            <li>{this.context.t('Publicitar y promocionar productos de manera creativa y tecnológica.')}</li>
                                            <li>{this.context.t('Posibilidad de reproducir contenido en vivo.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6} style={{paddingTop: "100px", padding:"100px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <div className="red-line3" style={{margin: "80px auto"}}></div>

                            <Row className="sliderDestacados parking">

                                <Col md={11} xs={10} className="center-block" style={{padding:"0px"}}>
                                    <h5 style={{color:"#fc034a", fontSize:"20px"}}>¿Cómo usar la Pantalla 3D Holográfica Hololed?</h5>
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop={false}
                                        nav={true}
                                        navText=""
                                        dots={true}
                                        margin={2}
                                        autoplay={false}
                                        autoplayHoverPause={true}
                                        autoplayTimeout={7000}
                                        autoplaySpeed={3500}
                                        responsive={
                                            {
                                                0: {
                                                    items: 1
                                                },
                                                // breakpoint from 480 up
                                                480: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                768: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                991: {
                                                    items: 1
                                                }
                                            }
                                        }
                                    >
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_1} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_2} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_3} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_4} alt=""/>
                                        </div>

                                    </OwlCarousel>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={12} className="bgGrey">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/hololed/FolletoA4hololed-01.pdf"}>
                                        <div className="btnDescarga" style={{paddingTop: "5%", paddingBottom: "5%"}}>
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Folleto')}</h3>
                                                <h5>{this.context.t('Hololed')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default Hololed;