import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/parking.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';

//Import Components
import Galeria from '../../elementos/Galeria';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/parking/foto1.png';
import foto2 from '../../../assets/img/galeria/parking/foto2.png';
import foto3 from '../../../assets/img/galeria/parking/foto3.png';
import foto4 from '../../../assets/img/galeria/parking/foto4.png';


import valor1 from '../../../assets/img/valores/parking/valor1.png';
import valor2 from '../../../assets/img/valores/parking/valor2.png';
import valor3_1 from '../../../assets/img/valores/parking/valor3_1.png';
import valor3_2 from '../../../assets/img/valores/parking/valor3_2.png';

import video from '../../../assets/img/valores/tecnoshaft/video.mp4';
import play from '../../../assets/img/play.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaGrey from '../../../assets/img/productos/btnDescargaGrey.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Parking extends Component {

    componentDidMount() {
        document.title = "Parking - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Parking')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más seguro, más exacto y más integrado.')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema integral de ayuda al estacionamiento para grandes vehículos.')}</p>
                                    <p className="dsc">{this.context.t('El sistema de ayuda al estacionamiento PKS-16, brinda mayor seguridad porque permite maniobrar y estacionar de manera fácil y cuidadosa, evitando causar accidentes y daños en el vehículo.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas destacado">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Integración')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('El Sistema soporta diversos opcionales, para brindarle al chofer una solución completa y adaptada a su necesidad:')}
                                            </p>
                                        <ul className="lista-trilla paddingLeft" >
                                            <li><b style={{marginLeft:"-5px"}}>{this.context.t('Cámara de visión externa:')}</b></li>
                                        </ul>
                                        <p>
                                            {this.context.t('Además de ser guiado por los sonidos y la información del display, el operador puede visualizar la acción y lugar a través de una cámara integrada.')}
                                        </p>
                                        <ul className="lista-trilla paddingLeft">
                                            <li><b style={{marginLeft:"-5px"}}>{this.context.t('Sensores extra:')}</b></li>
                                        </ul>
                                        <p>
                                            {this.context.t('El sistema soporta hasta 10 sensores, siendo posible agregar  protección frontal y trasera, tanto superior, como inferior.')}
                                        </p>
                                        <ul className="lista-trilla paddingLeft">
                                            <li><b style={{marginLeft:"-5px"}}>{this.context.t('Kit de protección lateral:')}</b></li>
                                        </ul>
                                        <p>
                                            {this.context.t('El sistema cuenta con la posibilidad de incorporar 2 sensores laterales para alerta sobre vehículos en ángulos de dificultosa detección con espejos..')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>

                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Inteligencia')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El sistema se activa automáticamente al conectar la marcha atrás, y le avisa al conductor la cercanía a obstáculos mediante un dispositivo ubicado en la cabina que emite alarmas acústicas y visuales.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <div className="red-line3" style={{margin: "80px auto"}}></div>

                            <Row className="sliderDestacados parking">

                                <Col md={11} xs={10} className="center-block" style={{padding:"0px"}}>
                                    <h5 style={{color:"#fc034a", fontSize:"20px"}}>Diagrama de conexiones</h5>
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop={false}
                                        nav={true}
                                        navText=""
                                        dots={true}
                                        margin={2}
                                        autoplay={false}
                                        autoplayHoverPause={true}
                                        autoplayTimeout={7000}
                                        autoplaySpeed={3500}
                                        responsive={
                                            {
                                                0: {
                                                    items: 1
                                                },
                                                // breakpoint from 480 up
                                                480: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                768: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                991: {
                                                    items: 1
                                                }
                                            }
                                        }
                                    >
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_1} alt=""/>
                                        </div>
                                        <div className="item">
                                            <img className="img-responsive" src={valor3_2} alt=""/>
                                        </div>

                                    </OwlCarousel>
                                </Col>
                            </Row>

                        </div>
                    </div>
                    {/*<Row>*/}
                        {/*<div className="video-guia">*/}
                            {/*<Col xs={12} md={5} className="film-container bgGrey2">*/}
                                {/*<div ref="playContainer" className="play-container">*/}
                                    {/*<img ref="play" className="play" src={play} onClick={() => this.playVideo()}*/}
                                         {/*alt=""/>*/}
                                {/*</div>*/}
                                {/*<video ref="film" className="img-responsive" preload src={video}*/}
                                       {/*onEnded={() => this.videoEnded()}*/}
                                       {/*type="video/mp4"*/}
                                       {/*style={{margin:"0 auto"}}*/}
                                {/*>*/}
                                {/*</video>*/}
                            {/*</Col>*/}

                            {/*<Col xs={12} md={7} className="bgGrisOsc">*/}
                                {/*<div className="descargas" style={{height: "272px", verticalAlign:"middle", display:"table-cell"}}>*/}
                                    {/*<a target="_blank"*/}
                                       {/*href={process.env.PUBLIC_URL + "/archivos/tecnoshaft/sensor-tecnologia-tecnoshaft.pdf"}>*/}
                                        {/*<div className="btnDescarga">*/}
                                            {/*<img src={btnDescarga} alt=""/>*/}
                                            {/*<div className="dscDescarga" style={{color: ""}}>*/}
                                                {/*<h3>{this.context.t('Catálogo')}</h3>*/}
                                                {/*<h5>{this.context.t('Tecnoshaft')}</h5>*/}
                                            {/*</div>*/}

                                        {/*</div>*/}
                                    {/*</a>*/}
                                {/*</div>*/}
                            {/*</Col>*/}
                        {/*</div>*/}
                    {/*</Row>*/}


                </section>

            </div>
        )
    }
}

export default Parking;