export const translations = {
    'en': {
        //Home
        'Detector de alta tensión': 'High Voltage Detector',
        'Piloto Automático': 'Autopilot',
        'Más tecnología, más rendimiento, más confiable': 'More technology, more performance, more reliable',
        'Productos Destacados': 'Featured Products',
        'Ver más': 'View more',

        'Realice aquí su consulta': 'Make your inquiry here',
        'Contado': '',
        'Tarjetas': '',
        'Financiación': '',
        'Líneas rotativas': '',
        'Nuestros Clientes': '',

        //Productos
        'TRANSPORTE': '',
        'TRANSFORMACIÓN VEHICULAR': '',
        'SOLUCIONES ESPECIALES': '',
        'AGRICULTURA': '',
        'Productos Autónomos': '',
        'Cámaras de visión nocturna': '',
        'Sensores de estacionamiento': '',
        'Cámaras de retroceso': '',
        'Telemetría': '',
        'Soluciones Multiplex': '',
        'Soluciones Eléctricas': '',
        'Cableado eléctrico': '',
        'Teclados': '',
        'Relayteras': '',
        'Joystick': '',
        'Consolas': '',
        'Sistema Wave': '',
        'Agricultura': 'Farming',

        //agricultura de precisión
        'Agricultura de Precisión': 'Precision Farming',
        'Sistemas tecnológicos utilizados para realizar operaciones agrícolas de manera eficiente, aumentando la productividad, simplificando el trabajo, mejorando la calidad de los producto y ahorrando dinero.': 'Technological systems used to perform agricultural operations efficiently, increasing productivity, simplifying work, improving the quality of products and saving money.',
        '100% Nacionales.': '100% National',
        'Comercialización y soporte técnico directo de fábrica.': 'Direct marketing and factory technical support.',
        'Aplicables en todo tipo de maquinaria agrícola.': 'Applicable in all types of agricultural machinery.',

        //Soluciones para fabricantes
        'Realizamos desarrollos de acuerdo a los requerimientos técnicos de cada fabricante, teniendo en cuenta los diferentes niveles de complejidad, la utilización de elementos eléctricos (hardware - software – sensores), y aplicaciones estéticas.': '',
        'Soluciones para fabricantes': '',
        'Especializados en maquinaria autopropulsada – automotiva.': '',
        'Alta modularidad.': '',
        'Productos configurables a demanda.': '',

        //Soluciones Electricas
        'Soluciones Eléctricas': '',
        'Diseñamos y desarrollamos a demanda, arneses eléctricos y unidades de potencia para diversos mercados y máquinas, brindando una solución integral de calidad e innovadora.': '',
        'Alto nivel de calidad.': '',
        'Producidos bajo norma automotriz - agrícola.': '',
        'Excelente relación - precio': '',

        //Soluciones Especiales
        'Soluciones Especiales': '',
        'Diseñamos y desarrollamos productos con soluciones especiales a demanda, capaces de cumplir con los requerimientos técnicos y los niveles de complejidad de cada mercado específico.': '',
        'Alto nivel de calidad.': '',
        'Alta modularidad.': '',
        'Productos configurables a demanda.': '',


        //Productos
        'Guía Rápida': '',
        'Manual de Uso': '',
        'Preguntas Frecuentes': '',
        'Desde': '',
        'Obtenga el suyo': '',
        'Características Técnicas:': '',


        //DAT
        'DAT': '',
        'Más tecnología, más seguridad, más simple.': '',
        'Seguridad:': '',
        'El detector de líneas de alta tensión DAT fue desarrollado para prevenir que maquinarias con altura variable, como las pulverizadoras, entren en contacto con líneas de alta tensión, cuando abren sus alas.': '',
        'Precisión:': '',
        'Puede detectar la presencia de cables de alta tensión hasta 100 metros de distancia*, advirtiendo al operario del potencial peligro, y evitando así que sea lastimado.': '',
        'Simplicidad:': '',
        'Se puede utilizar en prácticamente cualquier tipo de vehículo. El sistema está compuesto por una antena de detección y un dispositivo de alarma visual/auditiva dentro de la cabina.': '',

        'Sensibilidad de detección ajustable': '',
        'Plug & Play': '',
        'Opción “Silenciar” disponible.': '',
        '¿Cómo me ayuda el dispositivo a saber que tan cerca estoy de una línea de alta tensión?': '',
        'El dispositivo emite un sonido intermitente que es proporcional a la cercanía de la línea de alta tensión.': '',
        '¿Hace falta realizar alguna modificación eléctrica en la máquina para instalar el producto?': '',
        'No.': '',
        '$100.000': 'u$d 323.33',

        //PILOTO AUTOMATICO
        'Más tecnología, más precisión, más resultados.': '',
        'El Piloto Automático es un sistema de seguimiento de línea y conducción automática, diseñado con la última tecnología y los recursos más innovadores del mundo.': '',

        'Precisión': '',
        'Permite elegir y ajustar precisiones con un margen de error desde 15 hasta 2 cm. siendo posible utilizar abono bajo la modalidad PPU “Pague por uso”.': '',

        'Adaptabilidad': '',
        'Adaptable a todo tipo de máquinas (cosechadoras, tractores, pulverizadores, fertilizadores, picadoras).': '',

        'Innovación': '',
        'Sistema electrohidráulico TRASLADABLE, el sistema más preciso utilizado y recomendado en el mundo.': '',
        'Ahorro de hasta un 20% de costos de insumos, por campaña.': '',
        'Mayor precisión en las maniobras y operaciones.': '',
        'Retorno de la inversión en sólo un año de trabajo (ahorro por cada hectárea realizada).': '',

        '¿Se puede comprar el producto sin precisión y luego comprar la precisión aparte?': '',
        'Si.': '',

        '¿Cómo se puede adquirir el abono de precisión?': '',
        'Tenemos dividido el servicio para ser adquirido de forma trimestral, semestral o anual.': '',

        '¿Qué alteraciones se le deben hacer a la máquina para colocar el producto?': '',
        'Ninguna, la máquina queda 100% original, solamente se agregan partes que pueden retirarse a voluntad.': '',

        //MONITOR DE RENDIMIENTO
        'Monitor de Rendimiento': '',

        'Más tecnología, más prestaciones, más economía.': '',

        'El Monitor de Rendimiento es un sistema que le permite al productor gestionar la información de lotes de manera rápida y sencilla, visualizando la variabilidad de rendimiento del mismo de forma geo referenciada.': '',

        'Confiabilidad': '',
        'El Sistema 2.5 gestiona la información de rendimiento en tiempo real con un margen de error inferior al 5%, registrando de manera georeferenciada toda la información vinculada al rendimiento del cultivo cosechado.': '',

        'Precisión': '',
        'El sensor de rendimiento utilizado es de tipo placa de impacto y está diseñado a medida de cada modelo de  máquina,  garantizando un margen de error inferior al 5%, con fácil calibración y mantenimiento.': '',

        'Integración': '',
        'El monitor mide los siguientes datos de trilla:': '',
        'Área parcial y total.': '',
        'Velocidad de avance.': '',
        'Distancia recorrida.': '',
        'Humedad instantánea y promedio.': '',
        'Rendimiento instantáneo húmedo y seco': '',
        'Rendimiento promedio húmedo y seco': '',
        'Ritmo de trabajo instantáneo y promedio': '',
        'Flujo de grano húmedo instantáneo y promedio': '',
        'Flujo de grano seco promedio': '',
        'Peso húmedo parcial y total': '',
        'Peso seco parcial y total': '',
        'Mejora la productividad del lote.': '',
        'Visualizador de mapas gratuito.': '',
        'Económico y de fácil instalación.': '',

        '¿Se puede usar como balanza?': '',
        'Si bien la precisión del producto es muy buena no es su función reemplazar una balanza de tolba.': '',

        '¿En qué software se pueden ver los archivos?': '',
        'En cualquier software de gestión agronómica. El formato de los archivos es .shape': '',

        '¿Los datos quedan guardados en el sistema?': '',
        'No, toda la información queda guardada en el pendrive. El sistema debe tener siempre un pendrive conectado desde el inicio para que guarde los datos.': '',


        //TECNOSHAFT
        'Más control, más economía, más confort': '',

        'El Tecnoshaft controla de forma automática el eje elevadizo direccional del semiremolque o camión. Este sistema también es utilizado para impedir que maniobras peligrosas sean realizadas y para simplificar el día a día del camionero. Además con el Tecnoshaft puede informar el peso aproximado de carga y de carga potencial disponible.': '',


        'Inteligencia': '',
        'El sistema cuenta con las siguientes características:': '',
        'No requiere interacción con camión.': '',
        'No requiere interacción de conductor.': '',
        'Modo maniobra. Levanta al accionar reversa.': '',
        'Modo levante de emergencia al llegar al límite de pivot del eje.': '',
        'Modo anti vandalismo.': '',
        'Modo manual para emergencia.': '',
        'Pulsador en cabina opcional.': '',
        'Sistema con filtrado y corrección automática, para caminos de tierra.': '',
        'Funciona con semirremolques o acoplados de suspensión mecánica o neumática.': '',
        'Apto para ejes direccionales o fijos.': '',


        'Rentabilidad': '',
        'El Tecnoshaft garantiza un ahorro de un 30% en lo que respecta a mantenimiento de eje, suspensión, neumáticos y multas.': '',

        'Garantiza el correcto uso del implemento.': '',
        'Garantiza la mejor capacidad de frenado.': '',
        'Facilita la conducción.': '',

        //SISTEMA WAVE

        'Sistema Wave': '',
        'Más integración, más versatilidad, más adaptabilidad': '',
        'All in one - Sistema integral para agricultura de precisión. La versatilidad de este sistema hace que sea adaptable a cualquier tipo y modelo de pulverizador autopropulsado.': '',

        'Inteligencia': '',
        'El producto incluye aplicativos que facilitan el trabajo y las operaciones de la máquina, como por ejemplo: calculadora de picos, control dual de caudal y control de dosis por bomba variable.': '',

        'Expansión y modularidad': '',
        'El sistema incluye diversos opcionales que se pueden adquirir de acuerdo a cada necesidad. La modularidad en la arquitectura de este sistema permite tratar por separado cada aplicación, permitiendo trasladar a otra máquina alguno de los módulos de manera individual, como por ejemplo el Piloto Automático.': '',


        'Alta rentabilidad: Permite ahorrar hasta un 30% de insumos por hectárea.': '',

        'Mayor precisión en maniobras y operaciones.': '',

        'Expande la capacidad de trabajo de la máquina a horarios nocturnos, haciendo que la inversión pueda ser recuperada en un año de trabajo.': '',

        '¿Cuántas secciones puede controlar el corte automático?': '',
        'Hasta 16 secciones.': '',

        '¿Se puede instalar el sistema de control de dosis por bomba variable en cualquier máquina?': '',
        'Si.': '',

        '¿Cuáles son los opcionales?': '',
        'Antena de precisión, módulo de telemetría, sensor de nivel de tanque y sensor de presión de pulverización.': '',

        //CABLEADO ELECTRICO
        'Cableado eléctrico': "",
        'Más calidad, más confiabilidad, más simplicidad.': "",

        'Diseño, desarrollo y fabricación de arneses eléctricos a demanda, para todo tipo de máquinas.': "",

        'Alto nivel de Calidad': "",
        'Material de Normas Automotivas internacionales.': "",
        'Alta flexibilidad.': "",
        'Resistencia a rayos UV.': "",
        'Resistencia a hidrocarburos.': "",
        'Antiflama.': "",
        'Derivaciones soldadas por ultrasonido.': "",
        'Terminales prensados a máquina.': "",
        'Cables codificados o coloridos.': "",
        'Control de calidad realizados por dispositivos electrónicos.': "",
        'Sello de trazabilidad incorporado en producto.': "",

        'Estética innovadora': "",
        'Utilización de corrugado cerrado': "",
        'Utilización de derivaciones plásticas': "",
        'Conectores coloreados': "",
        'Identificación escrita de funciones': "",

        //CONSOLAS
        'Consolas': '',

        'Adaptabilidad, practicidad, calidad.': '',

        'DISEÑO Y DESARROLLO A MEDIDA:': '',
        'En Sensor ofrecemos el servicio de diseño, desarrollo y fabricación de consolas para diferentes aplicaciones. Estos sistemas de control con tableros de comando, permiten una gestión integral en el uso de máquinas agrícolas y son capaces de adaptarse a todo tipo asientos y cabinas.': '',


        'Solución Integral': '',
        'Cada uno de los dispositivos mecánicos, eléctricos y electrónicos están especialmente diseñados para alcanzar una solución total, llave en mano.': '',


        'Calidad': '',
        'Alguna de las características de nuestros desarrollos:': '',
        'Diseños realizados con materiales de última generación.': '',
        'Acabados de alta gama y detalles.': '',
        'Estudios avanzados en ergonomía.': '',
        'Productos aptos para intemperie.': '',
        'Diseños modulares o integrales.': '',

        //JOYSTICK

        'Joystick': '',
        'Más adaptabilidad, más tecnología y más diseño.': '',

        'Los comandos electrónicos móviles de Sensor están diseñados a medida y se adaptan a cualquier tipo de máquina agrícola. Desarrollamos variados modelos de joystick para que puedan ser utilizados con diferentes aplicaciones. Los sistemas de electrónica convencional o con procesamiento interno autónomo, cuentan con entradas disponibles para ejecutar lógicas pre programadas, sin necesidad de una ECU.': '',

        'Adaptabilidad': '',
        'Estos comandos se adaptan a todo tipo de máquinas como así también a sistemas de transmisión mecánica, automática o hidrostática.': '',

        'Calidad': '',
        'Alguna de las características de nuestros Joysticks son:': '',
        'Pulsadores vía teclas o membrana siliconada': '',
        'Funciones con retroiluminación': '',
        'Iconos y gráfica personalizable': '',
        'Diseño ergonómico': '',
        'Sistema de funciones eléctricos directas o comandos electrónicos bajo protocolo CAN, RS 232 o 485': '',
        'Apto para uso en intemperie.': '',


        //MODULO DE ESTADO SOLIDO





        //RELAYTERAS


        //STRACK

    },
    'br':
        {
            'Detector de alta tensión':
                'Detector de alta tensão',
            'Piloto Automático':
                '',

            'Agricultura':
                '',

            //agricultura de precisión
            'Agricultura de Precisión':
                'Agricultura de Precisão',
            'Sistemas tecnológicos utilizados para realizar operaciones agrícolas de manera eficiente, aumentando la productividad, simplificando el trabajo, mejorando la calidad de los producto y ahorrando dinero.':
                'Sistemas tecnológicos utilizados para realizar operações agrícolas de forma eficiente, aumentando a produtividade, simplificando o trabalho, melhorando a qualidade dos produtos e economizando dinheiro.',
        }
    ,
}