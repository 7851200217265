import styled from "styled-components";


export const Footer = styled.section`
    background-color:black;
    color:white;
    margin-top: 50px;
    display:flex;
    flex-direction: row-reverse;
    font-size: 16px;
    div{
        margin: 35px 0px;
        margin-right: auto;
        padding-left: 79px;
    }
    p{
        font-family: "Myriad Pro";
        font-weight: 100;
        margin: 0;
    }
    b{
        margin:0;
    }
    u{
        text-decoration-color: red;
        text-decoration: none;
        border-bottom: 1px solid red;
        letter-spacing: 1px;
    }
    img{
        position: absolute;
        display: flex;
        width: 250px;
        bottom: 0;  
    }
    
    @media(max-width: 775px){
        img{
            width: 150px;
        }
        div{  
            padding-left: 15px;
        }
    }
    @media(max-width: 517px){
        img{
            width: 125px;
        }
    }
    @media(max-width: 436px){
        img{
            width: 100px;
        }
    }

`;



