import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Scrollchor from 'react-scrollchor';
import OwlCarousel from 'react-owl-carousel';


//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/multiplex12.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import logo25 from '../../../assets/img/logo25prod.png';
import dwWing from '../../../assets/img/dwWing.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/multiplex12/foto1.jpg';
import foto2 from '../../../assets/img/galeria/multiplex12/foto2.jpg';
import foto3 from '../../../assets/img/galeria/multiplex12/foto3.jpg';
import foto4 from '../../../assets/img/galeria/multiplex12/foto4.jpg';

import valor1 from '../../../assets/img/valores/multiplex12/valor1.gif';
import valor2 from '../../../assets/img/valores/multiplex12/valor2.png';
import valor3 from '../../../assets/img/valores/multiplex12/valor3.png';



const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Multiplex12 extends Component {
    componentDidMount() {
        document.title = "Sensores - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Multiplex 1.2')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más control, más flexible, más sólido')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de control versátil y adaptable.')}</p>
                                    <p className="dsc">{this.context.t('Permite controlar hasta 6 funciones ON/OFF, a través de un único sistema compacto, multiplex y automotivo industrial. Este producto, además de ser económico, brinda flexibilidad, solidez y las más variadas opciones de uso.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>
                    <div id="caracteristicas" className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 style={{fontSize: "50px"}}>{this.context.t('Sistema tecnológico básico para control y gestión on-board')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Un sistema autónomo y autosuficiente capaz de controlar hasta 6 funciones ON/OFF.')}
                                        </p>
                                        <p className="sinBorde">
                                            {this.context.t('La versatilidad de este producto hace que sea ideal para aplicar tanto en ambulancias, ómnibus urbanos y embarcaciones como así también para los fabricantes de cabinas y para color transformaciones vehiculares.')}
                                        </p>
                                    </div>

                                </Col>
                                <Col md={6} style={{paddingTop: "60px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 style={{fontSize: "50px"}}>{this.context.t('Ventajas del sistema')}</h3>
                                        <div className="red-line3"></div>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('El dispositivo cuenta con un panel de control 100% sellado, inmune al agua.')}</li>
                                            <li>{this.context.t('Cada salida posee una protección incorporada.')}</li>
                                            <li>{this.context.t('El teclado es táctil capacitado, con respuesta de iluminación.')}</li>
                                            <li>{this.context.t('No es necesario intervenir el sistema con fusibles ni proteger las salidas.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6} style={{paddingTop: "100px", padding:"100px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>


                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3 style={{fontSize:"50px"}}>{this.context.t('Características')}</h3>
                                        <div className="red-line3"></div>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Salidas a relés incluido.')}</li>
                                            <li>{this.context.t('Salidas con sistema de autoprotección.')}</li>
                                            <li>{this.context.t('Iluminación de estado en tecla.')}</li>
                                            <li>{this.context.t('Sistema touch capacitive.')}</li>
                                            <li>{this.context.t('Posibilidad de configurar iconos de funciones.')}</li>
                                            <li>{this.context.t('Cableado mínimo.')}</li>
                                            <li>{this.context.t('Dimensiones mínimas.')}</li>
                                            <li>{this.context.t('Panel a prueba de agua.')}</li>
                                            <li>{this.context.t('Teclas ON/OFF de sólo un estado.')}</li>
                                            <li>{this.context.t('6 Salidas.')}</li>
                                            <li>{this.context.t('IP68.')}</li>
                                            <li>{this.context.t('12-24V.')}</li>
                                        </ul>
                                        <br/>
                                        <p>
                                            <b>{this.context.t('Dimensiones')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Alto: 80 mm.')}</li>
                                            <li>{this.context.t('Largo\\Profundidad: 70 mm')}</li>
                                            <li>{this.context.t('Ancho: 100 mm.')}</li>
                                        </ul>
                                        <br/>
                                        <p>
                                            <b>{this.context.t('Entregable')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Panel. ')}</li>
                                            <li>{this.context.t('Relaytera.')}</li>
                                            <li>{this.context.t('Cable de interconexión.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={12} className="bgGrey">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/multiplex12/FolletoComercialMultiplex1-2-01.pdf"}>
                                        <div className="btnDescarga" style={{paddingTop: "5%", paddingBottom: "5%"}}>
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Folleto')}</h3>
                                                <h5>{this.context.t('Multiplex 1.2')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default Multiplex12;