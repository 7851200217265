import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';



//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/teclados.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import logo25 from '../../../assets/img/logo25prod.png';



//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/teclados/foto1.png';
import foto2 from '../../../assets/img/galeria/teclados/foto2.png';
import foto3 from '../../../assets/img/galeria/teclados/foto3.png';
import foto4 from '../../../assets/img/galeria/teclados/foto4.png';

import valor1 from '../../../assets/img/valores/teclados/valor1.png';
import valor2 from '../../../assets/img/valores/teclados/valor2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class TecladosPaneles extends Component {

    componentDidMount() {
        document.title = "Teclados y Paneles - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Teclados y Paneles')}</span>
                                        <img src={logo25} alt=""/>
                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más modularidad, más originalidad y más simpleza.')}</p>
                                    <p className="tit-dsc">{this.context.t('Instrumentos para accionar funciones de forma eléctrica directa.')}</p>
                                    <p className="dsc">{this.context.t('La aplicación de estos módulos preconfigurados representan un ahorro de tiempo considerable, y además permiten obtener mejores resultados. La simplicidad del diseño hace que estos sistemas sean fáciles de instalar y de usar, ya que los módulos están precableados y listos para conectar.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 >{this.context.t('Diseños funcionales')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Diseñamos y desarrollamos módulos de funciones que se adaptan a cada necesidad.')}
                                        </p>
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>


                            </Row>
                            <br/><br/>
                            <Row>
                                <Col md={6}>

                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Tecnología')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            {this.context.t('Nuestros diseños innovadores cuentan con iluminación de funciones y de estado, además cada tecla está protegida, evitando de este modo que deban agregarse fusibles de protección.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                </section>

            </div>
        )
    }
}

export default TecladosPaneles;