import React, {Component} from 'react';
import {Row, Col, Button, Accordion, Panel} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import '../../../assets/css/Producto.css';
//Import Images
import hr from '../../../assets/img/productos/hr.png';
import portada from '../../../assets/img/productos/tecnoshaft2.png';
import dwWing from '../../../assets/img/dwWing.png';


import icon1 from '../../../assets/img/productos/tecnoshaft/icon1.png';
import icon2 from '../../../assets/img/productos/tecnoshaft/icon2.png';
import icon3 from '../../../assets/img/productos/tecnoshaft/icon3.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/tecnoshaft/foto1.png';
import foto2 from '../../../assets/img/galeria/tecnoshaft/foto2.png';
import foto3 from '../../../assets/img/galeria/tecnoshaft/foto3.png';
import foto4 from '../../../assets/img/galeria/tecnoshaft/foto4.png';

//Import Valores
import valor1 from '../../../assets/img/valores/tecnoshaft/valor1.mp4';
import valor2 from '../../../assets/img/valores/tecnoshaft/valor2.png';
import valor3 from '../../../assets/img/valores/tecnoshaft/valor3.png';
import video from '../../../assets/img/valores/tecnoshaft/video.mp4';
import play from '../../../assets/img/play.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import Iconos from "../../elementos/Iconos";
import Faqs from "../../elementos/Faqs";

const photos = [
    {src: foto3, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto1, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];


class Tecnoshaft extends Component {

    componentDidMount() {
        document.title = "Tecnoshaft - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    obtengaElSuyo() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }


    render() {
        //Faqs
        let faqs = [];
        faqs.push({
            pregunta: this.context.t('¿Qué sucede con el sistema cuando debo ir en reversa?'),
            respuesta: this.context.t('El sistema levanta el eje para permitir la maniobra.')
        });
        faqs.push({
            pregunta: this.context.t('¿¿Qué sucede cuando tomo una curva muy cerrada?'),
            respuesta: this.context.t('El sistema levanta el eje, evitando arrastre de neumáticos.')
        });
        faqs.push({
            pregunta: this.context.t('¿Puedo acoplar diversos camiones al semirremolque o el sistema queda hermanado con un camión tractor?'),
            respuesta: this.context.t('El Tecnoshaft es independiente al camión y autónomo.')
        });
        faqs.push({
            pregunta: this.context.t('¿Qué sucede cuando estoy con el semirremolque cargado y lo voy descargando de a poco, en diversos tramos?'),
            respuesta: this.context.t('El sistema va midiendo el peso, y cuando detecta que el peso de la carga no necesita acción del eje, lo levanta.')
        });
        faqs.push({
            pregunta: this.context.t('¿Puede medir peso?'),
            respuesta: this.context.t('El sistema no fue diseñado para medir peso.')
        });
        faqs.push({
            pregunta: this.context.t('¿Cuántos ejes puede controlar?'),
            respuesta: this.context.t('Puede controlar hasta 2 ejes.')
        });
        faqs.push({
            pregunta: this.context.t('¿Qué pasa si alguna cosa falla?'),
            respuesta: this.context.t('Si existe algún problema o falla, el sistema baja el eje.')
        });
        faqs.push({
            pregunta: this.context.t('¿Puedo subir el eje si el implemento está cargado?'),
            respuesta: this.context.t('Si, puede subirlo de forma manual.')
        });
        faqs.push({
            pregunta: this.context.t('¿Puedo bajar el eje con el implemento vacío?'),
            respuesta: this.context.t('Si, puede bajarlo de forma manual.')
        });
        faqs.push({
            pregunta: this.context.t('¿Qué sucede cuando retiro el contacto del camión?'),
            respuesta: this.context.t('Al retirar el contacto del camión el eje bajará.')
        });
        return (
            <div>
                <section className="productos">
                    <div className="home-producto" style={{paddingBottom: "85px"}}>
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Tecnoshaft')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Mayor control, rendimiento y confort')}</p>
                                    <p className="tit-dsc">{this.context.t('Control automático del eje levadizo')}</p>
                                    <p className="dsc">{this.context.t('Este sistema electrónico automático, sirve para controlar cuando el eje levadizo debe bajar o subir, dependiendo del peso de carga que la unidad posea. Este dispositivo además impide que maniobras no recomendadas sean realizadas, sin alterar la costumbre del camionero ni generar complicaciones.')}
                                    </p>

                                    <div style={{paddingTop: "25px"}}>
                                        {/*<Col md={5} style={{padding: "0px"}}>*/}
                                            {/*<h2 style={{fontSize: "31px"}}>{this.context.t('Desde ')}*/}
                                                {/*<span>{this.context.t('U$D 960')}</span></h2>*/}
                                            {/*<p className="referencia">*/}
                                                {/*<i>*/}
                                                    {/*+ IVA. Incluye instalación del producto.*/}
                                                    {/*Los valores expresados están sujetos a modificaciones según*/}
                                                    {/*corresponda.*/}
                                                {/*</i>*/}
                                            {/*</p>*/}
                                        {/*</Col>*/}
                                        <Col md={5}>
                                            <Scrollchor to="financiacion" disableHistory={true}
                                                        beforeAnimate={() => this.obtengaElSuyo()}>
                                                <Button style={{marginTop: "20px"}}>
                                                    {this.context.t('Obtenga el suyo')}
                                                </Button>
                                            </Scrollchor>
                                            <p className="sub-button">
                                                <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>Realice su
                                                    consulta</Link>
                                            </p>
                                        </Col>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>
                    <div id="caracteristicas" className="productos-caracteristicas destacado">
                        <div className="container-limit">
                            <Row>
                                <Col md={10}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Seguridad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('El Tecnoshaft controla de forma automática el eje levadizo direccional del semiremolque o camión. Este sistema también es utilizado para impedir que maniobras peligrosas sean realizadas y para simplificar el día a día del camionero. Además con el Tecnoshaft puede informar el peso aproximado de carga y de carga potencial disponible.')}
                                        </p>
                                    </div>
                                </Col>
                                {/*<Col md={6}>*/}
                                {/*<div className="img-graficos">*/}
                                {/*<img className="img-responsive" src={pilot1} alt=""/>*/}
                                {/*</div>*/}
                                {/*</Col>*/}
                            </Row>
                            <Row>
                                <Col md={8} className="center-block">
                                    {/*<img className="img-responsive" src={valor1} alt=""/>*/}
                                    <video className="img-responsive" preload autoPlay loop>
                                        <source src={valor1} type="video/mp4"/>
                                    </video>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>

                            <Row>
                                <Col md={12}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Innovación')}</h3>
                                        <div className="red-line3"></div>
                                    </div>
                                    <img className="img-responsive" src={valor2} alt="" style={{margin: "auto"}}/>
                                </Col>
                                {/*<Col md={6}>*/}
                                {/*<div className="img-graficos">*/}
                                {/*<img className="img-responsive" src={pilot2} alt=""/>*/}
                                {/*</div>*/}
                                {/*</Col>*/}
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>


                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Rentabilidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('El Tecnoshaft garantiza un ahorro de un 30% en lo que respecta a mantenimiento de eje, suspensión, neumáticos y multas.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Iconos
                            icon1={icon1}
                            icon2={icon2}
                            icon3={icon3}
                            text1={this.context.t('Garantiza el correcto uso del implemento.')}
                            text2={this.context.t('Garantiza la mejor capacidad de frenado.')}
                            text3={this.context.t('Facilita la conducción.')}
                        />
                    </div>


                    <Row>
                        <div className="video-guia">
                            <Col xs={12} md={7} className="film-container bgGrey2">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()}
                                       type="video/mp4" controls={true}
                                       style={{margin: "0 auto", maxHeight: "474px", height: "474px"}}
                                >
                                </video>
                            </Col>

                            <Col xs={12} md={5} className="bgGrey" style={{display: ""}}>
                                <div className="descargas"
                                     style={{height: "100%", display: "flex", alignItems: "center"}}>
                                    <a target="_blank" className="incompleto"
                                       href={process.env.PUBLIC_URL + "/archivos/tecnoshaft/sensor-tecnologia-tecnoshaft.pdf"}>
                                        <div className="btnDescarga">
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Tecnoshaft')}</h5>
                                            </div>

                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>
                    {/*<Faqs faqs={faqs}/>*/}
                    <Row>
                        <div className="faq">
                            <div className="container-limit">
                                <div style={{
                                    width: "80px",
                                    height: "2px",
                                    backgroundColor: "white",
                                    margin: "0 auto"
                                }}></div>
                                <h2>{this.context.t('Preguntas Frecuentes')}</h2>
                                <Row>
                                    <Col md={6}>
                                        <Accordion>
                                            <Panel
                                                header={this.context.t('¿Qué sucede con el sistema cuando debo ir en reversa?')}
                                                eventKey="1">
                                                {this.context.t('El sistema levanta el eje para permitir la maniobra')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Qué sucede cuando tomo una curva muy cerrada?')}
                                                eventKey="2">
                                                {this.context.t('El sistema levanta el eje, evitando arrastre de neumáticos')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Puedo acoplar diversos camiones al semirremolque o el sistema queda hermanado con un camión tractor?')}
                                                eventKey="3">
                                                {this.context.t('El Tecnoshaft es independiente al camión y autónomo.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Qué sucede cuando estoy con el semirremolque cargado y lo voy descargando de a poco, en diversos tramos?')}
                                                eventKey="4">
                                                {this.context.t('El sistema va midiendo el peso, y cuando detecta que el peso de la carga no necesita acción del eje, lo levanta.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Puede medir peso?')}
                                                eventKey="5">
                                                {this.context.t('El sistema no fue diseñado para medir peso.')}
                                            </Panel>


                                        </Accordion>
                                    </Col>
                                    <Col md={6}>
                                        <Accordion>
                                            <Panel
                                                header={this.context.t('¿Cuántos ejes puede controlar?')}
                                                eventKey="1">
                                                {this.context.t('Puede controlar hasta 2 ejes.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Qué pasa si alguna cosa falla?')}
                                                eventKey="2">
                                                {this.context.t('Si existe algún problema o falla, el sistema baja el eje.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Puedo subir el eje si el implemento está cargado?')}
                                                eventKey="3">
                                                {this.context.t('Si, puede subirlo de forma manual.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Puedo bajar el eje con el implemento vacío?')}
                                                eventKey="4">
                                                {this.context.t('Si, puede bajarlo de forma manual.')}
                                            </Panel>
                                            <Panel
                                                header={this.context.t('¿Qué sucede cuando retiro el contacto del camión?')}
                                                eventKey="5">
                                                {this.context.t('Al retirar el contacto del camión el eje bajará.')}
                                            </Panel>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col md={7} className="center-block">
                                    <h6>¿Aún no resolvió sus dudas?</h6>
                                    <Link to="/contacto">
                                        <Button>
                                            Consúltenos
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>

                    </Row>


                </section>
                <SliderFinan id="financiacion" bgColor="white" color="#eb0029"/>
            </div>

        );
    }
}

export default Tecnoshaft;