import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import valor1 from '../../../assets/img/valores/dat/valor1.png';
import video from '../../../assets/img/valores/dat/video.mp4';
import dat from '../../../assets/img/galeria/dat/Dat.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/dat/foto1.png';
import foto2 from '../../../assets/img/galeria/dat/foto2.png';
import foto3 from '../../../assets/img/galeria/dat/foto3.png';
import foto4 from '../../../assets/img/galeria/dat/foto4.png';
import logo25 from '../../../assets/img/logo25prod.png';


const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Dat extends Component {
    componentDidMount() {
        window.scroll(0, 0);
        document.title = "DAT - Sensor Tecnología";
    }

    obtengaElSuyo(){
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6} sm={6}>
                                <div className="img-producto">
                                    <br/><br/>
                                    <img className="img-responsive" src={dat} alt=""/>
                                </div>
                            </Col>
                            <Col md={6} sm={6}>
                                <div className="tit-producto">
                                    <h3>
                                        <span>{this.context.t('DAT')}</span>
                                        <img src={logo25} alt=""/>
                                    </h3>
                                    <h1>
                                        {this.context.t('Detector de Alta Tensión')}
                                    </h1>

                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Mayor detección, seguridad y flexibilidad.')}
                                    </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Detector de líneas de alta tensión para maquinas autopropulsadas.')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('El DAT cuenta con un alto poder de detección y precisión. Fue diseñado para evitar que máquinas con altura variable, como las pulverizadoras, entren en contacto con líneas de alta tensión, cuando abren sus alas.')}
                                    </p>


                                    {/*<Col md={5} style={{padding: "0px"}}>*/}
                                        {/*<h2>{this.context.t('Desde')} <span>{this.context.t('U$D 2.600')}</span></h2>*/}
                                        {/*<p className="referencia">*/}
                                            {/*<i>*/}
                                                {/*+ IVA. Incluye instalación del producto.*/}
                                                {/*Los valores expresados están sujetos a modificaciones según corresponda.*/}
                                            {/*</i>*/}
                                        {/*</p>*/}
                                    {/*</Col>*/}
                                    <Col xs={12} md={6}>
                                        <Scrollchor to="financiacion" disableHistory={true} beforeAnimate={() => this.obtengaElSuyo()}>
                                            <Button style={{marginTop: "20px"}}>
                                                {this.context.t('Obtenga el suyo')}
                                            </Button>
                                        </Scrollchor>
                                        <p className="sub-button">
                                            <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>Realice su consulta</Link>
                                        </p>
                                    </Col>


                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3 style={{maxWidth: "600px"}}>{this.context.t('Precisión')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('El detector de líneas de alta tensión DAT fue desarrollado para prevenir que maquinarias con altura variable, como las pulverizadoras, entren en contacto con líneas de alta tensión, cuando abren sus alas.')}</p>

                                        <p>
                                            {this.context.t('El sistema está compuesto por una antena de detección y un dispositivo de alarma visual/auditiva dentro de la cabina.')}
                                        </p>

                                        <p>
                                            {this.context.t('Puede detectar la presencia de cables de alta tensión hasta 100 metros de distancia*, advirtiendo al operario del potencial peligro, y evitando así que sea lastimado.')}
                                        </p>

                                        <p className="referencia">
                                            {this.context.t('* Bajo condiciones de operación ideales. Generalmente, el rango de detección depende del cable detectado. A mayor voltaje, mayor rango de detección.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "80px"}}>
                                        <h3>{this.context.t('Prestaciones')}</h3>
                                        <div className="red-line3"></div>
                                        <ul className="lista-trilla" style={{fontWeight: "500"}}>
                                            <li>
                                                Detecta la presencia de líneas de alta tensión hasta 100m de
                                                distancia.*
                                            </li>
                                            <li>
                                                Sensibilidad de detección ajustable.
                                            </li>
                                            <li>
                                                Plug & Play.
                                            </li>
                                            <li>
                                                Dispositivo de alarma visual/auditiva.
                                            </li>
                                            <li>
                                                Opción “Silenciar” disponible.
                                            </li>
                                            <li>
                                                Se puede utilizar en prácticamente cualquier tipo de vehículo.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <video ref="film" className="img-responsive" preload src={video}
                                           autoPlay
                                           loop
                                           type="video/mp4"
                                           style={{marginTop:"50px"}}>
                                    </video>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={6} className="bgGrey">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/dat/MM-BRCH-000001 - Brochure PC-MODU-DAT.REV01 - ESP.pdf"}>
                                        <div className="btnDescarga">
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('DAT | Detector de Alta Tensión')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                                <Col md={6} className="bgGrey2">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/dat/MM-MANU-000026 - Manual DAT.pdf"}>

                                        <div className="btnDescarga">
                                            <img src={btnDescarga} alt=""/>
                                            <div className="dscDescarga">
                                                <h3>{this.context.t('Manual de Uso')}</h3>
                                                <h5>{this.context.t('DAT | Detector de Alta Tensión')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    <SliderFinan id="financiacion" bgColor="white"
                                 // link="https://www.mercadopago.com/mla/checkout/start?pref_id=229863581-0f76d62a-1999-467b-b1c5-60486c35e447"
                                 color="#eb0029"/>

                </section>

            </div>
        )
    }
}

export default Dat;