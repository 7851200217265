import React, {Component} from 'react';
import {Col, Row, Button, Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/consola.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import ImgAmpliable from '../../elementos/ImgAmpliable'


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/consola/foto1.png';
import foto2 from '../../../assets/img/galeria/consola/foto2.png';
import foto3 from '../../../assets/img/galeria/consola/foto3.png';
import foto4 from '../../../assets/img/galeria/consola/foto4.png';
import foto5 from '../../../assets/img/galeria/consola/foto5.png';
import foto6 from '../../../assets/img/galeria/consola/foto6.png';
import foto7 from '../../../assets/img/galeria/consola/foto7.png';
import foto8 from '../../../assets/img/galeria/consola/foto8.png';
import foto9 from '../../../assets/img/galeria/consola/foto9.png';
import foto10 from '../../../assets/img/galeria/consola/foto10.png';
import foto11 from '../../../assets/img/galeria/consola/foto11.png';
import foto12 from '../../../assets/img/galeria/consola/foto12.png';
import foto13 from '../../../assets/img/galeria/consola/foto13.png';



import valor1 from '../../../assets/img/valores/consolas/valor1.png';
import valor2 from '../../../assets/img/valores/consolas/valor2.png';
import valor4_1 from '../../../assets/img/valores/consolas/valor4_1.png';
import valor4_2 from '../../../assets/img/valores/consolas/valor4_2.png';
import valor4_3_1 from '../../../assets/img/valores/consolas/valor4_3_1.png';
import valor4_3_2 from '../../../assets/img/valores/consolas/valor4_3_2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

const lightBox = [
    {src: foto5, width: 4, height: 3},
    {src: foto6, width: 4, height: 3},
    {src: foto7, width: 4, height: 3},
    {src: foto8, width: 4, height: 3},
    {src: foto9, width: 4, height: 3},
    {src: foto10, width: 4, height: 3},
    {src: foto11, width: 4, height: 3},
    {src: foto12, width: 4, height: 3},
    {src: foto13, width: 4, height: 3}
]

const imagen1=[
    {src: valor1, width: 4, height: 4},
];
const imagen2=[
    {src: valor2, width: 4, height: 4}
];


class Consolas extends Component {

    componentDidMount() {
        document.title = "Consolas - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    cambiarTabVertical(tab) {
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && (this.refs[idTab].classList.contains("tab-vertical-body")
                || this.refs[idTab].classList.contains("tab-vertical-li")))
                this.refs[idTab].classList.remove("in");
            this.refs[tab].classList.add("in");
            this.refs[tab + "Li"].classList.add("in");
        });
    }

    changeVerticalHorizontalTab(tab) {
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && (this.refs[idTab].classList.contains("tab-vertical-horizontal-item")
                || this.refs[idTab].classList.contains("tab-vertical-horizontal-img")))
                this.refs[idTab].classList.remove("in");
            if (this.refs[tab])
                this.refs[tab].classList.add("in");
            if (this.refs[tab + "Img"])
                this.refs[tab + "Img"].classList.add("in");
        });
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto" style={{paddingBottom: "60px"}}>
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Consolas')}</span>
                                        <img src={logo25} alt=""/>
                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Mayor integración, efectividad y calidad.')}</p>
                                    <p className="tit-dsc">{this.context.t('Diseño y desarrollo de consolas a medida.')}</p>
                                    <p className="dsc">{this.context.t('Ofrecemos el servicio de diseño, desarrollo y fabricación de consolas para diferentes aplicaciones. Estos sistemas de control con tableros de comando, permiten una gestión integral en el uso de máquinas agrícolas y son capaces de adaptarse a todo tipo asientos y cabinas.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>

                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos} lightbox={lightBox}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Solución integral')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Cada uno de los dispositivos mecánicos, eléctricos y electrónicos están especialmente diseñados para alcanzar una solución total, llave en mano.')}
                                        </p>
                                        <p>
                                            {this.context.t('Este sistema de módulos permite la adaptación a una línea de máquinas de diferentes segmentos sin perder la identidado “family feeling” de la marca del cliente.')}
                                        </p>
                                        <p>
                                            {this.context.t('Otra ventaja de la modularización es que permite actualizar o renovar el aspecto del producto paulatinamente con menores exigencias económicas.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <ImgAmpliable photos={imagen1}/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Calidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            <b>{this.context.t('Alguna de las características de nuestros desarrollos:')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Diseños realizados con materiales de última generación.')}</li>
                                            <li>{this.context.t('Acabados de alta gama y detalles.')}</li>
                                            <li>{this.context.t('Estudios avanzados en ergonomía.')}</li>
                                            <li>{this.context.t('Productos aptos para intemperie.')}</li>
                                            <li>{this.context.t('Diseños modulares o integrales.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <ImgAmpliable photos={imagen2}/>
                                    </div>
                                </Col>
                            </Row>


                            <section id="calidad">
                                <br/><br/><br/><br/>
                                <Row>
                                    <Col md={6} xsHidden={true} className="tab-vertical-horizontal" style={{border:"none"}}>
                                        <h4 ref="comparativa" className="tab-vertical-horizontal-item in"
                                           style={{ position: "absolute", right: "15px",
                                               top:"0", marginRight:"50px"}}
                                           onClick={() => this.changeVerticalHorizontalTab("comparativa")}>
                                            {this.context.t('Diseño')}
                                        </h4>
                                    </Col>
                                    <Col md={6} xsHidden={true} className="tab-vertical-horizontal" style={{borderRight: "none"}}>
                                        <h4 ref="estabilidad" className="tab-vertical-horizontal-item"
                                           style={{ marginLeft:"50px"}}
                                           onClick={() => this.changeVerticalHorizontalTab("estabilidad")}>
                                            {this.context.t('Ergonomía')}
                                        </h4>
                                    </Col>
                                </Row>
                                <br/><br/>
                                <Row style={{padding: "20px"}}>
                                    <div ref="comparativaImg" className="tab-vertical-horizontal-img in">
                                        <Col xsHidden={true}>
                                            <Col md={4} className="tab-vertical">
                                                <div ref="calculadoraLi" className="tab-vertical-li in"
                                                     style={{padding: "60px"}}>
                                                    <a className="calculadora"
                                                       onClick={() => this.cambiarTabVertical("calculadora")}>
                                                        {this.context.t('Display')}
                                                    </a>
                                                    <Glyphicon id="calculadora" glyph="stop"/>
                                                </div>
                                                <div ref="pastillasLi" className="tab-vertical-li"
                                                     style={{padding: "60px"}}>
                                                    <a className="pastillas"
                                                       onClick={() => this.cambiarTabVertical("pastillas")}>
                                                        {this.context.t('Setting y control')}
                                                    </a>
                                                    <Glyphicon id="pastillas" glyph="stop"/>
                                                </div>
                                                <div ref="bombaLi" className="tab-vertical-li"
                                                     style={{padding: "60px"}}>
                                                    <a className="bomba"
                                                       onClick={() => this.cambiarTabVertical("bomba")}>
                                                        {this.context.t('Conducción')}
                                                    </a>
                                                    <Glyphicon id="bomba" glyph="stop"/>
                                                </div>

                                            </Col>
                                            <Col md={8} style={{paddingTop: "10px"}}>
                                                <div ref="calculadora" className="tab-vertical-body in">
                                                    <img className="tab-vertical-img" src={valor4_1}/>
                                                    <br/>

                                                    <p>
                                                        {this.context.t(
                                                            'A pesar de que el display concentra la mayor cantidad de controles, ' +
                                                            'la comodidad y seguridad de operación no se compromete. En la sección de manejo ' +
                                                            'se agrupan los controles de uso frecuente para control de la máquina y la aplicación. ' +
                                                            'En la consola se mantienen accesibles funciones de seguridad.')}
                                                    </p>

                                                </div>
                                                <div ref="pastillas" className="tab-vertical-body">
                                                    <img className="tab-vertical-img" src={valor4_2}/>
                                                    <br/>
                                                    <p>El Display concentra toda la información necesaria durante la
                                                        aplicación, combinando datos de la máquina, el sistema,
                                                        parámetros
                                                        de
                                                        conducción y ajustes de aplicación.
                                                        <br/>
                                                        La información en pantalla se ordena en función de la
                                                        frecuencia de control. El marco de pantalla ofrece superficies
                                                        de
                                                        grip y apoyo para una operación más precisa, confortable y
                                                        segura
                                                        durante el manejo.</p>
                                                </div>
                                                <div ref="bomba" className="tab-vertical-body">

                                                    <img src={valor4_3_1}/>
                                                    <p>
                                                        {this.context.t(
                                                            'El Joystick en posición baja permite la operación en un ángulo de confort óptimo por tiempo prolongado. La empuñadura quebrada permite que el peso de la mano se distribuya sobre la palma y la mano se relaje sin perder acceso al teclado. Los controles principales se agrupan alrededor de la posición de reposo del dedo pulgar.')}
                                                    </p>
                                                </div>

                                            </Col>
                                        </Col>
                                    </div>
                                    <div>
                                        <Col mdHidden={true} lgHidden={true} xs={12}>
                                            <b className="tab-vertical-horizontal-item"
                                               style={{color: "#eb0029", fontSize: "19px"}}>
                                                {this.context.t('Ergonomía')}
                                            </b>
                                            <br/>
                                            <br/>
                                            <img src={valor4_3_2} className="tab-vertical-horizontal-img in"/>
                                        </Col>
                                        <img ref="estabilidadImg" className="tab-vertical-horizontal-img"
                                             src={valor4_3_2}/>
                                    </div>
                                </Row>
                                <Row style={{padding: "20px"}} className="sliderDestacados">
                                    <Col mdHidden={true} lgHidden={true} xs={12} className="carouselRes">
                                        <b className="tab-vertical-horizontal-item"
                                           style={{color: "#eb0029", fontSize: "19px"}}>
                                            {this.context.t('Diseño')}
                                        </b>
                                        <br/>
                                        <br/>

                                        <OwlCarousel
                                            ref="slider2"
                                            className="owl-theme"
                                            loop={false}
                                            nav={false}
                                            navText=""
                                            dots={true}
                                            margin={2}
                                            autoplay={false}
                                            autoplayHoverPause={true}
                                            autoplayTimeout={7000}
                                            autoplaySpeed={3500}
                                            responsive={
                                                {
                                                    0: {
                                                        items: 1
                                                    },
                                                    // breakpoint from 480 up
                                                    480: {
                                                        items: 1
                                                    },
                                                    // breakpoint from 768 up
                                                    768: {
                                                        items: 1
                                                    },
                                                    // breakpoint from 768 up
                                                    991: {
                                                        items: 1
                                                    }
                                                }
                                            }
                                        >

                                            <div className="item">
                                                <Col xs={12}>
                                                    <b>
                                                        {this.context.t('Display')}
                                                    </b>
                                                    <br/>
                                                </Col>
                                                <Col xs="12">
                                                    <img className="img-responsive" src={valor4_1} alt=""
                                                         style={{margin: "auto", clear: "both"}}/>
                                                    <br/>
                                                    <p>
                                                        {this.context.t(
                                                            'A pesar de que el display concentra la mayor cantidad de controles, ' +
                                                            'la comodidad y seguridad de operación no se compromete. En la sección de manejo ' +
                                                            'se agrupan los controles de uso frecuente para control de la máquina y la aplicación. ' +
                                                            'En la consola se mantienen accesibles funciones de seguridad.')}
                                                    </p>
                                                </Col>

                                            </div>
                                            <div className="item">
                                                <Col xs={12}>
                                                    <b>
                                                        {this.context.t('Setting y control')}
                                                    </b>
                                                    <br/>
                                                </Col>
                                                <Col xs="12">
                                                    <img className="img-responsive" src={valor4_2} alt=""
                                                         style={{margin: "auto", clear: "both"}}/>
                                                    <br/>

                                                    <p>El Display concentra toda la información necesaria durante la
                                                        aplicación, combinando datos de la
                                                        máquina, el sistema, parámetros de conducción y ajustes de
                                                        aplicación. La información en pantalla se ordena en función de
                                                        la
                                                        frecuencia de control. El marco de pantalla ofrece superficies
                                                        de
                                                        grip y apoyo para una operación más precisa, confortable y
                                                        segura
                                                        durante el manejo.</p>
                                                </Col>

                                            </div>
                                            <div className="item">
                                                <Col xs={12}>
                                                    <b>
                                                        {this.context.t('Conducción')}
                                                        <br/>
                                                    </b>
                                                </Col>
                                                <Col xs="12">
                                                    <img className="img-responsive" src={valor4_3_1} alt=""
                                                         style={{margin: "auto", clear: "both"}}/>
                                                    <br/>

                                                    <p>
                                                        {this.context.t(
                                                            'El Joystick en posición baja permite la operación en un ángulo de confort óptimo por tiempo prolongado. La empuñadura quebrada permite que el peso de la mano se distribuya sobre la palma y la mano se relaje sin perder acceso al teclado. Los controles principales se agrupan alrededor de la posición de reposo del dedo pulgar.')}
                                                    </p>
                                                </Col>

                                            </div>
                                        </OwlCarousel>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    </div>

                </section>

            </div>
        )
    }
}

export default Consolas;