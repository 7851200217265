import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import directa from '../../assets/img/financiacion/compraDirecta.png';
import visa from '../../assets/img/financiacion/visa.png';
import master from '../../assets/img/financiacion/master.png';
import american from '../../assets/img/financiacion/american.png';
import shopping from '../../assets/img/financiacion/shopping.png';
import cencosud from '../../assets/img/financiacion/cencosud.png';
import cabal from '../../assets/img/financiacion/cabal.png';
import naranja from '../../assets/img/financiacion/naranja.png';
import nativa from '../../assets/img/financiacion/nativa.png';
import argencard from '../../assets/img/financiacion/argencard.png';
import diners from '../../assets/img/financiacion/diners.png';
import mercado from '../../assets/img/financiacion/mercadopago.png';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


import next from '../../assets/img/icon/nextFlecha.png';
import back from '../../assets/img/icon/befoFlecha.png';


import btnDescarga from '../../assets/img/productos/btnDescarga.png';
import btnDescargaGrey from '../../assets/img/productos/btnDescargaGrey.png';

import '../../assets/css/SliderFinan.css';

import OwlCarousel from 'react-owl-carousel';

class SliderFinan extends Component {
    triggerAnalytics(){
        var data =  {
            content_category: 'clics',
            content_name: 'clic-medios-de-pago'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'comprar',
            category: 'clics',
            label: 'clic-medios-de-pago'
        });
    }
    render() {
        const {bgColor, color, id, link} = this.props;
        return (
            <section id={id} className="sliderFinan consulta" style={{backgroundColor: bgColor}}>
                <div className="container-limit">
                    <h2 style={{color: color}}>{this.context.t('Compre este Producto')}</h2>
                    <h4 style={{
                        color: color,
                        textAlign: "center",
                        fontSize: "23px",
                        marginBottom: "50px",
                        fontFamily: "Myriad Pro",
                        fontWeight: "500"
                    }}>
                        {this.context.t('Seleccione su forma de financiación')}
                    </h4>
                    <Row>
                        <OwlCarousel
                            className="owl-theme"
                            loop={true}
                            nav
                            navText=""
                            dots
                            margin={2}
                            autoplay={false}
                            autoplayHoverPause={true}
                            autoplayTimeout={7000}
                            autoplaySpeed={3500}
                            responsive={
                                {
                                    0: {
                                        items: 2
                                    },
                                    // breakpoint from 480 up
                                    480: {
                                        items: 3
                                    },
                                    // breakpoint from 768 up
                                    768: {
                                        items: 3
                                    },
                                    // breakpoint from 768 up
                                    991: {
                                        items: 3
                                    },
                                    1260:{
                                        items: 4
                                    },
                                    1300:{
                                        items:5
                                    }
                                }
                            }
                        >
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={directa} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={visa} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={master} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={american} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={shopping} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={cencosud} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={cabal} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={naranja} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={nativa} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={argencard} alt=""/>
                                </a>
                            </div>
                            <div className="item" onClick={() => {this.triggerAnalytics()}}>
                                <a target="_blank" href={this.props.link}>
                                    <img src={diners} alt=""/>
                                </a>
                            </div>


                        </OwlCarousel>
                    </Row>
                    <h5
                        style={{
                            textAlign: "center",
                            color: "#676868",
                            fontFamily: "Myriad Pro",
                            fontWeight: "500",
                            marginTop: "80px",
                            marginBottom: "50px",
                        }}
                    >
                        Financiación operada a través de&nbsp;&nbsp;
                        <span>
                            <img src={mercado} alt=""/>
                        </span>
                    </h5>
                </div>
            </section>
        );
    }
}

export default SliderFinan