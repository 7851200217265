import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/solucionesMultiplex/1.png';
import icon_2 from '../../assets/img/categorias/solucionesMultiplex/2.png';
import icon_3 from '../../assets/img/categorias/solucionesMultiplex/3.png';
import foto_1 from '../../assets/img/categorias/solucionesMultiplex/foto_1.png';
import foto_2 from '../../assets/img/categorias/solucionesMultiplex/foto_2.png';
import foto_3 from '../../assets/img/categorias/solucionesMultiplex/foto_3.png';
import foto_4 from '../../assets/img/categorias/solucionesMultiplex/foto_4.png';


class SolucionesMultiplex extends Component {

    componentDidMount() {
        document.title = "Soluciones Multiplex - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">


                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h5>{this.context.t('Transporte')}</h5>
                        <h3>{this.context.t('Soluciones Multiplex')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Los diferentes modelos Múltiplex Sensor, fueron creados para la industria del fabricante de ómnibus, para mejorar la productividad, reducir costos operativos, y brindar valor agregado a través de las diferentes funcionalidades que se pueden aplicar para facilitar la conducción de la unidad y ofrecer al transportista mejores resultados a través de la información que el sistema genera.\n' +
                            'Todas nuestras plataformas cuentan con una excelente relación costo - beneficio,  además de incorporar avances tecnológicos adaptados a cada segmento de mercado.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p>{this.context.t('Nuestras prestaciones optimizan la economía y el confort del coche.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p>{this.context.t('Las Soluciones Multiplex cuentan con capacidad de resolución modular.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p>{this.context.t('Estos sistemas se adaptan a las necesidades de la máquina a controlar.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/multiplex-15"><p>Multiplex 1.5</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/multiplex-15"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/multiplex-20"><p>Multiplex 2.0</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/multiplex-20"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/multiplex-25"><p>Multiplex 2.5</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/multiplex-25"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/multiplex-30"><p>Multiplex 3.0</p></Link>
                                <img src={foto_4} className="img-responsive" alt=""/>
                                <Link to="/productos/multiplex-30"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default SolucionesMultiplex;