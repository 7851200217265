import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/solucionesFabricantes/1.png';
import icon_2 from '../../assets/img/categorias/solucionesFabricantes/2.png';
import icon_3 from '../../assets/img/categorias/solucionesFabricantes/3.png';
import foto_1 from '../../assets/img/categorias/solucionesFabricantes/foto_1.png';
import foto_2 from '../../assets/img/categorias/solucionesFabricantes/foto_2.png';
import foto_3 from '../../assets/img/categorias/solucionesFabricantes/foto_3.png';
import foto_4 from '../../assets/img/categorias/solucionesFabricantes/foto_4.png';


class SolucionesFabricantes extends Component {

    componentDidMount() {
        document.title = "Soluciones fabricantes - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">


                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h5>{this.context.t('Agricultura')}</h5>
                        <h3>{this.context.t('Soluciones para fabricantes')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Realizamos desarrollos de acuerdo a los requerimientos técnicos de cada fabricante, ' +
                            'teniendo en cuenta los diferentes niveles de complejidad, la utilización de elementos' +
                            ' eléctricos (hardware - software - sensores), y aplicaciones estéticas.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Desarrollos especializados en maquinaria autopropulsada - automotiva.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p>{this.context.t('Nuestras soluciones cuentan con alta capacidad de modularidad.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p>{this.context.t('Nuestros productos son configurables a demanda')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/cableado-electrico"><p>Cableados eléctricos</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/cableado-electrico"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/consolas"><p>Consolas</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/consolas"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/joystick"><p>Joysticks</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/joystick"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/modulo-solido"><p>Módulos de Estado Sólido</p></Link>
                                <img src={foto_4} className="img-responsive" alt=""/>
                                <Link to="/productos/modulo-solido"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default SolucionesFabricantes;