import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/multiplex25.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/multiplex25/foto1.png';
import foto2 from '../../../assets/img/galeria/multiplex25/foto2.png';
import foto3 from '../../../assets/img/galeria/multiplex25/foto3.png';
import foto4 from '../../../assets/img/galeria/multiplex25/foto4.png';

import valor1 from '../../../assets/img/valores/multiplex25/valor1.png';
import valor2 from '../../../assets/img/valores/multiplex25/valor2.png';
import valor3 from '../../../assets/img/valores/multiplex25/valor3.png';

import video from '../../../assets/img/valores/multiplex25/video.mp4';
import play from '../../../assets/img/play.png';
import btnDescargaGrey from '../../../assets/img/productos/btnDescargaGrey.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Multiplex25 extends Component {

    componentDidMount() {
        window.scroll(0, 0);
        document.title = "Multiplex 2.5 - Sensor Tecnología";
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Multiplex 2.5')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más fusión, más gestión, más evolución')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de tecnología media para control y gestión on board.')}</p>
                                    <p className="dsc">{this.context.t('El Multiplex 2.5 es un sistema de automatización de todas las funciones de carrocerías y  chasis de ómnibus. Cuenta con una interfaz gráfica fácil de usar y puede aplicarse en diversos vehículos, entre ellos: ómnibus de media y larga distancia; ambulancias y embarcaciones')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Software confiable')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            {this.context.t('Sistema derivado de exitosa plataforma tecnológica TEVIS, creada por Sensor Tecnología, es flexible, potente y virtualmente amigable, su software es propietario y muy confiable. Capaz de controlar y gestionar todas las funciones y actividades de una máquina compleja.')}
                                        </p>
                                        <p>
                                            {this.context.t('Esta tecnología, creada en 2014, pasó exitosamente por diversos mercados (agricultura, vial, minería y pecuaria) volviéndose más versátil y adaptable, incluyendo numerosas funcionalidades y propiedades ya pre establecidas.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Sistema adaptable')}</h3>
                                        <div className="red-line3"></div>

                                        <p>
                                            {this.context.t('El sistema trabaja bajo un método centralizado, brindando diversos módulos de entradas/salidas disponibles de acuerdo a la necesidad de la máquina a controlar.')}
                                        </p>
                                        <br/>
                                        <p><b>{this.context.t('Prestaciones')}</b></p>
                                        <p>{this.context.t('Control, gestión y automatización de todas las funciones de la carrocería:')}</p>
                                        <ul className="lista-trilla" style={{color: "#eb0029"}}>
                                            <li><b>Luces.</b></li>
                                            <li><b>Motores eléctricos.</b></li>
                                            <li><b>Electroválvulas.</b></li>
                                            <li><b>A/A.</b></li>
                                            <li><b>Cartel de ruta / itinerario.</b></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <br/><br/>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>Funciones <br/> Pre-establecidas</h3>
                                        <div className="red-line3"></div>

                                        <ul className="lista-trilla" style={{paddingLeft: "3px"}}>
                                            <li>Control de cantidad de pasajeros a bordo.</li>
                                            <li>Llamador de camarera.</li>
                                            <li>Control de límite de velocidad.</li>
                                            <li>Climatizador.</li>
                                            <li>Control de humedad para evitar vidrios empañados.</li>
                                            <li>Plataformas para elección gráfica de funciones disponibles de acuerdo a
                                                consumos.
                                            </li>
                                            <li>Librería de iconos normalizada y actualizable.</li>
                                            <li>Eventos guardados en memoria.</li>
                                            <li>Control y alarma por modalidad de conducción violenta.</li>
                                            <li>Control y alarma por inclinación.</li>
                                            <li>Sensores de estacionamiento.</li>
                                            <li>Cierre / bloqueo automático de puertas y bodegas.</li>
                                            <li>Apagado / encendido de luces de acuerdo a la velocidad.</li>
                                            <li>Alarmas de nivel de combustible programables.</li>
                                            <li>Sonidos polifónicos.</li>
                                            <li>Sistema multi-idioma.</li>
                                            <li>Programa de alarmas por mantenimiento vehicular.</li>
                                            <li>GPS incorporado.</li>
                                            <li>Generación de eventos georeferenciados en mapa, trasladable a google
                                                maps .®
                                            </li>
                                            <li>Envío de información por GPRS.</li>
                                            <li>Modo gráfico nocturno.</li>
                                            <li>Autoprotección del sistema por sobrecarga con registros.</li>
                                            <li>Diagnósticos de comunicación – entradas – salidas - consumos.</li>

                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="video-guia" style={{display: "flex"}}>
                            <Col md={7} className="film-container">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()} controls={true}
                                       type="video/mp4">
                                </video>
                            </Col>

                            <Col md={5}>
                                <div className="descargas bgGrey" style={{height: "100%"}}>
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/multiplex25/guiaRapidaMultiplex25.pdf"}>
                                        <div style={{height: "50%"}} className="btnDescarga">
                                            <img src={btnDescargaGrey} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Guía Rápida')}</h3>
                                                <h5>{this.context.t('Multiplex 2.5')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/multiplex25/manualMultiplex25.pdf"}>
                                        <div style={{height: "50%"}} className="bgGrisOsc">
                                            <div className="btnDescarga">
                                                <img src={btnDescarga} alt=""/>
                                                <div className="dscDescarga">
                                                    <h3>{this.context.t('Manual de Uso')}</h3>
                                                    <h5>{this.context.t('Multiplex 2.5')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default Multiplex25;