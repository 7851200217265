import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';

import wasap from "../../assets/img/iconMD/wasap.png";
import tel from "../../assets/img/iconMD/tel.png";
import face from "../../assets/img/iconMD/face.png";
import mail from "../../assets/img/iconMD/mail.png";
import '../../assets/css/Consulta.css';

class Consulta extends Component{
    render(){
        const {bgColor, color} = this.props;
        return(
            <section className={"consulta"} style={{backgroundColor:bgColor}}>
                <div className="container-limit">
                    <h2 style={{color:color}}>{this.context.t('Realice aquí su consulta')}</h2>
                    <Row>
                        <Col md={6}>
                            <ul style={{color:color, border:"1px solid " + color}}>
                                <li>Formas de Pago</li>
                                <li>Contado</li>
                                <li>Tarjetas</li>
                                <li>Financiación</li>
                            </ul>
                        </Col>
                        <Col md={6}>
                            <ul style={{color:color}} className="second-box">
                                <li><img src={wasap} alt=""/><span>3476692615</span></li>
                                <li><img src={tel} alt=""/><span>Líneas rotativas +54-3476 461 416/370/358</span>
                                </li>
                                <li><img src={mail} alt=""/><span>ventas@sensortecnologia.com</span></li>

                            </ul>
                        </Col>

                    </Row>
                </div>
            </section>
        );
    }
}

export default Consulta;