import React, {Component} from 'react';
import {Col, Row, Button, Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Scrollchor from 'react-scrollchor';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import portada from '../../../assets/img/productos/adas.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/adas/foto1.png';
import foto2 from '../../../assets/img/galeria/adas/foto2.png';
import foto3 from '../../../assets/img/galeria/adas/foto3.png';
import foto4 from '../../../assets/img/galeria/adas/foto4.png';

import valor1 from '../../../assets/img/valores/adas/valor1.png';
import valor2 from '../../../assets/img/valores/adas/valor2.gif';
import valor3 from '../../../assets/img/valores/adas/valor3.png';
import valor4_1 from '../../../assets/img/valores/adas/valor4_1.mp4';
import valor4_2 from '../../../assets/img/valores/adas/valor4_2.mp4';
import valor4_3 from '../../../assets/img/valores/adas/valor4_3.mp4';

import video from '../../../assets/img/valores/adas/video.mp4';
import play from '../../../assets/img/play.png';
import logo25 from '../../../assets/img/logo25prod.png';
import dwWing from '../../../assets/img/dwWing.png';
import OwlCarousel from "react-owl-carousel";

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Adas extends Component {

    componentDidMount() {
        window.scroll(0, 0);
        document.title = "Monitor rendimiento - Sensor Tecnología";
    }

    playVideo() {
        this.refs.film.play();
        this.refs.play.style.display = "none";
        this.refs.playContainer.classList.toggle("play-container");
    }

    videoEnded() {
        this.refs.play.style.display = "block";
        this.refs.playContainer.classList.toggle("play-container");
    }

    obtengaElSuyo() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    cambiarTabVertical(tab) {
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && (this.refs[idTab].classList.contains("tab-vertical-body")
                || this.refs[idTab].classList.contains("tab-vertical-li")))
                this.refs[idTab].classList.remove("in");
            this.refs[tab].classList.add("in");
            this.refs[tab + "Li"].classList.add("in");
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h3>
                                        <span>{this.context.t('ADAS')}</span>
                                        <img src={logo25} alt=""/>
                                    </h3>
                                    <h1 style={{fontSize: "48px", lineHeight: "50px"}}>
                                        {this.context.t('Sistema avanzado de asistencia al conductor')}
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Más detección, más precisión, más seguridad.')}
                                    </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Sistema inteligente para prevenir accidentes.')}
                                    </p>
                                    <p className="dsc">
                                        {this.context.t('Este sistema graba automáticamente todo lo que acontece durante la conducción, previniendo accidentes de tráfico en espacios urbanos y rutas. Su versatilidad permite aplicarlo en camiones y vehículos de servicio,  brindando la información necesaria para la reacción anticipada del conductor ante accidentes y el registro audiovisual de lo sucedido.')}
                                    </p>


                                    {/*<Col md={5} style={{padding: "0px"}}>*/}
                                    {/*<h2>{this.context.t('Desde')} <span>{this.context.t('U$D 6.500')}</span></h2>*/}
                                    {/*<p className="referencia">*/}
                                    {/*<i>*/}
                                    {/*+ IVA. Incluye instalación del producto.*/}
                                    {/*Los valores expresados están sujetos a modificaciones según corresponda.*/}
                                    {/*</i>*/}
                                    {/*</p>*/}
                                    {/*</Col>*/}
                                    <Col md={5} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <Scrollchor to="caracteristicas">
                            <h2>{this.context.t('Características Técnicas')}</h2>
                            <img src={dwWing} alt=""/>
                        </Scrollchor>
                    </div>
                    <div id="caracteristicas" className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Sistema tecnológico de calidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El sistema está compuesto por una cámara inteligente que graba automáticamente al encenderse el coche, registrando todo lo sucedido. En cuanto al diseño, la cámara está recubierta por una carcaza de protección inviolable, permitiendo que únicamente pueda acceder a las grabaciones personal autorizado.')}
                                        </p>
                                        <p className="sinBorde">
                                            {this.context.t('El Sistema ADAS también se compone por un dispositivo de alarma dentro del vehículo que, al reconocer situaciones de peligro, genera señales visuales y sonoras, advirtiendo al conductor para que pueda anticiparse al evento y maniobrar de manera correcta.')}
                                        </p>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Detección clara y precisa')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('A travaés de un sensor de visión inteligente el Adas es capaz de reconocer vehículos, ciclistas, peatones y diversos elementos, como así también detectar líneas de carril minimizando el riesgo de accidentes. El sistema mide continuamente la distancia y la velocidad relativa de los objetos, para calcular el posible riesgo de colisión con ellos.')}
                                        </p>
                                        <p className="sinBorde">
                                            {this.context.t('Esta tecnología es elegida por las mejores compañías de transportes y vehículos de servicio.')}
                                        </p>
                                        <br/>
                                        <p>
                                            <b>{this.context.t('Principales beneficios de usar este sistema:')}</b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Brinda mayor seguridad y control.')}</li>
                                            <li>{this.context.t('Facilita la conducción.')}</li>
                                            <li>{this.context.t('Previene choques.')}</li>
                                            <li>{this.context.t('Registra eventos y accidentes.')}</li>
                                            <li>{this.context.t('Fácil instalación.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <div className="red-line3" style={{margin: "80px auto"}}></div>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3 style={{fontSize: "50px"}}>{this.context.t('Principales funciones del sistema')}</h3>
                                        <div className="red-line3"></div>
                                        <ul className="lista-trilla grey">
                                            <li>{this.context.t('Graba videos de todo lo sucedido durante la conducción.')}</li>
                                            <li>{this.context.t('Detecta automáticamente riesgos durante el manejo y los registra en video.')}</li>
                                            <li>{this.context.t('Ejecuta alertas ante situaciones a prevenir.')}</li>
                                            <li>{this.context.t('Detecta la proximidad a otros vehículos y la ubicación vía GPS.')}</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>


                            <Row style={{padding: "20px"}}>
                                <div ref="comparativaImg" className="tab-vertical-horizontal-img in">
                                    <Col md={10} xsHidden={true} className="center-block">
                                        <div className="tab-horizontal">
                                            <div ref="calculadoraLi" className="horiz tab-vertical-li in"
                                                 style={{padding: "50px", paddingRight: "60px"}}>
                                                <a className="calculadora"
                                                   onClick={() => this.cambiarTabVertical("calculadora")}>
                                                    {this.context.t('Advertencia de colisión delantera')}
                                                </a>
                                                <Glyphicon id="calculadora" glyph="stop"/>
                                            </div>
                                            <div ref="pastillasLi" className="horiz tab-vertical-li"
                                                 style={{padding: "50px", paddingRight: "60px"}}>
                                                <a className="pastillas"
                                                   onClick={() => this.cambiarTabVertical("pastillas")}>
                                                    {this.context.t('Alerta de acambio accidental de carril')}
                                                </a>
                                                <Glyphicon id="pastillas" glyph="stop"/>
                                            </div>
                                            <div ref="bombaLi" className="horiz tab-vertical-li"
                                                 style={{padding: "50px", paddingRight: "60px"}}>
                                                <a className="bomba"
                                                   onClick={() => this.cambiarTabVertical("bomba")}>
                                                    {this.context.t('Aviso de partida de auto delantero')}
                                                </a>
                                                <Glyphicon id="bomba" glyph="stop"/>
                                            </div>

                                        </div>
                                        <Col md={12} style={{paddingTop: "10px"}}>
                                            <div ref="calculadora" className="tab-vertical-body in">
                                                <video className="img-responsive" preload autoPlay loop src={valor4_1}
                                                       type="video/mp4">
                                                </video>
                                                <div className="animaciones-text">
                                                    <h5>Advertencia de colisión delantera</h5>
                                                    <p>Genera una señal auditiva o vibratoria cuando existe un riesgo de
                                                        choque con un vehículo adelante, calculando la distancia y la
                                                        velocidad relativa.</p>
                                                </div>
                                            </div>
                                            <div ref="pastillas" className="tab-vertical-body">
                                                <video className="img-responsive" preload autoPlay loop src={valor4_2}
                                                       type="video/mp4">
                                                </video>
                                                <div className="animaciones-text">
                                                    <h5>Alerta de cambio de carril</h5>
                                                    <p>Genera una señal auditiva o vibratoria si el vehículo se retira
                                                        del carril mientras se maneja por encima de la velocidad
                                                        presentada.</p>
                                                </div>
                                            </div>
                                            <div ref="bomba" className="tab-vertical-body">
                                                <video className="img-responsive" preload autoPlay loop src={valor4_3}
                                                       type="video/mp4">
                                                </video>
                                                <div className="animaciones-text">
                                                    <h5>Aviso de partida de auto delantero</h5>
                                                    <p>Genera un aviso al estar parado cuando los vehículos delanteros
                                                        inician su marcha.</p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Col>
                                </div>
                            </Row>

                            <Row style={{padding: "20px"}} className="sliderDestacados">
                                <Col mdHidden={true} lgHidden={true} xs={12} className="carouselRes">
                                    <OwlCarousel
                                        ref="slider2"
                                        className="owl-theme"
                                        loop={false}
                                        nav={false}
                                        navText=""
                                        dots={true}
                                        margin={2}
                                        autoplay={false}
                                        autoplayHoverPause={true}
                                        autoplayTimeout={7000}
                                        autoplaySpeed={3500}
                                        responsive={
                                            {
                                                0: {
                                                    items: 1
                                                },
                                                // breakpoint from 480 up
                                                480: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                768: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                991: {
                                                    items: 1
                                                }
                                            }
                                        }
                                    >
                                        <div className="item">
                                            <video className="img-responsive" preload controls src={valor4_2}
                                                   type="video/mp4">
                                            </video>
                                            <h5>Advertencia de colisión delantera</h5>
                                            <p>Genera una señal auditiva o vibratoria cuando existe un riesgo de
                                                choque con un vehículo adelante, calculando la distancia y la
                                                velocidad relativa.</p>
                                        </div>
                                        <div className="item">
                                            <video className="img-responsive" preload controls src={valor4_2}
                                                   type="video/mp4">
                                            </video>
                                            <h5>Alerta de cambio de carril</h5>
                                            <p>Genera una señal auditiva o vibratoria si el vehículo se retira
                                                del carril mientras se maneja por encima de la velocidad
                                                presentada.</p>
                                        </div>
                                        <div className="item">
                                            <video className="img-responsive" preload controls src={valor4_2}
                                                   type="video/mp4">
                                            </video>
                                            <div className="animaciones-text">
                                                <h5>Aviso de partida de auto delantero</h5>
                                                <p>Genera un aviso al estar parado cuando los vehículos delanteros
                                                    inician su marcha.</p>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </Col>
                            </Row>


                        </div>
                    </div>


                    <Row>
                        <div className="video-guia">
                            <Col xs={12} md={7} className="film-container bgGrey2">
                                <div ref="playContainer" className="play-container">
                                    <img ref="play" className="play" src={play} onClick={() => this.playVideo()}
                                         alt=""/>
                                </div>
                                <video ref="film" className="img-responsive" preload src={video}
                                       onEnded={() => this.videoEnded()}
                                       type="video/mp4" controls={true}
                                       style={{margin: "0 auto", maxHeight: "474px"}}
                                >
                                </video>
                            </Col>

                            <Col xs={12} md={5} className="bgGrey">
                                <div className="descargas"
                                     style={{height: "100%", display: "flex", alignItems: "center"}}>
                                    <a target="_blank" className="incompleto"
                                       href={process.env.PUBLIC_URL + "/archivos/adas/FolletoA4ADAS-01.pdf"}>
                                        <div className="btnDescarga">
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Folleto')}</h3>
                                                <h5>{this.context.t('ADAS - Sistema avanzado de asistencia al conductor')}</h5>
                                            </div>

                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default Adas;