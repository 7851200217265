import React, {Component} from 'react';
import {Row, Col, Panel, Accordion, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import Dudas from "./dudas/Dudas";

//import '../../assets/css/Iconos.css';

class Faqs extends Component {
    constructor(props){
        super(props);
    }
    consultenos(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-consultenos'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-consultenos'
        });
    }
    render() {
        const {faqs} = this.props;
        let i = 0;
        let Acordiones = [];
        let faq = {};
        let iMax = 0;

        let Paneles = [];
        for (i = 0; faqs.length / 2 > i; i++){
            faq = faqs[i];

            Paneles.push(
                <Panel
                    header={faq.pregunta}
                    eventKey={i}>
                    {/*{faq.respuesta}*/}
                    <div dangerouslySetInnerHTML={{__html: faq.respuesta}}></div>
                </Panel>
            );
            iMax = i;
        }
        Acordiones.push(
            <Col md={6}>
                <Accordion>
                    {Paneles}
                </Accordion>
            </Col>
        );

        Paneles = [];
        for (i = iMax + 1; faqs.length > i; i++){
            faq = faqs[i];

            Paneles.push(
                <Panel
                    header={faq.pregunta}
                    eventKey={i}>
                    {/*{faq.respuesta}*/}
                    <div dangerouslySetInnerHTML={{__html: faq.respuesta}}></div>
                </Panel>
            );
        }
        Acordiones.push(
            <Col md={6}>
                <Accordion>
                    {Paneles}
                </Accordion>
            </Col>
        );

        return (
            <section id="faqs">
                <Row>
                    <div className="faq">
                        <div className="container-limit">
                            <div style={{
                                width: "80px",
                                height: "2px",
                                backgroundColor: "white",
                                margin: "0 auto"
                            }}></div>
                            <h2>{this.context.t('Preguntas Frecuentes')}</h2>
                            <Row>
                                {Acordiones}
                            </Row>
                            <Row>
                                {this.props.noMostrarConsulta ? "" :
                                <Col md={7} className="center-block">
                                    <h6>¿Aún no resolvió sus dudas?</h6>
                                    <Link to="/contacto" onClick={() => this.consultenos()}>
                                        <Button>
                                            Consúltenos
                                        </Button>
                                    </Link>
                                </Col>
                                    }
                            </Row>
                        </div>
                    </div>
                </Row>
            </section>
        );
    }
}

export default Faqs;