import React,{Component} from 'react';
import {Row, Col} from 'react-bootstrap';

//import '../../assets/css/Iconos.css';

class Iconos extends Component{
    render(){
        const {icon1,icon2,icon3,text1,text2,text3} = this.props;
        return(
            <section id="iconos">
                <Row style={{backgroundColor: "#ec0928", marginTop: "60px"}} className="iconos">
                    <div className="container-limit">
                        <Row>
                            <Col md={4}>
                                <img src={icon1} alt=""/>
                                <p>
                                    {text1}
                                </p>
                            </Col>
                            <Col md={4} className="divisor-lateral">
                                <img src={icon2} alt=""/>
                                <p>
                                    {text2}
                                </p>
                            </Col>
                            <Col md={4}>
                                <img src={icon3} alt=""/>
                                <p>
                                    {text3}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </section>
        );
    }
}

export default Iconos;