import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';



//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/ptp.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import logo25 from '../../../assets/img/logo25prod.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/ptp/foto1.png';
import foto2 from '../../../assets/img/galeria/ptp/foto2.png';
import foto3 from '../../../assets/img/galeria/ptp/foto3.png';
import foto4 from '../../../assets/img/galeria/ptp/foto4.png';

import valor1 from '../../../assets/img/valores/ptp/valor1.png';
import valor2 from '../../../assets/img/valores/ptp/valor2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class SistemaPTP extends Component {

    componentDidMount() {
        document.title = "Sistema PTP - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Sistema PTP')}</span>
                                        <img src={logo25} alt=""/>
                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más control, más seguridad, más inteligencia.')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de control para patrulleros y móviles de seguridad.')}</p>
                                    <p className="dsc">{this.context.t('Este sistema permite controlar y gestionar acciones para patrulleros y móviles de seguridad. Esta producto es seguro y confiable ya que cada uno de sus componentes cumplen con normas militares.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Integración')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El Sistema PTP se compone de un terminal virtual que soporta softwares de uso policial, militar, sistema de detección de patentes, grabación de imágenes, monitoramiento satelital de la unidad y consulta de base de datos, convirtiéndose en la plataforma de uso más segura y práctica para las fuerzas de seguridad.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Inteligencia')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            {this.context.t('ETSP-SF1 es una terminal completamente robusta, certificada por normativa militar MIL-STD-810G. Se compone de una pantalla capacitada multitáctil de 10.1” y resolución HD. La batería adicional provee hasta 11 horas de operación continua y puede ser intercambiada sin necesidad de apagar el sistema, evitando así frenar procesos críticos.\n' +
                                                'Este conjunto de características hacen del termina TPS-SF1 un dispositivo ideal para trabajo en exteriores e interiores con iluminación variable.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={12} className="bgGrey">
                                    <a target="_blank" href={process.env.PUBLIC_URL + "/archivos/ptp/ptp-catalogo.pdf"}>
                                        <div className="btnDescarga" style={{paddingTop:"5%",paddingBottom:"5%"}}>
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Sistema PTP')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                </section>

            </div>
        )
    }
}

export default SistemaPTP;