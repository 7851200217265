import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import c from '../../constants';
import {Link} from "react-router-dom";
import history from '../../history';
import LoaderNoticia from '../Loader/LoaderNoticia';
import LoaderNoticias from '../Loader/LoaderNoticias';
import '../../assets/css/Noticias.css';

import facebook from "../../assets/img/iconRed/facebookSR.png";
import whatsapp from "../../assets/img/iconRed/whatsappSR.png";
import twitter from "../../assets/img/iconRed/twitterSR.png";
import linkedin from "../../assets/img/iconRed/linkedin.png";


class Noticias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: null,
            noticias: null,
            noticiaPpal: null
        }
    }

    componentDidMount() {
        $.ajax({
            url: c.BASE_URL + "/noticias",
            type: 'GET',
            cache: false,
            before: () => {
                this.setState({fetching: true})
            },
            success: (data) => {
                this.setState({noticiaPpal: data[0]});
                this.setState({noticias: data.slice(1)});
                this.setState({fetching: false})

            }
        })
    }

    goToNoticia(id) {
        history.push("/noticia" + "/" + id);
    }

    render() {

        let noticias = this.state.noticias ? this.state.noticias.map((noticia) => {
            return (
                <div className="mini-noticia" onClick={() => this.goToNoticia(noticia.idnoticia)}>
                    <img
                        src={'http://gruposensor.com/upload/noticias/' + noticia.idnoticia + '/imagenes/small/' + noticia.img[0]}/>
                    <h4>{JSON.parse(noticia.titulo).es}</h4>
                </div>
            )
        }) : null;

        let noticiaPpal = this.state.noticiaPpal;
        //URL from current page
        const url = window.location.href + "noticia/" + (noticiaPpal ? noticiaPpal.idnoticia : "");
        //URL patterns for Social media sites share functionalities
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        const twitterUrl = `https://twitter.com/home?status=${url}`;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        const whatsAppUrl = `https://wa.me/?text=${url}`;

        return (
            <section className="noticias" style={{display: noticias && noticias.length == 0 ? "none" : "block"}}>
                <Row>
                    <Col md={8} className="noticia">
                        {
                            this.state.fetching == true || this.state.fetching == null ? <LoaderNoticia/> :
                                <div>
                                    <h2>Noticias</h2>
                                    <img
                                        src={'http://gruposensor.com/upload/noticias/' + (noticiaPpal ? noticiaPpal.idnoticia : "") + '/imagenes/big/' + (noticiaPpal ? noticiaPpal.img[0] : "")}/>
                                    <h2 className="tituloNoticia">{noticiaPpal ? JSON.parse(noticiaPpal.titulo).es : ""}</h2>
                                    <p className="copeteNoticia">
                                        <div className="breve-descripcion"
                                             dangerouslySetInnerHTML={{__html: noticiaPpal ? JSON.parse(noticiaPpal.breve_descripcion).es : ""}}>
                                        </div>
                                    </p>
                                    <br/>
                                    <Link to={"/noticia" + "/" + (noticiaPpal ? noticiaPpal.idnoticia : "")}><a
                                        className="notaCompleta">Leer noticia completa ></a></Link>
                                    <div className="redesSociales">
                                        <a target="_blank" href={facebookUrl}><img src={facebook}/></a>
                                        <a target="_blank" href={twitterUrl}><img src={twitter}/></a>
                                        <a target="_blank" href={linkedinUrl}><img src={linkedin}/></a>
                                        <a target="_blank" href={whatsAppUrl}><img src={whatsapp}/></a>
                                    </div>
                                </div>
                        }
                    </Col>
                    {
                        this.state.fetching == true || this.state.fetching == null ? <Col md={4}><LoaderNoticias/></Col> :
                            <div>
                                <Col md={4} className="masNoticias">
                                    <h2>Más noticias</h2>
                                    <div className="slider-noticias">
                                        {noticias}
                                    </div>
                                    <Link to={"/noticias"} style={{color:"white"}}>Ver todas ></Link>
                                </Col>
                            </div>
                    }
                </Row>

            </section>
        );
    }
}

export default Noticias;