import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';


import '../../assets/css/Categorias.css';
import icon_1 from '../../assets/img/categorias/productosAutonomos/1.png';
import icon_2 from '../../assets/img/categorias/productosAutonomos/2.png';
import icon_3 from '../../assets/img/categorias/productosAutonomos/3.png';
import foto_1 from '../../assets/img/categorias/productosAutonomos/foto_1.png';
import foto_2 from '../../assets/img/categorias/productosAutonomos/foto_2.png';
import foto_3 from '../../assets/img/categorias/productosAutonomos/foto_3.png';
import foto_4 from '../../assets/img/categorias/productosAutonomos/foto_4.png';


class ProductosAutonomos extends Component {

    componentDidMount() {
        document.title = "Productos de precisión - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="agri-precision">
                <section className="categ-cuerpo">
                    <div className="container-limit">
                        <h5>{this.context.t('Transporte')}</h5>
                        <h3>{this.context.t('Productos Autónomos')}</h3>
                        <div className="red-line"></div>
                        <p>{this.context.t('Diseñamos productos capaces de resolver problemas de automatización, conexión' +
                            ' y control, que requieran la aplicación de alta tecnología. Estas soluciones de fácil ' +
                            'instalación, se adaptan a todo tipo de transporte y equipos móviles en general.')}</p>
                    </div>
                </section>

                <section className="categ-features">
                    <div className="container-limit">
                        <div className="items">
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_1} alt=""/>
                                <p style={{marginTop:"3px"}}>{this.context.t('Amplia variedad de sistemas, compatibles con todo tipo de vehículos.')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_2} alt=""/>
                                <p>{this.context.t('Productos Autónomos, fáciles de instalar')}</p>
                            </div>
                            <div className="item-feature invisible1015">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="item-feature">
                                <img className="img-responsive" src={icon_3} alt=""/>
                                <p>{this.context.t('Diseñados para optimizar el funcionamiento de cada equipo.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg-line"></div>
                </section>

                <section className="productos-categoria">
                    <Row>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/tecnoshaft"><p>Tecnoshaft</p></Link>
                                <img src={foto_1} className="img-responsive" alt=""/>
                                <Link to="/productos/tecnoshaft"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/parking"><p>Parking</p></Link>
                                <img src={foto_2} className="img-responsive" alt=""/>
                                <Link to="/productos/parking"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/sistema-rec"><p>Sistema REC</p></Link>
                                <img src={foto_3} className="img-responsive" alt=""/>
                                <Link to="/productos/sistema-rec"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="img-categ">
                                <Link to="/productos/vision-nocturna"><p>Visión Nocturna</p></Link>
                                <img src={foto_4} className="img-responsive" alt=""/>
                                <Link to="/productos/vision-nocturna"><Button>Ver Más</Button></Link>
                            </div>
                        </Col>
                    </Row>
                </section>


            </div>
        );
    }
}

export default ProductosAutonomos;