import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import tel from "../../assets/img/iconWhite/tel4.png";
import face from "../../assets/img/iconWhite/face4.png";
import li from '../../assets/img/iconWhite/linkedin2.png';
import ig from '../../assets/img/iconWhite/ig2.png';
import whatsapp from '../../assets/img/iconWhite/whatsapp3.png';


class PagoConfirmado extends Component {
    render() {
        return (
            <section id="pago-procesando">
                <section className="contacto-datos">
                    <div className="container-limit">
                        <div className="titulo">
                            <h2>Gracias!</h2>
                            <div className="lineaBlanca" style={{margin: "30px auto"}}/>
                            <p>
                                Su pago está <u> confirmado.</u>
                            </p>
                            <br/>

                        </div>
                        <Row className="fila">
                            <Col md={3} className="linea-right center-text">
                                <img src={whatsapp} alt=""/><span>3476692615</span>
                            </Col>
                            <Col md={6} className="linea-right center-text">
                                <a href="tel:+543476461416">
                                    <img src={tel} alt=""/>
                                    <span>Líneas rotativas +54-3476 461 416/370/358</span>
                                </a>
                            </Col>
                            <Col md={3} className="center-text">
                                <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                                    <img src={face} alt=""/><span>Sensor Tecnología</span>
                                </a>
                            </Col>
                        </Row>
                        <Row className="fila">
                            <Col md={3} className="linea-right">
                                <a target="_blank" href="https://www.linkedin.com/company/16217851/">
                                    <img src={li} alt=""/><span>Sensor Tecnología</span>
                                </a>
                            </Col>
                            <Col md={6} className="linea-right center-text center-change" style={{paddingTop:"36x", minHeight:"106px"}}>
                                <a href="mailto:ventas@sensortecnologia.com">
                                    <b>ventas@sensortecnologia.com</b>
                                </a>
                            </Col>
                            <Col md={3} className="linea-bottom-change">
                                <a target="_blank" href="https://www.instagram.com/sensortecnologia/">
                                    <img src={ig} alt=""/><span>sensortecnologia</span>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </section>
            </section>
        );
    }
}

export default PagoConfirmado;