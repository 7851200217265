import React, {Component} from 'react';
import {Row, Col, Clearfix} from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';


import '../../assets/css/Clientes.css';

import claas from "../../assets/img/clientes/claas.png";
import helvetica from "../../assets/img/clientes/helvetica.png";
import praba from "../../assets/img/clientes/praba.png";
import vplay from "../../assets/img/clientes/vplay.png";
import metalfor from "../../assets/img/clientes/metalfor.png";
import metalsur from "../../assets/img/clientes/metalsur.png";
import allotis from "../../assets/img/clientes/allotis.png";
import vassalli from "../../assets/img/clientes/vassalli.png";

import saldivia from "../../assets/img/clientes/saldivia.png";
import kuhn from "../../assets/img/clientes/kuhn.png";
import vulcano from "../../assets/img/clientes/vulcano.png";
import metalpar from "../../assets/img/clientes/metalpar.png";
import troyano from "../../assets/img/clientes/troyano.png";
import stara from "../../assets/img/clientes/stara.png";
import rossi from "../../assets/img/clientes/rossi.png";
import nicco from "../../assets/img/clientes/niccolo.png";


class Clientes extends Component {
    render() {
        return (
            <section className="clientes">
                <div className="container-limit">
                    <h6>Nuestros Clientes</h6>
                    <Row>
                        <Col md={10} className="center-block">
                            <OwlCarousel
                                className="owl-theme"
                                loop={true}
                                nav={true}
                                autoplay={true}
                                smartSpeed={3000}
                                navText=""
                                dots={false}
                                margin={2}
                                autoplayHoverPause={false}
                                responsive={
                                    {
                                        0: {
                                            items: 2
                                        },
                                        // breakpoint from 480 up
                                        480: {
                                            items: 2
                                        },
                                        // breakpoint from 768 up
                                        768: {
                                            items: 3
                                        },
                                        // breakpoint from 768 up
                                        991: {
                                            items: 4
                                        }
                                    }
                                }
                            >
                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={claas} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={metalfor} alt=""/></Col>
                                </div>
                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={helvetica} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={metalsur} alt=""/></Col>
                                </div>
                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={praba} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={allotis} alt=""/></Col>
                                </div>
                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={vplay} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={vassalli} alt=""/></Col>
                                </div>


                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={saldivia} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={troyano} alt=""/></Col>
                                </div>


                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={kuhn} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img src={stara} alt=""/></Col>
                                </div>

                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={vulcano} alt=""/></Col>
                                    <Col md={12} xs={12} className="divMarca"><img style={{maxWidth:"93px"}} src={nicco} alt=""/></Col>
                                </div>
                                <div className="item">
                                    <Col md={12} xs={12} className="divMarca"><img src={metalpar} alt=""/></Col>
                                </div>


                            </OwlCarousel>
                        </Col>
                    </Row>
                    <p>Sensor se reserva el derecho de publicar el listado completo de clientes. Los clientes que
                        figuran en esta
                        lista sólo son algunos
                        seleccionados y actualizados periodicamente, de acuerdo a nuestros criterios y política.</p>
                </div>
            </section>
        )
            ;
    }
}

export default Clientes;