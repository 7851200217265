import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {NavLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setLanguage} from 'redux-i18n';

//Iconos
import face from '../../assets/img/face.png';
import youtube from '../../assets/img/youtube.png';
import ig from '../../assets/img/ig.png';
import ln from '../../assets/img/in.png';
import messenger from '../../assets/img/messenger-big.png';
import whatsapp from '../../assets/img/whatsapp-big.png';

//CSS
import "../../assets/css/Header.css";

//Componentes WTF
import Home from '../paginas/Home';
import AgriculturaPrecision from '../categorias/AgriculturaPrecision';
import SolucionesFabricantes from '../categorias/SolucionesFabricantes';
import SolucionesElectricas from "../categorias/SolucionesElectricas";
import SolucionesEspeciales from "../categorias/SolucionesEspeciales";
import Dat from "../productos/standarts/Dat";
import Consulta from "../elementos/Consulta";
import PilotoAutomatico from "../productos/destacados/PilotoAutomatico";
import MonitorRendimiento from "../productos/standarts/MonitorRendimiento";
import SistemaWave from "../productos/destacados/SistemaWave";
import CableadoElectrico from "../productos/standarts/CableadoElectrico";
import Consolas from "../productos/standarts/Consolas";
import Joystick from "../productos/standarts/Joystick";
import ModuloSolido from "../productos/standarts/ModuloSolido";
import Relayteras from "../productos/standarts/Relayteras";
import Strack from "../productos/standarts/Strack";
import Tecnoshaft from "../productos/destacados/Tecnoshaft";
import SistemaVisionNoc from "../productos/standarts/SistemaVisionNoc";
import Parking from "../productos/standarts/Parking";
import Multiplex12 from "../productos/standarts/Multiplex12";
import Multiplex15 from "../productos/standarts/Multiplex15";
import Multiplex20 from "../productos/standarts/Multiplex20";
import Multiplex25 from "../productos/standarts/Multiplex25";
import Multiplex30 from "../productos/destacados/Multiplex30";
import TecladosPaneles from "../productos/standarts/TecladosPaneles";
import Sensores from "../productos/standarts/Sensores";
import SistemaPTP from "../productos/standarts/SistemaPTP";
import SistemaREC from "../productos/standarts/SistemaREC";
import VideoGuia from '../elementos/VideoGuia';
import SliderFinan from "../elementos/SliderFinan";
import ProductosAutonomos from "../categorias/ProductosAutonomos";
import SolucionesMultiplex from "../categorias/SolucionesMultiplex";
import Faqs from "../elementos/Faqs";
import Hololed from "../productos/standarts/Hololed";
import Adas from "../productos/standarts/Adas";

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scrollTop: 0
        };
        this.languages = ['es', 'en', 'br'];
        this.children = [];
        this.onChangeLang = this.onChangeLang.bind(this);
        this.onScroll = this.onScroll.bind(this);


    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    componentWillMount() {
        this.props.dispatch(setLanguage('es'));
    }

    onChangeLang(e) {
        this.props.dispatch(setLanguage(e.target.value))
    };

    onScroll(e) {
        let scrollTop = e.srcElement.scrollingElement ? e.srcElement.scrollingElement.scrollTop : "";
        this.setState({scrollTop: scrollTop})
    }


    render() {
        return (
            <section className={`header ${this.state.scrollTop > 100 ? 'smaller' : ''}`}>

                <div className="container-limit">
                    <ul>
                        <li>
                            <select className="selector-lenguaje" onChange={(e) => this.onChangeLang(e)}>
                                <option value="es">ESPAÑOL</option>
                            </select>
                        </li>
                        <li><a target="_blank" href="https://www.facebook.com/sensortecnologia/"><img src={face}
                                                                                                      alt=""/></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/user/sensoragricola"><img src={youtube}
                                                                                                       alt=""/></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/sensortecnologia/"><img src={ig} alt=""/></a>
                        </li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/16217851/"><img src={ln} alt=""/></a>
                        </li>
                        <li><NavLink className="menuHeader" to="/soporte" activeclassname="active">Soporte</NavLink>
                        </li>
                        <li><NavLink className="menuHeader" to="/descargas" activeclassname="active">Descargas</NavLink>
                        </li>
                        {/*<li><NavLink to="/login">Log-in/Registro</NavLink></li>*/}
                        <li><NavLink className="menuHeader" to="/contacto" activeclassname="active">Contacto</NavLink>
                        </li>
                    </ul>
                    <ul className={`text-to-right pull-right habla-nosotros hidden-xs ${this.state.scrollTop > 100 ? 'smaller' : ''}`}
                        style={{borderLeft: "1px solid white", paddingLeft: "9px"}}>

                        <li>
                            <a target="_blank" href="https://m.me/sensortecnologia/">
                                <img src={messenger}
                                     alt=""/>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=5493476649439">
                                <img src={whatsapp}
                                     alt="Enviar mensaje por WhatsApp"/>
                            </a>
                        </li>
                        <li>
                            <p><b>Hablá</b> con nosotros</p>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}

Home.contextTypes = {
    t: React.PropTypes.func.isRequired
};

AgriculturaPrecision.contextTypes = {
    t: React.PropTypes.func.isRequired
};

SolucionesFabricantes.contextTypes = {
    t: React.PropTypes.func.isRequired
};

SolucionesElectricas.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SolucionesEspeciales.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Dat.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Consulta.contextTypes = {
    t: React.PropTypes.func.isRequired
};
PilotoAutomatico.contextTypes = {
    t: React.PropTypes.func.isRequired
};
MonitorRendimiento.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SistemaWave.contextTypes = {
    t: React.PropTypes.func.isRequired
};
CableadoElectrico.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Joystick.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Consolas.contextTypes = {
    t: React.PropTypes.func.isRequired
};
ModuloSolido.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Relayteras.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Strack.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Tecnoshaft.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SistemaVisionNoc.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Parking.contextTypes = {
    t: React.PropTypes.func.isRequired
};

Multiplex12.contextTypes = {
    t: React.PropTypes.func.isRequired
};

Multiplex15.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Multiplex20.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Multiplex25.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Multiplex30.contextTypes = {
    t: React.PropTypes.func.isRequired
};

TecladosPaneles.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SistemaPTP.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SistemaREC.contextTypes = {
    t: React.PropTypes.func.isRequired
};
VideoGuia.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SliderFinan.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Sensores.contextTypes = {
    t: React.PropTypes.func.isRequired
};
ProductosAutonomos.contextTypes = {
    t: React.PropTypes.func.isRequired
};
SolucionesMultiplex.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Faqs.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Hololed.contextTypes = {
    t: React.PropTypes.func.isRequired
};
Adas.contextTypes = {
    t: React.PropTypes.func.isRequired
};


export default withRouter(connect(state => ({
    lang: state.i18nState.lang
}))(Header));
