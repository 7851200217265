import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

//import '../../assets/css/Iconos.css';
import dwBtn from '../../assets/img/dw-btn.png';

class BotonDescarga extends Component {
    render() {
        const {texto, linkDescarga, descripcion, paddingA} = this.props;
        return (
            <div className="container-btn-descarga">
                <div className="btn-descarga">
                    <img src={dwBtn} alt=""/>
                    <a target="_blank" href={linkDescarga} style={{paddingRight:paddingA}}>
                        {texto}
                    </a>
                </div>
                {descripcion ?
                    <div className="btn-descripcion">{descripcion}</div>
                    : ""}
            </div>
        );
    }
}

export default BotonDescarga;