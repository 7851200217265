import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../../assets/css/Footer.css';

import wasap from "../../assets/img/iconWhite/wasap2.png";
import tel from "../../assets/img/iconWhite/tel3.png";
import face from "../../assets/img/iconWhite/face3.png";
import mail from "../../assets/img/iconWhite/mail3.png";
import li from '../../assets/img/iconWhite/linkedin.png';
import ig from '../../assets/img/iconWhite/ig.png';

class Footer extends Component {
    render() {
        return (
            <section className="footer">
                <div className="container-limit">
                    <Row className="footer-red">
                        <Col md={3} sm={6} xs={6} className="col-12-personalizada">
                            <div className="col-linea">
                                <h4>TRANSPORTE</h4>
                                <ul>
                                    <li><Link to="categorias/productos-autonomos">Productos Autónomos</Link></li>
                                    <li><Link to="/productos/tecnoshaft">Tecnoshaft</Link></li>
                                    <li><Link to="/productos/parking">Parking</Link></li>
                                    <li><Link to="/productos/parking">Sensores de
                                        estacionamiento</Link></li>
                                    <li><Link to="/productos/parking">Cámaras de retroceso</Link></li>
                                    <li><Link to="/productos/s-trak">Telemetría S-Trak</Link></li>
                                    <li><Link to="/productos/vision-nocturna">Cámaras de Visión Nocturna</Link></li>
                                    <li><Link to="/productos/sistema-rec">Sistema REC</Link></li>
                                    <li><Link to="/productos/adas">Adas</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/categorias/soluciones-multiplex">Soluciones Multiplex</Link></li>
                                    <li><Link to="/productos/multiplex-12">Multiplex 1.2</Link></li>
                                    <li><Link to="/productos/multiplex-15">Multiplex 1.5</Link></li>
                                    <li><Link to="/productos/multiplex-20">Multiplex 2.0</Link></li>
                                    <li><Link to="/productos/multiplex-25">Multiplex 2.5</Link></li>
                                    <li><Link to="/productos/multiplex-30">Multiplex 3.0</Link></li>
                                    <li><Link to="/productos/modulo-solido">Módulos Estado Sólido</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/categorias/soluciones-electricas">Soluciones Eléctricas</Link></li>
                                    <li><Link to="/productos/cableado-electrico">Cableados Eléctricos</Link></li>
                                    <li><Link to="/productos/teclados-paneles">Teclados</Link></li>
                                    <li><Link to="/productos/relayteras">Relayteras</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="col-12-personalizada">
                            <div className="col-linea">
                                <h4 className="otro-margin">TRANSFORMACIÓN VEHICULAR</h4>
                                <ul>
                                    <li><Link to="/categorias/soluciones-multiplex">Soluciones Multiplex</Link></li>
                                    <li><Link to="/productos/multiplex-12">Multiplex 1.2</Link></li>
                                    <li><Link to="/productos/multiplex-15">Multiplex 1.5</Link></li>
                                    <li><Link to="/productos/multiplex-20">Multiplex 2.0</Link></li>
                                    <li><Link to="/productos/multiplex-25">Multiplex 2.5</Link></li>
                                    <li><Link to="/productos/multiplex-30">Multiplex 3.0</Link></li>
                                    <li><Link to="/productos/modulo-solido">Módulos Estado Sólido</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/categorias/soluciones-electricas">Soluciones Eléctricas</Link></li>
                                    <li><Link to="/productos/cableado-electrico">Cableados Eléctricos</Link></li>
                                    <li><Link to="/productos/teclados-paneles">Teclados</Link></li>
                                    <li><Link to="/productos/relayteras">Relayteras</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="col-12-personalizada">
                            <div className="col-linea">
                                <h4 className="otro-margin">SOLUCIONES ESPECIALES</h4>
                                <ul>
                                    <li style={{fontWeight: 100}}><Link to="/productos/joysticks">Joysticks</Link></li>
                                    <li><Link to="/productos/s-trak">Telemetría S-Trak</Link></li>
                                    <li><Link to="/productos/consolas">Consolas</Link></li>
                                    <li><Link to="/productos/teclados-paneles">Teclados</Link></li>
                                    <li><Link to="/productos/cableado-electrico">Cableados Eléctricos</Link></li>
                                    <li><Link to="/productos/hololed">Hololed</Link></li>
                                    <li><Link to="/productos/relayteras">Relayteras</Link></li>
                                    <li><Link to="/productos/sensores">Sensor Electrónico Indicador de Nivel</Link></li>
                                    <li><Link to="/productos/sistema-rec">Sistema REC</Link></li>
                                    <li><Link to="/productos/sistema-ptp">Sistema PTP</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="col-12-personalizada">
                            <div className="col-linea">
                                <h4>AGRICULTURA</h4>
                                <ul>
                                    <li><Link to="/categorias/agricultura-precision">Agricultura de Precisión</Link>
                                    </li>
                                    <li><Link to="/productos/dat">DAT</Link></li>
                                    <li><Link to="/productos/piloto-automatico">Piloto Automático</Link></li>
                                    <li><Link to="/productos/monitor-rendimiento">Monitor de Rendimiento</Link></li>
                                    <li><Link to="/productos/sistema-wave">Sistema Wave</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/categorias/soluciones-fabricantes">Soluciones para fabricantes</Link>
                                    </li>
                                    <li><Link to="/productos/cableado-electrico">Cableados Eléctricos</Link></li>
                                    <li><Link to="/productos/consolas">Consolas</Link></li>
                                    <li><Link to="/productos/joysticks">Joysticks</Link></li>
                                    <li><Link to="/productos/s-trak">Telemetría S-Trak</Link></li>
                                    <li><Link to="/productos/relayteras">Relayteras</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <section className="footer-consulta">
                        <Row>
                            <h5>Buscanos en:</h5>
                        </Row>
                        <Row className="linea-sup">
                            <Col md={2} xs={2} className="mod-1148 linea-right linea-bottom center-text">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                                        <img src={face} alt=""/><span>Sensor Tecnología</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={2} xs={2} className="mod-1148 linea-right linea-bottom center-text">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/company/16217851/">
                                        <img src={li} alt=""/><span>Sensor Tecnología</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={2} xs={2} className="mod-1148 linea-right linea-bottom del-linea center-text">
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/sensortecnologia/">
                                        <img src={ig} alt=""/><span>sensortecnologia</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={4} className="invisible-1148 linea-right linea-bottom center-text" style={{width: "29%"}}>
                                <li>
                                    <a href="tel:+543476461416">
                                    <img src={tel} alt=""/><span>Líneas rotativas +54-3476 461 416/370/358</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={2} className="invisible-1148 center-text" style={{width: "20.9%"}}>
                                <li>
                                    <a href="mailto:ventas@sensortecnologia.com">
                                    <img src={mail} alt=""/><span>ventas@sensortecnologia.com</span>
                                </a>
                                </li>
                            </Col>

                            <Col md={6} sm={6} xs={6} className="visible-1148 linea-right center-text">
                                <li>
                                    <a href="tel:+543476461416">
                                        <img src={tel} alt=""/><span>Líneas rotativas +54-3476 461 416/370/358</span>
                                    </a>
                                </li>
                            </Col>
                            <Col md={6} sm={6} xs={6} className="visible-1148 center-text">
                                <li>
                                    <a href="mailto:ventas@sensortecnologia.com">
                                        <img src={mail} alt=""/><span>ventas@sensortecnologia.com</span>
                                    </a>
                                </li>
                            </Col>
                        </Row>
                    </section>


                    <section className="footer-consulta-res">
                        <Row>
                            <h5>Buscanos en:</h5>
                        </Row>
                        <Row className="container-contacto">
                            <div className="item-contacto">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                                        <img src={face} alt=""/>
                                    </a>
                                </li>
                            </div>
                            <div className="item-contacto">
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/company/16217851/">
                                        <img src={li} alt=""/>
                                    </a>
                                </li>
                            </div>
                            <div className="item-contacto">
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/sensortecnologia/">
                                        <img src={ig} alt=""/>
                                    </a>
                                </li>
                            </div>
                            <div className="item-contacto">
                                <li>
                                    <a href="tel:+543476461416">
                                        <img src={tel} alt=""/>
                                    </a>
                                </li>
                            </div>
                            <div className="item-contacto">
                                <li>
                                    <a href="mailto:ventas@sensortecnologia.com">
                                        <img src={mail} alt=""/>
                                    </a>
                                </li>
                            </div>
                        </Row>
                    </section>


                </div>
                <Row className="footer-black taco">
                    <p>Ruta Nac. Nro. 34 km 54.8, Totoras, Santa Fe, Argentina - +54-3476 461 416/370/358</p>
                    <a target="_blank" href="http://www.ideorama.com.ar/">Diseñado por IDEORAMA</a>
                </Row>
            </section>
    );
    }
    }

    export default Footer;