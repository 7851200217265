import React, {Component} from 'react';
import styled from 'styled-components';


import logo25 from '../../../assets/img/logo25.png';

const Container = styled.section`
    background: rgba(198,46,46,1);
    background: -moz-linear-gradient(top, rgba(198,46,46,1) 0%, rgba(239,55,55,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(198,46,46,1)), color-stop(100%, rgba(239,55,55,1)));
    background: -webkit-linear-gradient(top, rgba(198,46,46,1) 0%, rgba(239,55,55,1) 100%);
    background: -o-linear-gradient(top, rgba(198,46,46,1) 0%, rgba(239,55,55,1) 100%);
    background: -ms-linear-gradient(top, rgba(198,46,46,1) 0%, rgba(239,55,55,1) 100%);
    background: linear-gradient(to bottom, rgba(198,46,46,1) 0%, rgba(239,55,55,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#c62e2e", endColorstr="#ef3737", GradientType=0 );
    width: 100%;
    
    p{
        font-family: 'Myriad Pro';
        font-weight: 300;
        font-size: 18px;
        margin: 0px
    }
    
    img{
        margin: 15px;
    }
    
`;

const H3 = styled.h3`
    font-size: 30px;
    font-family: 'Myriad Pro';
    margin-top:30px;
    margin-bottom:5px;
    font-weight: 100;
    b {
        font-family: 'Myriad Pro';
        font-weight: 500; 
    }
`;

const Button = styled.button`
    margin-top: 10px;
    margin-bottom: 10px;
    height: 38px;
    background-color: white;
    color: #606060;
    font-family: 'Myriad Pro';
    font-weight: 100;
    border:none;
    font-size:16px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: auto;
    align-self: center;
    -webkit-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    transition: all .2s ease-in-out;
    :hover{
        transform:scale(1.2);
    }
`;


class BarraOportunidad extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <Container>
                <div className='container-limit' style={{display: 'flex', flexWrap:'wrap'}}>
                    <img src={logo25} alt="Sensor 25 años"/>
                    <div style={{color: 'white'}}>
                        <H3>Oportunidad de <b>precio bonificado</b></H3>
                        <p>Sumate a la promoción especial 25° aniversario.</p>
                        <p>Registrate para asegurar tu descuento (cupos limitados).</p>
                    </div>
                    <Button onClick={() => this.props.onClick()}>Adquirí el descuento</Button>
                </div>
            </Container>
        );
    }
}

export default BarraOportunidad;