import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';



//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/rec.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/rec/foto1.png';
import foto2 from '../../../assets/img/galeria/rec/foto2.png';
import foto3 from '../../../assets/img/galeria/rec/foto3.png';
import foto4 from '../../../assets/img/galeria/rec/foto4.png';

import valor1 from '../../../assets/img/valores/rec/valor1.png';
import valor2 from '../../../assets/img/valores/rec/valor2.gif';
import valor3 from '../../../assets/img/valores/rec/valor3.png';


const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class SistemaREC extends Component {

    componentDidMount() {
        document.title = "Sistema REC - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }
    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Sistema REC')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más seguridad, más control, más modularidad')}</p>
                                    <p className="tit-dsc">{this.context.t('Sistema de video vigilancia vehicular.')}</p>
                                    <p className="dsc">{this.context.t('El sistema Sensor REC, es una unidad DVR con características específicas. Se compone por cámaras de visión nocturna, que registran y guardan en una memoria interna todo lo que sucede, también cuenta con un GPS interno para realizar geoposición de imágenes.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Modularidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('El sistema cuenta con 4 cámaras (todas aptas a intemperie y antivandálicas, que permiten registrar y guardar a la vez), y con un domo de control manual, para el seguimiento de acciones a distancia.')}
                                        </p>
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Gran versatilidad')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            {this.context.t('El Sistema REC puede ser aplicado y adaptado en: ómnibus, ambulancias, patrulleros, trenes, automóviles de seguridad, camión de caudales y otros medios de transporte.')}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor3} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>


                </section>

            </div>
        )
    }
}

export default SistemaREC;