import React, {Component} from 'react';
import {Row, Col, Button, Accordion, Panel} from 'react-bootstrap';
import '../../../assets/css/Producto.css';
import hr from '../../../assets/img/productos/hr.png';
import miniHr from '../../../assets/img/productos/miniHr.png';

import piloto from '../../../assets/img/productos/piloto.png';
import dwWing from '../../../assets/img/dwWing.png';
import pilot1 from '../../../assets/img/productos/piloto/1.png';
import pilot2 from '../../../assets/img/productos/piloto/2.png';
import pilot3 from '../../../assets/img/productos/piloto/3.png';
import ahorro from '../../../assets/img/productos/tecnoshaft/icon1.png';
import precision from '../../../assets/img/productos/tecnoshaft/icon1.png';
import inversion from '../../../assets/img/productos/tecnoshaft/icon1.png';
import video from '../../../assets/img/productos/video.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import Consulta from '../../elementos/Consulta';
import Footer from '../../layout/Footer';

class Productos extends Component {
    render() {
        return (
            <div>
                <section className="productos">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={piloto} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>Piloto Automático</h1>
                                    <br/>
                                    <img src={hr} alt=""/>
                                    <p>Más tecnología, más rendimiento, más confiable.</p>
                                    <h2>Desde <span>$100.000</span></h2>
                                    <Button>Obtenga el suyo</Button>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="caracteristicas-tecnicas bgRed">
                        <h2>Características Técnicas</h2>
                        <img src={dwWing} alt=""/>
                    </div>
                    <div className="productos-caracteristicas">
                        <Row>
                            <Col md={6}>
                                <div className="caracteristicas-descripcion">
                                    <h3>Precisión</h3>
                                    <br/><br/>
                                    <img src={hr} alt=""/>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus assumenda
                                        cum
                                        cupiditate dolorum ducimus eligendi quia, quibusdam quis ullam vel! A aliquam
                                        esse
                                        libero, minima officia ullam. Aspernatur, dolorum, exercitationem!
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="img-graficos">
                                    <img className="img-responsive" src={pilot1} alt=""/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <img style={{margin: "0 auto", display: "block", marginTop: "90px", marginBottom: "90px"}}
                                 src={hr} alt=""/>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="caracteristicas-descripcion">
                                    <h3>Tecnología</h3>
                                    <br/><br/>
                                    <img src={hr} alt=""/>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus assumenda
                                        cum
                                        cupiditate dolorum ducimus eligendi quia, quibusdam quis ullam vel! A aliquam
                                        esse
                                        libero, minima officia ullam. Aspernatur, dolorum, exercitationem!
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="img-graficos">
                                    <img className="img-responsive" src={pilot2} alt=""/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <img style={{margin: "0 auto", display: "block", marginTop: "90px", marginBottom: "90px"}}
                                 src={hr} alt=""/>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <div className="caracteristicas-descripcion">
                                    <h3>Confianza</h3>
                                    <br/><br/>
                                    <img src={hr} alt=""/>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus assumenda
                                        cum
                                        cupiditate dolorum ducimus eligendi quia, quibusdam quis ullam vel! A aliquam
                                        esse
                                        libero, minima officia ullam. Aspernatur, dolorum, exercitationem!
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="img-graficos">
                                    <img className="img-responsive" src={pilot3} alt=""/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <ul>
                                <li><img src={ahorro} alt=""/><p>Ahorro de hasta un <b> 20% </b> de costos de insumos,
                                    por
                                    campaña.</p></li>
                                <li><img src={precision} alt=""/><p><b>Mayor precisión </b> en las maniobras y
                                    operaciones.
                                </p></li>
                                <li><img src={inversion} alt=""/><p><b>Retorno de la inversión</b> en sólo un año de
                                    trabajo
                                    (ahorro por cada hectárea realizada).</p></li>
                            </ul>
                        </Row>
                    </div>
                    <Row>
                        <img style={{width: "100vw"}} src={video} alt=""/>
                    </Row>

                    <Row>
                        <div className="descargas bgRed">
                            <Row>
                                <Col md={6} style={{borderRight: "1px solid white"}}>
                                    <div className="btnDescarga">
                                        <img src={btnDescarga} alt=""/>
                                        <div className="dscDescarga">
                                            <h3>Guía Rápida</h3>
                                            <h5>Mapeador / Corte automático de secciones</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="btnDescarga">
                                        <img src={btnDescarga} alt=""/>
                                        <div className="dscDescarga">
                                            <h3>Manual de Uso</h3>
                                            <h5>Piloto automático y computadora de aplicación.</h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                    <Row>
                        <div className="faq">
                            <div className="container-limit">
                                <img src={miniHr} alt=""/>
                                <h2>Preguntas Frecuentes</h2>
                                <Accordion>
                                    <Panel header="¿Collapsible Group Item #1?" eventKey="1">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad
                                        squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food
                                        truck
                                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a
                                        bird
                                        on it
                                        squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                        helvetica,
                                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                        excepteur
                                        butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                                        aesthetic
                                        synth
                                        nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </Panel>
                                    <Panel header="¿Collapsible Group Item #2?" eventKey="2">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad
                                        squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food
                                        truck
                                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a
                                        bird
                                        on it
                                        squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                        helvetica,
                                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                        excepteur
                                        butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                                        aesthetic
                                        synth
                                        nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </Panel>
                                    <Panel header="¿Collapsible Group Item #3?" eventKey="3">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad
                                        squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food
                                        truck
                                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a
                                        bird
                                        on it
                                        squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                        helvetica,
                                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                        excepteur
                                        butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                                        aesthetic
                                        synth
                                        nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </Panel>
                                    <Panel header="¿Collapsible Group Item #4?" eventKey="4">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                        richardson ad
                                        squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food
                                        truck
                                        quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a
                                        bird
                                        on it
                                        squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                        helvetica,
                                        craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan
                                        excepteur
                                        butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                                        aesthetic
                                        synth
                                        nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </Panel>
                                </Accordion>
                            </div>
                        </div>
                    </Row>
                </section>
                <Consulta bgColor="white" color="#ec0928"/>
            </div>

        );
    }
}

export default Productos;