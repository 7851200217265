import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/relaytera.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/relaytera/foto1.png';
import foto2 from '../../../assets/img/galeria/relaytera/foto2.png';
import foto3 from '../../../assets/img/galeria/relaytera/foto3.png';
import foto4 from '../../../assets/img/galeria/relaytera/foto4.png';

import valor1 from '../../../assets/img/valores/relayteras/valor1.png';
import valor2 from '../../../assets/img/valores/relayteras/valor2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Relayteras extends Component {

    componentDidMount() {
        document.title = "Relaytera - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto" style={{paddingBottom: "60px"}} >
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Relayteras Automotivas Eléctricas')}</span>
                                        <img src={logo25} alt=""/>
                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>
                                        {this.context.t('Mayor capacidad de transformación y desarrollo tecnológico')}
                                        </p>
                                    <p className="tit-dsc">
                                        {this.context.t('Desarrollos eléctricos versátiles y de altas prestaciones.')}
                                        </p>
                                    <p className="dsc">
                                        {this.context.t('Las Relayteras Automativas Eléctricas son unidades de comando de potencia. En Sensor implementamos un sistema de aplicación según requerimiento, a través del cual ofrecemos un servicio de relevamiento de funciones y consumos, para aplicar la unidad de mejor conveniencia.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="" style={{paddingLeft:"50px"}}>
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Escalables')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('La modularidad y tamaño reducido de nuestros modelos permite adaptarlos a cualquier espacio disponible.')}
                                        </p>
                                    </div>

                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Características de nuestras Relayteras')}</h3>
                                        <div className="red-line3"></div>

                                        <p className="sinBorde">
                                            <ul className="lista-trilla">
                                                <li>{this.context.t('Resistente a la intemperie: IP 50')}</li>
                                                <li>{this.context.t('Disponible en 12 y 24 V')}</li>
                                                <li>{this.context.t('Anti flama')}</li>
                                                <li>{this.context.t('Prueba de funciones realizada en computador, con trazabilidad registrada.')}</li>
                                            </ul>
                                        </p>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos" style={{paddingTop:"80px"}}>
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>


                </section>

            </div>
        )
    }
}

export default Relayteras;