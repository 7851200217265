import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {
    Container, Header, Contenido, P,
    Button, Info, Input, Footer, Cerrar
} from "./EstilosOportunidad";
import $ from 'jquery';

import logo25 from '../../../assets/img/oportunidad/logo25bg.png';
import logoSensor from '../../../assets/img/oportunidad/logo.png';
import piloto from '../../../assets/img/oportunidad/footer_piloto.png';

class ModalOportunidad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                origen: "Modal Oportunidad",
                telefono: "",
                consulta: ""
            },
            error: null,
            enviado: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);

    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/sendMail.php",
            // url: 'http://localhost/Sensor/php/sendMail.php',
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function (data) {
                this.setState({spinner: true});
                this.setState({consulta: "Llamar a: " + data.telefono})
            }.bind(this),
            cache: false,
            success: function () {
                // console.log("success");
                this.setState({enviado: true});
                this.setState({error: false});
                this.setState({spinner: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                // console.log(err);
                this.setState({error: true});
                this.setState({enviado: false});
                this.setState({spinner: false});
            }.bind(this)
        });
    }

    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
        // console.log(this.state.form);
    }

    render() {
        //chau scroll de la pagina de atras
        document.getElementsByTagName('html')[0].style.overflow = this.props.show ? "hidden" : "auto";
        return (
            <div>
                <Modal
                    {...this.props}
                    bsSize="large"
                >
                    <div onClick={this.props.onHide} style={Cerrar}>&#x2715;</div>
                    <Modal.Body style={Container}>
                        <Header>
                            <img src={logoSensor} style={{alignSelf: "center", marginLeft: "50px"}} alt=""/>
                            <img className="logo25" src={logo25} style={{marginLeft: "auto", height: "155px"}} alt=""/>
                        </Header>

                        <Contenido>
                            <div hidden={!this.state.enviado}>
                                <h3 style={{fontSize: "30px"}}>
                                    Tus datos fueron enviados exitosamente.
                                </h3>
                                <P style={{marginBottom: "40px"}}>Un asistente te llamará a la brevedad</P>
                            </div>
                            <div hidden={!this.state.error}>
                                <h3 style={{fontSize: "30px"}}>
                                    Ocurrio un error.
                                </h3>
                                <P style={{marginBottom: "40px"}}>Intente nuevamente mas tarde</P>
                            </div>
                            <div hidden={this.state.enviado || this.state.error == true}>
                                <h2>PROMOCIÓN 25° ANIVERSARIO</h2>
                                <P>
                                    <b>Obtené tu descuento especial</b> por un
                                    Piloto Automático Sensor y <b>pesificá</b> también el precio.
                                </P>
                                <P>
                                    Para acceder al DESCUENTO confirmá tu identidad <br/>ingresando tu teléfono
                                </P>
                                <form
                                    onSubmit={(e) => this.submitForm(e)}
                                >
                                    <Input id="telefono" type="number" placeholder="Teléfono o WhatsApp*" required={true}
                                           onChange={(e) => this.changeForm(e)}/>
                                    {this.state.spinner ?
                                        <div className="lds-dual-ring"></div>
                                        :
                                        <Button type="submit">Enviar datos</Button>
                                    }
                                </form>
                                <Info>
                                    *La información requerida es obligatoria para que se pueda establecer el contacto.
                                    Los datos personales no serán difundidos.
                                </Info>
                            </div>
                        </Contenido>
                        <div hidden={this.state.enviado || this.state.error == true}>
                        <Footer>
                            <img src={piloto}/>
                            <div>
                                <p>
                                    PILOTO AUTOMÁTICO.
                                </p>
                                <b>ÚTIL, ACTIVO, <u>VERDADERO.</u></b>
                            </div>
                        </Footer>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ModalOportunidad;