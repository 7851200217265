import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import joystick from '../../../assets/img/productos/joystick.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';
import logo25 from '../../../assets/img/logo25prod.png';


//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/jostick/foto1.png';
import foto2 from '../../../assets/img/galeria/jostick/foto2.png';
import foto3 from '../../../assets/img/galeria/jostick/foto3.png';
import foto4 from '../../../assets/img/galeria/jostick/foto4.png';

import valor1 from '../../../assets/img/valores/joystick/valor1.png';
import valor2 from '../../../assets/img/valores/joystick/valor2.png';
import valor3_1 from '../../../assets/img/valores/joystick/valor3_1.png';
import valor3_2 from '../../../assets/img/valores/joystick/valor3_2.png';
import valor4_1 from '../../../assets/img/valores/joystick/valor4_1.png';
import valor4_2 from '../../../assets/img/valores/joystick/valor4_2.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Joystick extends Component {

    componentDidMount() {
        document.title = "Joysticks - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    changeHorizontalTab(tab, nroSlider, slide) {
        Object.keys(this.refs).some((idTab) => {
            // console.log(".tab-horizontal-item." + nroSlider);
            if (this.refs[idTab].classList && this.refs[idTab].classList.contains("tab-horizontal-item") && this.refs[idTab].classList.contains(nroSlider))
                this.refs[idTab].classList.remove("in");
            this.refs[tab].classList.add("in");
        });
        let slider = this.refs["slider" + nroSlider];
        if (slider)
            slider.to(slide, 100);
    }

    changeSlider1(param) {
        // console.log(param);
        if (param.item.index == 0)
            this.changeHorizontalTab("corte", 1, 0);
        else
            this.changeHorizontalTab("mapeo", 1, 1);
    }

    realiceSuConsulta() {
        var data = {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={joystick} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1>
                                        <span>{this.context.t('Joysticks')}</span>
                                        <img src={logo25} alt=""/>
                                    </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Mayor adecuación, innovación y diseño.')}</p>
                                    <p className="tit-dsc">{this.context.t('Comandos electrónicos móviles para diversas aplicaciones')}</p>
                                    <p className="dsc">{this.context.t('Diseñamos Joysticks a medida que se adaptan a diferentes máquinas agrícolas. Cada modelo está desarrollado para ser utilizado con distintas aplicaciones. Los sistemas de electrónica convencional o con procesamiento interno autónomo, cuentan con entradas disponibles para ejecutar lógicas pre programadas, sin necesidad de una ECU.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="caracteristica-cables">
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Comandos')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('Estos comandos se adaptan a todo tipo de máquinas como así también a sistemas de transmisión mecánica, automática o hidrostática.')}
                                        </p>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion" style={{marginTop: "60px"}}>
                                        <h3>{this.context.t('Calidad')}</h3>
                                        <div className="red-line3"></div>
                                        <p>
                                            <b>
                                                {this.context.t('Alguna de las características de nuestros Joysticks son:')}
                                            </b>
                                        </p>
                                        <ul className="lista-trilla">
                                            <li>{this.context.t('Pulsadores vía teclas o membrana siliconada')}</li>
                                            <li>{this.context.t('Funciones con retroiluminación')}</li>
                                            <li>{this.context.t('Iconos y gráfica personalizable')}</li>
                                            <li>{this.context.t('Diseño ergonómico')}</li>
                                            <li>{this.context.t('Sistema de funciones eléctricos directas o comandos electrónicos bajo protocolo CAN, RS 232 o 485')}</li>
                                            <li>{this.context.t('Apto para uso en intemperie.')}</li>
                                        </ul>

                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <section className="seccion">
                            <Row>
                                <Col md={6} xsHidden={true} className="caracteristicas-descripcion">
                                    <img src={valor4_1} style={{
                                        maxWidth: "120px",
                                        minHeight: "118px",
                                        float: "right",
                                        cursor: "pointer"
                                    }} onClick={() => this.changeHorizontalTab("corte", 1, 0)}/>
                                    <h4 ref="corte" className="tab-horizontal-item 1 in" style={{
                                        float: "right",
                                        clear: "both",
                                        paddingRight: "20px",
                                        paddingTop: "20px"
                                    }}
                                        onClick={() => this.changeHorizontalTab("corte", 1, 0)}>{this.context.t('ETH')}</h4>
                                    <br/>
                                </Col>
                                <Col md={6} xsHidden={true}>
                                    <img src={valor4_2} style={{
                                        maxWidth: "120px",
                                        minHeight: "118px",
                                        cursor: "pointer",
                                        float: "left"
                                    }} onClick={() => this.changeHorizontalTab("mapeo", 1, 1)}/>
                                    <h4 ref="mapeo" className="tab-horizontal-item 1"
                                        style={{clear: "both", paddingLeft: "20px", paddingTop: "20px", float: "left"}}
                                        onClick={() => this.changeHorizontalTab("mapeo", 1, 1)}>{this.context.t('PULA II')}</h4>
                                </Col>
                            </Row>
                            <Row className="sliderDestacados">
                                <Col md={10} className="center-block">
                                    <OwlCarousel
                                        ref="slider1"
                                        className="owl-theme"
                                        loop={false}
                                        nav={false}
                                        navText=""
                                        dots={true}
                                        margin={2}
                                        autoplay={false}
                                        autoplayHoverPause={true}
                                        autoplayTimeout={7000}
                                        autoplaySpeed={3500}
                                        onDragged={(e) => this.changeSlider1(e)}
                                        responsive={
                                            {
                                                0: {
                                                    items: 1
                                                },
                                                // breakpoint from 480 up
                                                480: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                768: {
                                                    items: 1
                                                },
                                                // breakpoint from 768 up
                                                991: {
                                                    items: 1
                                                }
                                            }
                                        }
                                    >
                                        <div className="item" id="mapeo">
                                            <Col mdHidden={true} lgHidden={true} xs={12}
                                                 className="caracteristicas-descripcion">
                                                <h4 className="tab-horizontal-item"
                                                    onClick={() => this.changeHorizontalTab("corte", 1, 0)}>{this.context.t('ETH')}</h4>
                                                <br/>
                                            </Col>
                                            <Row>
                                                <Col md="6" xs="12" className="caracteristicas-descripcion">
                                                    <h4>{this.context.t('ETH Joystick para pulverizadoras')}</h4>
                                                    <ul className="lista-trilla">
                                                        <li>
                                                            {this.context.t('Teclado de silicona.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('13 funciones disponibles.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Iconos y gráfica personalizable.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Teclas con ciclo de vida de 1.000.000 de pulsaciones.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Comunicación CAN bus / RS 485.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Retroiluminado.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Diseño ergonómico.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Fácil de usar.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Cuerpo resistente y robusto.')}
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col md="6" xs="12">
                                                    <img className="img-responsive" src={valor3_1} alt=""
                                                         style={{margin: "auto", clear: "both"}}/>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: "50px"}}>
                                                <Col md="6" xs="12" className="caracteristicas-descripcion">
                                                    <p>
                                                        {this.context.t('Desarrollado usando tecnología electrónica de punta, el joystick ETH fue pensado para enriquecer la experiencia de manejo a través de mejoras en la interfaz de control de aplicación. El teclado de goma brinda una sensación táctil agradable con íconos retroiluminados. El cuerpo de plástico asegura una posición de descanso natural de la mano,')}
                                                    </p>
                                                </Col>
                                                <Col md="6" xs="12" className="caracteristicas-descripcion">
                                                    <p>
                                                        {this.context.t(' por lo que el joystick sigue siendo confortable incluso en usos intensivos y prolongados. La parte posterior está diseñada con curvas suaves y sin aristas filosas, dejando que la mano se mueva y descanse libremente a lo largo del cuerpo. Estas características hacen de ETH un joystick líder en su rubro, no habiendo otro igual con los mismos niveles de tecnología y ergonomía.')}
                                                    </p>
                                                </Col>
                                            </Row>


                                        </div>
                                        <div className="item ">
                                            <Col mdHidden={true} lgHidden={true} xs={12}
                                                 className="caracteristicas-descripcion">
                                                <h4 className="tab-horizontal-item" style={{float: "left"}}
                                                    onClick={() => this.changeHorizontalTab("mapeo", 1, 1)}>{this.context.t('PULA II')}</h4>
                                            </Col>
                                            <Row>
                                                <Col md="4" xs="12" className="caracteristicas-descripcion">
                                                    <h4>{this.context.t('PULA II Joystick ')}</h4>
                                                    <ul className="lista-trilla">
                                                        <li>
                                                            {this.context.t('De 3 a 9 funciones disponibles.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Opciones de pulsadores:')}
                                                            <ul className="lista-trilla">
                                                                <li>
                                                                    {this.context.t('MEC Serie 3.')}
                                                                </li>
                                                                <li>
                                                                    {this.context.t('Otto Serie P9.')}
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            {this.context.t('Gráfica e íconos personalizables.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Mango de goma macizo.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t('Cuerpo resistente y robusto.')}
                                                        </li>
                                                        <li>
                                                            {this.context.t(' Apoyo inferior para mano.')}
                                                        </li>


                                                    </ul>
                                                </Col>
                                                <Col md="8" xs="12">
                                                    <h4>{this.context.t('PULA II Versiones ')}</h4>
                                                    <img className="img-responsive" src={valor3_2} alt=""
                                                         style={{margin: "auto", clear: "both"}}/>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: "50px"}}>
                                                <Col md="6" xs="12" className="caracteristicas-descripcion">
                                                    <p>
                                                        {this.context.t('El Joystick PULA II fue desarrollado para configurarse en diferentes versiones (de 3 a 9 pulsadores) de acuerdo a las necesidades particulares del cliente. Además, se destaca por el material elegido para su cuerpo, Santoprene 50 Shore D. PULA II es el único joystick en el mercado inyectado completamente ')}
                                                    </p>
                                                </Col>
                                                <Col md="6" xs="12" className="caracteristicas-descripcion">
                                                    <p>
                                                        {this.context.t(' con este tipo particular de goma, haciéndolo macizo y resistente pero también ligeramente blando y antideslizante, las cuales son características ideales para el confort de la mano. PULA II se destaca de su competencia gracias a su versatilidad y diseño mejorado.')}
                                                    </p>
                                                </Col>
                                            </Row>

                                        </div>

                                    </OwlCarousel>
                                </Col>
                            </Row>
                        </section>

                    </div>
                </section>

            </div>
        )
    }
}

export default Joystick;