import React, {Component} from 'react';
import {Row, Col, Accordion, Panel} from 'react-bootstrap';

//CSS
import '../../assets/css/Descargas.css';

//Img
import miniHr from '../../assets/img/productos/miniHr.png';
import dwBtn from '../../assets/img/dw-btn.png';
import dwBtnDisabled from '../../assets/img/dw-btn-disabled.png';
import wasap from "../../assets/img/iconRed/whats.png";
import tel from "../../assets/img/iconRed/tel.png";
import face from "../../assets/img/iconRed/face.png";
import mail from "../../assets/img/iconRed/mail.png";

//Components
import BotonDescarga from "../elementos/BotonDescarga";
import BotonDescargaDisabled from "../elementos/BotonDescargaDisabled";


class Descargas extends Component {

    componentDidMount() {
        document.title = "Descargas - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Row>
                    <section className="faq descargas">
                        <div className="container-limit">
                            <div className="lineaBlanca"/>
                            <h2 style={{textAlign: "left"}}>Descargas</h2>
                            <Accordion>
                                <Panel header="Agricultura" eventKey="1">
                                    <div className="accordionIn">
                                        <Accordion>
                                            <Panel header="Agricultura de Precisión" eventKey="1">
                                                <ul>
                                                    <li>

                                                        <div className="item-descargas agricultura">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Utilidades</p>
                                                                </Col>
                                                                <BotonDescarga texto="AgroGIS" descripcion="Actualización. Visualización de Mapas AgroGIS_01783_rg300sen."
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/utilidades/AgroGIS_01783_rg300sen.zip"}/>
                                                                <BotonDescarga texto="AgroGIS" descripcion="Actualización. Manual AgroGis."
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/utilidades/Mini_Manual_AgroGis.rar"}/>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4}>
                                                                </Col>
                                                                <BotonDescarga texto="Conversor de Mapas" descripcion="Actualización. Programa para convertir mapas de Log a Shp."
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/utilidades/log2shp.7z"}/>
                                                                <BotonDescarga texto="Conversor de Mapas" descripcion="Actualización. Plantilla para SMS V10."
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/utilidades/Sensor_R05_Plantilla.imt"} style={{width:"105px"}}/>
                                                                <BotonDescarga texto="Conversor de Mapas" descripcion="Actualización. Manual del Conversor de Mapas."
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/utilidades/Sensor_Manual_Conversor_Mapas.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>

                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>DAT</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescarga texto="Manual de Uso"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/dat/MM-MANU-000026 - Manual DAT.pdf"}/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/dat/MM-BRCH-000001 - Brochure PC-MODU-DAT.REV01 - ESP.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Piloto Automático</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescarga texto="Manual de Uso"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/piloto/MM-BRCH-000006 - Brochure PC-MODU-VTWAVECAP.V.00 - ESP.pdf"}/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/piloto/MM-MANU-000029 - Manual de Usuario T-Wave (Completo) Tractor.pdf"}/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/piloto/folleto-piloto.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Monitor de Rendimiento</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de Uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/monitor/sensor-tecnologia-sr2-5.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Sistema Wave</p>
                                                                </Col>
                                                                <BotonDescarga texto="Guía Rápida"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/sistemaWave/guiaRapidaWave.pdf"}/>
                                                                <BotonDescarga texto="Manual de Uso"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/sistemaWave/manualUsoWave.pdf"}/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/sistemaWave/catalogoWave.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                            <Panel header="Soluciones para Fabricantes" eventKey="2">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Cableados Eléctricos</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Consolas</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Joysticks</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Telemetría S-Trak</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Relayteras</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                        </Accordion>
                                    </div>
                                </Panel>
                                <Panel header="Transporte" eventKey="2">
                                    <div className="accordionIn">
                                        <Accordion>
                                            <Panel header="Productos autónomos" eventKey="1">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Tecnohaft</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/tecnoshaft/sensor-tecnologia-tecnoshaft.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Parking</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Telemetría S-Trak</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Cámaras de visión nocturna</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/vision/catalogo-Vision-Nocturna.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Sistema REC</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Adas</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescarga linkDescarga={process.env.PUBLIC_URL + "/archivos/adas/FolletoA4ADAS-01.pdf"} texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                            <Panel header="Soluciones Multiplex" eventKey="2">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 1.2</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescarga texto="Folleto" linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex12/FolletoComercialMultiplex1-2-01.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 1.5</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex15/Multiplex 1-5.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 2.0</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex20/FolletoMultiplex2.0_web.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 2.5</p>
                                                                </Col>
                                                                <BotonDescarga texto="Guía Rápida"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/guiaRapidaMultiplex25.pdf"}/>
                                                                <BotonDescarga texto="Manual de Uso"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/manualMultiplex25.pdf"}/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/guiarapida-multiplex25.pdf"}/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/FolletoMultiplex2.5_web.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 3.0</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex30/Multiplex3-0.pdf"}/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex30/FolletoMultiplex 3.0_web.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Modulo de estado sólido</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/mes/catalogo-mes.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                            <Panel header="Soluciones eléctricas" eventKey="3">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Cableados eléctricos</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Teclado</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Relayteras</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                        </Accordion>
                                    </div>
                                </Panel>
                                <Panel header="Transformación Vehicular" eventKey="3">
                                    <div className="accordionIn">
                                        <Accordion>
                                            <Panel header="Soluciones Multiplex" eventKey="2">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 1.2</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescarga texto="Folleto" linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex12/FolletoComercialMultiplex1-2-01.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 1.5</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex15/Multiplex 1-5.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 2.0</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex20/FolletoMultiplex2.0_web.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 2.5</p>
                                                                </Col>
                                                                <BotonDescarga texto="Guía Rápida"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/guiaRapidaMultiplex25.pdf"}/>
                                                                <BotonDescarga texto="Manual de Uso"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/manualMultiplex25.pdf"}/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/guiarapida-multiplex25.pdf"}/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/FolletoMultiplex2.5_web.pdf"}/>
                                                            </Row>
                                                            <Row className="rowFlex">
                                                                <Col md={4}>
                                                                </Col>
                                                                <b>Sistema de Ubicacion a Pedido:</b>
                                                                <BotonDescarga texto="Aplicación" paddingA="19px"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/LbyR_1.3.apk"}/>
                                                                <BotonDescarga texto="Resumen   "
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex25/_Resumen.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Multiplex 3.0</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex30/Multiplex3-0.pdf"}/>
                                                                <BotonDescarga texto="Folleto"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/multiplex30/FolletoMultiplex 3.0_web.pdf"}/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Modulo de estado sólido</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescarga texto="Catálogo"
                                                                               linkDescarga={process.env.PUBLIC_URL + "/archivos/mes/catalogo-mes.pdf"}/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                            <Panel header="Soluciones eléctricas" eventKey="3">
                                                <ul>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Cableados eléctricos</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Teclado</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item-descargas">
                                                            <Row>
                                                                <Col md={4}>
                                                                    <p>Relayteras</p>
                                                                </Col>
                                                                <BotonDescargaDisabled texto="Guía Rápida"/>
                                                                <BotonDescargaDisabled texto="Manual de uso"/>
                                                                <BotonDescargaDisabled texto="Catálogo"/>
                                                                <BotonDescargaDisabled texto="Folleto"/>
                                                            </Row>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </Panel>
                                        </Accordion>
                                    </div>
                                </Panel>
                                <Panel header="Soluciones Especiales" eventKey="4">
                                    <ul>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Joysticks</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Telemetría S-Trak</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Consolas</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Teclado</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Cableados eléctricos</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Relayteras</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Sensor electrónico de nivel</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescarga texto="Catálogo"
                                                                   linkDescarga={process.env.PUBLIC_URL + "/archivos/sensores/sensoresDeLiquido.pdf"}/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Sistema REC</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Sistema PTP</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescarga texto="Catálogo"
                                                                   linkDescarga={process.env.PUBLIC_URL + "/archivos/ptp/ptp-catalogo.pdf"}/>
                                                    <BotonDescargaDisabled texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="item-descargas">
                                                <Row>
                                                    <Col md={4}>
                                                        <p>Hololed</p>
                                                    </Col>
                                                    <BotonDescargaDisabled texto="Guía Rápida"/>
                                                    <BotonDescargaDisabled texto="Manual de uso"/>
                                                    <BotonDescargaDisabled texto="Catálogo"/>
                                                    <BotonDescarga linkDescarga={process.env.PUBLIC_URL + "/archivos/hololed/FolletoA4hololed-01.pdf"} texto="Folleto"/>
                                                </Row>
                                            </div>
                                        </li>
                                    </ul>
                                </Panel>
                            </Accordion>
                        </div>
                    </section>
                    <section className="consulta-aqui">
                        <div className="container-limit">
                            <h2>Realice aquí su consulta</h2>
                            <Row className="linea-sup">
                                <Col md={3} className="linea-right center-text">
                                    <li><img src={wasap} alt=""/><span>3476692615</span></li>
                                </Col>
                                <Col md={3} className="linea-right center-text">
                                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/">
                                        <li><img src={face} alt=""/><span>Sensor Tecnología</span></li>
                                    </a>
                                </Col>
                                <Col md={6} className="center-text">
                                    <a href="tel:+543476461416">
                                        <li><img src={tel}
                                                 alt=""/><span>Líneas rotativas +54-3476 461 416/370/358</span>
                                        </li>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="linea-bottom center-text">
                                <Col md={12}>
                                    <a href="mailto:ventas@sensortecnologia.com">
                                        <li><img src={mail} alt=""/><span>ventas@sensortecnologia.com</span></li>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </Row>
            </div>
        );
    }
}

export default Descargas;