import React, {Component} from 'react';
import Scrollchor from 'react-scrollchor';
import {Row, Col, Button, Accordion, Panel, Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../../../assets/css/Producto.css';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
//Import Images
import hr from '../../../assets/img/productos/hr.png';
import portada from '../../../assets/img/productos/sistemaWave/WAVE-portada.png';
import dwWing from '../../../assets/img/dwWing.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaGrey from '../../../assets/img/productos/btnDescargaGrey.png';

import solucionIntegra from '../../../assets/img/productos/sistemaWave/solucionIntegral.png';

import icon1 from '../../../assets/img/productos/piloto/icon1.png';
import icon2 from '../../../assets/img/productos/piloto/icon2.png';
import icon3 from '../../../assets/img/productos/piloto/icon3.png';

//Import Imagenes Galeria
import foto1 from '../../../assets/img/productos/sistemaWave/1.png';
import foto2 from '../../../assets/img/productos/sistemaWave/2.png';
import foto3 from '../../../assets/img/productos/sistemaWave/3.png';
import foto4 from '../../../assets/img/productos/sistemaWave/4.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import Iconos from "../../elementos/Iconos";
import Faqs from "../../elementos/Faqs";

//Import Valores
import valor1_1_1 from '../../../assets/img/valores/sistemaWave/valor1_1_1.png';
import valor1_1_2 from '../../../assets/img/valores/sistemaWave/valor1_1_2.png';
import valor1_2_1 from '../../../assets/img/valores/sistemaWave/valor1_2_1.png';
import valor1_2_2 from '../../../assets/img/valores/sistemaWave/valor1_2_2.png';

import valor2_1 from '../../../assets/img/valores/sistemaWave/valor2_1.png';
import valor2_2 from '../../../assets/img/valores/sistemaWave/valor2_2.png';
import valor2_3_1 from '../../../assets/img/valores/sistemaWave/valor2_3_1.png';
import valor2_3_2 from '../../../assets/img/valores/sistemaWave/valor2_3_2.png';
import valor2_3_3 from '../../../assets/img/valores/sistemaWave/valor2_3_3.png';
import valor2_4 from '../../../assets/img/valores/sistemaWave/valor2_4.png';
import valor3_11 from '../../../assets/img/valores/sistemaWave/valor3_11.png';
import valor3_12 from '../../../assets/img/valores/sistemaWave/valor3_12.png';
import valor3_2 from '../../../assets/img/valores/sistemaWave/valor3_2.png';

// import '../../../assets/css/sliderDestacados.css';
import OwlCarousel from 'react-owl-carousel';
import ImgAmpliable from "../../elementos/ImgAmpliable";
import logo25 from '../../../assets/img/logo25prod.png';

const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

const imagen=[
    {src: valor3_12, width: 4, height: 4}

];

class SistemaWave extends Component {

    componentDidMount() {
        document.title = "Sistema wave - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    obtengaElSuyo(){
        var data = {
            content_category: 'clics',
            content_name: 'bt-obtenga-el-suyo'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action: 'comprar',
            category: 'clics',
            label: 'bt-obtenga-el-suyo'
        });
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    cambiarTabVertical(tab) {
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && (this.refs[idTab].classList.contains("tab-vertical-body")
                    || this.refs[idTab].classList.contains("tab-vertical-li")))
                this.refs[idTab].classList.remove("in");
            this.refs[tab].classList.add("in");
            this.refs[tab + "Li"].classList.add("in");
        });
    }

    changeVerticalHorizontalTab(tab) {
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && (this.refs[idTab].classList.contains("tab-vertical-horizontal-item")
                    || this.refs[idTab].classList.contains("tab-vertical-horizontal-img")))
                this.refs[idTab].classList.remove("in");
            if (this.refs[tab])
                this.refs[tab].classList.add("in");
            if (this.refs[tab + "Img"])
                this.refs[tab + "Img"].classList.add("in");
        });
    }

    changeHorizontalTab(tab, nroSlider, slide) {
        // console.log(tab);
        Object.keys(this.refs).some((idTab) => {
            if (this.refs[idTab].classList && this.refs[idTab].classList.contains("tab-horizontal-item") && this.refs[idTab].classList.contains(nroSlider))
                this.refs[idTab].classList.remove("in");
            this.refs[tab].classList.add("in");
        });
        // console.log(this.refs);
        let slider = this.refs["slider" + nroSlider];
        if (slider)
            slider.to(slide, 100);
    }

    changeSlider1(param) {
        if (param.item.index == 0)
            this.changeHorizontalTab("corte", 1, 0);
        else
            this.changeHorizontalTab("mapeo", 1, 1);
    }

    changeSlider2(param) {
        if (param.item.index == 0)
            this.changeHorizontalTab("pulverizadora", 2, 0);
        else
            this.changeHorizontalTab("arneses", 2, 1);
    }

    render() {
        //Faqs
        let faqs = [];
        faqs.push({
            pregunta: this.context.t('¿Cuántas secciones puede controlar el corte automático?'),
            respuesta: this.context.t('El corte automático puede controlar hasta 16 secciones.')
        });
        faqs.push({
            pregunta: this.context.t('¿Se puede instalar el sistema de control de dosis por bomba variable en cualquier máquina?'),
            respuesta: this.context.t('Si, este sistema de control puede instalarse en cualquier máquina.')
        });
        faqs.push({
            pregunta: this.context.t('¿Cuáles son los opcionales?'),
            respuesta: this.context.t('Los opcionales son: Antena de precisión, módulo de Telemetría, Sensor de nivel de tanque y Sensor de presión de pulverización.')
        });
        return (
            <div>
                <div className="sliderDestacados">
                    <section className="productos">
                        <div className="home-producto">
                            <Row>
                                <Col md={6}>
                                    <div className="img-producto">
                                        <img className="img-responsive" src={portada} alt=""
                                             style={{maxHeight: "600px", marginTop: "-40px"}}/>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="tit-producto">
                                        <h1>
                                            <span>{this.context.t('Sistema Wave')}</span>
                                            <img src={logo25} alt=""/>
                                        </h1>
                                        <img src={hr} alt=""/>
                                        <p>
                                            {this.context.t('Más integración, más versatilidad, más adaptabilidad')}
                                        </p>
                                        <p className="tit-dsc">
                                            {this.context.t('Sistema integral para agricultura de precisión.')}
                                        </p>
                                        <p className="dsc">
                                            {this.context.t('Este sistema ofrece una solución integral para el productor, permitiéndole optimizar el funcionamiento de la máquina, simplificar su trabajo y generar grandes ahorros por hectárea realizada. La versatilidad de este sistema hace que sea adaptable a cualquier tipo y modelo de pulverizador autopropulsado.')}
                                        </p>
                                        {/*<Col md={5} style={{padding: "0px"}}>*/}
                                            {/*<h2 style={{fontSize: "31px"}}>{this.context.t('Desde ')}*/}
                                                {/*<span>{this.context.t('U$D 15.500')}</span></h2>*/}
                                            {/*<p className="referencia">*/}
                                                {/*<i>*/}
                                                    {/*+ IVA. Incluye instalación del producto.*/}
                                                    {/*Los valores expresados están sujetos a modificaciones según*/}
                                                    {/*corresponda.*/}
                                                {/*</i>*/}
                                            {/*</p>*/}
                                        {/*</Col>*/}
                                        <Col md={5}>
                                            <Scrollchor to="financiacion" disableHistory={true} beforeAnimate={() => this.obtengaElSuyo()}>
                                                <Button style={{marginTop: "20px"}}>
                                                    {this.context.t('Obtenga el suyo')}
                                                </Button>
                                            </Scrollchor>
                                            <p className="sub-button">
                                                <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>Realice su consulta</Link>
                                            </p>
                                        </Col>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                        <div className="home-gallery">
                            <Galeria photos={photos}/>
                        </div>
                        <div className="caracteristicas-tecnicas bgRed">
                            <Scrollchor to="caracteristicas">
                                <h2>{this.context.t('Características Técnicas')}</h2>
                                <img src={dwWing} alt=""/>
                            </Scrollchor>
                        </div>
                        <div id="caracteristicas" className="productos-caracteristicas destacado">
                            <div className="container-limit">
                                <Row>
                                    <div className="container-limit-md">
                                        <Col md={6}>
                                            <div className="caracteristicas-descripcion">
                                                <h3>{this.context.t('Solución integral')}</h3>
                                                <div className="red-line3"></div>
                                                <p>
                                                    {this.context.t('El Sistema Wave integra los siguientes productos:')}
                                                    <ul className="lista-trilla">
                                                        <li>{this.context.t('Computadora de aplicación.')}</li>
                                                        <li>{this.context.t('Mapeo y guía satelital.')}</li>
                                                        <li>{this.context.t('Piloto Automático.')}</li>
                                                        <li>{this.context.t('Corte automático de secciones.')}</li>
                                                        <li>{this.context.t('VRT (Dosis variable)')}</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <img className="img-responsive" src={solucionIntegra} alt=""
                                                 style={{margin: "auto"}}/>
                                        </Col>
                                    </div>
                                </Row>


                                <section className="seccion">
                                    <Row>
                                        <Col md={7} xsHidden={true} smHidden={true} className="caracteristicas-descripcion">
                                            <h4 ref="corte" className="tab-horizontal-item 1 in"
                                                style={{float: "right"}}
                                                onClick={() => this.changeHorizontalTab("corte", 1, 0)}>{this.context.t('Corte automático de secciones')}</h4>
                                            <br/>
                                        </Col>
                                        <Col md={5} xsHidden={true} smHidden={true}>
                                            <h4 ref="mapeo" className="tab-horizontal-item 1" style={{float: "left"}}
                                                onClick={() => this.changeHorizontalTab("mapeo", 1, 1)}>{this.context.t('Mapeo')}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="center-block">
                                            <OwlCarousel
                                                ref="slider1"
                                                className="owl-theme"
                                                loop={false}
                                                nav={false}
                                                navText=""
                                                dots={true}
                                                margin={2}
                                                autoplay={false}
                                                autoplayHoverPause={true}
                                                autoplayTimeout={7000}
                                                autoplaySpeed={3500}
                                                onDragged={(e) => this.changeSlider1(e)}
                                                responsive={
                                                    {
                                                        0: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 480 up
                                                        480: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        768: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        991: {
                                                            items: 1
                                                        }
                                                    }
                                                }
                                            >
                                                <div className="item" id="mapeo">
                                                    <Col mdHidden={true} lgHidden={true} xs={12}
                                                         className="caracteristicas-descripcion">
                                                        <h4 className="tab-horizontal-item in"
                                                            onClick={() => this.changeHorizontalTab("corte", 1, 0)}>{this.context.t('Corte automático de secciones')}</h4>
                                                        <br/>
                                                    </Col>
                                                    <Col md="7" xs="12" className="caracteristicas-descripcion">
                                                        <p>
                                                            El corte automático de secciones evita la superposición
                                                            entre
                                                            pasadas
                                                            o los huecos en la aplicación activando o desactivando las
                                                            válvulas
                                                            de
                                                            secciones en forma automática.
                                                            <br/>
                                                            Actualmente el corte automático de secciones maneja hasta
                                                            16 secciones. Los tiempos de actuación de las válvulas son
                                                            contemplados
                                                            por el control y configurables.
                                                        </p>
                                                        <img className="img-responsive" src={valor1_1_1} alt=""/>
                                                    </Col>
                                                    <Col md="5" xs="12">
                                                        <img className="img-responsive" src={valor1_1_2} alt=""
                                                             style={{
                                                                 maxWidth: "250px",
                                                                 margin: "auto",
                                                                 clear: "both"
                                                             }}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col mdHidden={true} lgHidden={true} xs={12}
                                                         className="caracteristicas-descripcion">
                                                        <h4 className="tab-horizontal-item" style={{float: "left"}}
                                                            onClick={() => this.changeHorizontalTab("mapeo", 1, 1)}>{this.context.t('Mapeo')}</h4>
                                                    </Col>
                                                    <Col md="7" xs="12" className="caracteristicas-descripcion">
                                                        <p>
                                                            El sistema mapea en forma geo-referenciada junto con la
                                                            cobertura la dosis objetivo y real, la velocidad de
                                                            aplicación,
                                                            la presión de aplicación, la variables meteorológicas, etc.
                                                        </p>
                                                        <br/>
                                                        <b style={{color: "#eb0029"}}>
                                                            El mapeador incluye herramientas en pantalla para:
                                                        </b>
                                                        <ul className="lista-trilla">
                                                            <li>
                                                                Cálculo de área de lote
                                                            </li>
                                                            <li>
                                                                Trazado de líneas guía
                                                            </li>
                                                            <li>
                                                                Registro de puntos de referencia
                                                            </li>
                                                            <li>
                                                                Exportación de datos vía USB
                                                            </li>
                                                        </ul>
                                                        <br/>
                                                        <h4 className="no-hover">Puntos de interés</h4>
                                                        <br/>
                                                        <img className="img-responsive" src={valor1_2_1} alt=""
                                                             style={{
                                                                 maxWidth: "350px",
                                                                 margin: "-40px 0px -40px -10px",
                                                                 clear: "both"
                                                             }}/>
                                                    </Col>
                                                    <Col md="5" xs="12">
                                                        <img className="img-responsive" src={valor1_2_2} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>
                                                </div>

                                            </OwlCarousel>
                                        </Col>
                                    </Row>
                                </section>
                                <div className="red-line3" style={{margin: "80px auto"}}></div>
                                <section id="inteligencia">

                                    <Row>
                                        <div className="container-limit-md">
                                            <Col md={8}>
                                                <div className="caracteristicas-descripcion">
                                                    <h3>{this.context.t('Inteligencia')}</h3>
                                                    <div className="red-line3" style={{marginBottom:"0px"}}></div>
                                                </div>
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row style={{padding: "50px 20px"}} className="hidden-sm hidden-xs">
                                        <Col xsHidden={true} smHidden={true}>
                                            <Col md={4} className="tab-vertical">
                                                <div ref="calculadoraLi" className="tab-vertical-li in">
                                                    <a className="calculadora"
                                                       onClick={() => this.cambiarTabVertical("calculadora")}>
                                                        {this.context.t('Calculadora avanzada de pulverización')}
                                                    </a>
                                                    <Glyphicon id="calculadora" glyph="stop"/>
                                                </div>
                                                <div ref="pastillasLi" className="tab-vertical-li">
                                                    <a className="pastillas"
                                                       onClick={() => this.cambiarTabVertical("pastillas")}>
                                                        {this.context.t('Base de datos de pastillas')}
                                                    </a>
                                                    <Glyphicon id="pastillas" glyph="stop"/>
                                                </div>
                                                <div ref="bombaLi" className="tab-vertical-li">
                                                    <a className="bomba"
                                                       onClick={() => this.cambiarTabVertical("bomba")}>
                                                        {this.context.t('Control de dosis por bomba variable')}
                                                    </a>
                                                    <Glyphicon id="bomba" glyph="stop"/>
                                                </div>
                                                <div ref="caudalLi" className="tab-vertical-li">
                                                    <a className="caudal"
                                                       onClick={() => this.cambiarTabVertical("caudal")}>
                                                        {this.context.t('Control dual de caudal')}
                                                    </a>
                                                    <Glyphicon id="caudal" glyph="stop"/>
                                                </div>

                                            </Col>
                                            <Col md={8} style={{paddingTop: "50px"}} className="background">
                                                <div ref="calculadora" className="tab-vertical-body in">
                                                    <img className="tab-vertical-img" src={valor2_1}/>

                                                </div>
                                                <div ref="pastillas" className="tab-vertical-body">
                                                    <img className="tab-vertical-img" src={valor2_2}/>
                                                </div>
                                                <div ref="bomba" className="tab-vertical-body">
                                                    <Col md={4} xs={12} className="tab-vertical-horizontal">
                                                        <b ref="comparativa" className="tab-vertical-horizontal-item in"
                                                           onClick={() => this.changeVerticalHorizontalTab("comparativa")}>
                                                            {this.context.t('Comparativa entre métodos')}
                                                        </b>
                                                    </Col>
                                                    <Col md={4} xs={12} className="tab-vertical-horizontal">
                                                        <b ref="estabilidad" className="tab-vertical-horizontal-item"
                                                           onClick={() => this.changeVerticalHorizontalTab("estabilidad")}>
                                                            {this.context.t('Mayor estabilidad entre caudales')}
                                                        </b>
                                                    </Col>
                                                    <Col md={4} xs={12} className="tab-vertical-horizontal">
                                                        <b ref="disminucion" className="tab-vertical-horizontal-item"
                                                           onClick={() => this.changeVerticalHorizontalTab("disminucion")}>
                                                            {this.context.t('Disminución en la formación de espuma')}
                                                        </b>
                                                    </Col>
                                                    <img ref="comparativaImg" className="tab-vertical-horizontal-img in"
                                                         src={valor2_3_1}/>
                                                    <img ref="estabilidadImg" className="tab-vertical-horizontal-img"
                                                         src={valor2_3_2}/>
                                                    <img ref="disminucionImg" className="tab-vertical-horizontal-img"
                                                         src={valor2_3_3}/>
                                                </div>
                                                <div ref="caudal" className="tab-vertical-body">
                                                    <img className="tab-vertical-img" src={valor2_4}
                                                         style={{marginTop: "-65px"}}/>
                                                    <p>
                                                        {this.context.t(
                                                            'Sistema que detecta en tiempo real, anomalías en la presión de pulverización, informando con alarmas y registrando el evento. El sistema puede además, sugerir algunas verificaciones a realizar y evita equivocaciones en la configuración o elección de pastillas. Esta mejora aumenta la funcionalidad de la computadora de aplicación, genera una mejor precisión de aplicación y anticipa problemas.')}
                                                    </p>
                                                </div>

                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} mdHidden={true} lgHidden={true} className="center-block">
                                            <OwlCarousel
                                                ref="slider2"
                                                className="owl-theme"
                                                loop={false}
                                                nav={false}
                                                navText=""
                                                dots={true}
                                                margin={2}
                                                autoplay={false}
                                                autoplayHoverPause={true}
                                                autoplayTimeout={7000}
                                                autoplaySpeed={3500}
                                                responsive={
                                                    {
                                                        0: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 480 up
                                                        480: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        768: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        991: {
                                                            items: 1
                                                        }
                                                    }
                                                }
                                            >

                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="calculadora">
                                                            {this.context.t('Calculadora avanzada de pulverización')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <img className="img-responsive" src={valor2_1} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="pastillas">
                                                            {this.context.t('Base de datos de pastillas')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <img className="img-responsive" src={valor2_2} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="bomba">
                                                            {this.context.t('Control de dosis por bomba variable')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <br/>
                                                        <p>{this.context.t('Comparativa entre métodos')}</p>
                                                        <img className="img-responsive" src={valor2_3_1} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="bomba">
                                                            {this.context.t('Control de dosis por bomba variable')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <br/>
                                                        <p>{this.context.t('Mayor estabilidad entre caudales')}</p>
                                                        <img className="img-responsive" src={valor2_3_2} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="bomba">
                                                            {this.context.t('Control de dosis por bomba variable')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <br/>
                                                        <p>{this.context.t('Disminución en la formación de espuma')}</p>
                                                        <img className="img-responsive" src={valor2_3_3} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>

                                                </div>
                                                <div className="item">
                                                    <Col xs={12} className="tab-vertical-li">
                                                        <a className="cauda">
                                                            {this.context.t('Control dual de caudal')}
                                                        </a>
                                                    </Col>
                                                    <Col xs="12">
                                                        <img className="img-responsive" src={valor2_4} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                        <p>
                                                            <p>
                                                                {this.context.t(
                                                                    'Sistema que detecta en tiempo real, anomalías en la presión de pulverización, informando con alarmas y registrando el evento. El sistema puede además, sugerir algunas verificaciones a realizar y evita equivocaciones en la configuración o elección de pastillas. Esta mejora aumenta la funcionalidad de la computadora de aplicación, genera una mejor precisión de aplicación y anticipa problemas.')}
                                                            </p>
                                                        </p>
                                                    </Col>

                                                </div>


                                            </OwlCarousel>
                                        </Col>
                                    </Row>
                                </section>

                                <div className="red-line3" style={{margin: "80px auto"}}></div>

                                <Row>
                                    <Col md={8}>
                                        <div className="caracteristicas-descripcion">
                                            <h3>{this.context.t('Expansión y modularidad')}</h3>
                                            <div className="red-line3"></div>
                                            <p>
                                                {this.context.t('El sistema incluye diversos opcionales que se pueden adquirir de acuerdo a cada necesidad. La modularidad en la arquitectura de este sistema permite tratar por separado cada aplicación, permitiendo trasladar a otra máquina alguno de los módulos de manera individual, como por ejemplo el Piloto Automático.')}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <section className="seccion">
                                    <Row>
                                        <Col md={6} xsHidden={true} smHidden={true} className="caracteristicas-descripcion">
                                            <h4 ref="pulverizadora" className="tab-horizontal-item 2 in"
                                                style={{float: "right"}}
                                                onClick={() => this.changeHorizontalTab("pulverizadora", 2, 0)}>{this.context.t('Pulverizadora')}</h4>
                                            <br/>
                                        </Col>
                                        <Col md={6} xsHidden={true} smHidden={true}>
                                            <h4 ref="arneses" className="tab-horizontal-item 2" style={{float: "left"}}
                                                onClick={() => this.changeHorizontalTab("arneses", 2, 1)}>{this.context.t('Arneses y conexiones')}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="center-block">
                                            <OwlCarousel
                                                ref="slider2"
                                                className="owl-theme"
                                                loop={false}
                                                nav={false}
                                                navText=""
                                                dots={true}
                                                margin={2}
                                                autoplay={false}
                                                autoplayHoverPause={true}
                                                autoplayTimeout={7000}
                                                autoplaySpeed={3500}
                                                onDragged={(e) => this.changeSlider2(e)}
                                                responsive={
                                                    {
                                                        0: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 480 up
                                                        480: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        768: {
                                                            items: 1
                                                        },
                                                        // breakpoint from 768 up
                                                        991: {
                                                            items: 1
                                                        }
                                                    }
                                                }
                                            >
                                                <div className="item">
                                                    <Col mdHidden={true} lgHidden={true} xs={12}
                                                         className="caracteristicas-descripcion">
                                                        <h4 className="tab-horizontal-item" style={{float: "left"}}
                                                            onClick={() => this.changeHorizontalTab("arneses", 2, 1)}>{this.context.t('Arneses y conexiones')}</h4>
                                                    </Col>
                                                    <Col md="5" xs="12"
                                                         className="caracteristicas-descripcion listaIndice">
                                                        <p>
                                                            <b>1</b> Terminal Virtual
                                                        </p>
                                                        <p>
                                                            <b>2</b> ECU de Autopilot
                                                        </p>
                                                        <p>
                                                            <b>3</b> Sensor de posición de dirección
                                                        </p>
                                                        <p>
                                                            <b>4</b> Sistema hidráulico de dirección
                                                        </p>
                                                        <p>
                                                            <b>5</b> Válvula proporcional de dirección
                                                        </p>
                                                        <p>
                                                            <b>6</b> Sensor de presión hidráulica
                                                        </p>
                                                        <p>
                                                            <b>7</b> Sección del botalón con pastillas
                                                        </p>
                                                        <p>
                                                            <b>8</b> Sensor de presión de aplicación
                                                        </p>
                                                        <p>
                                                            <b>9</b> Válvulas de corte de sección
                                                        </p>
                                                        <p>
                                                            <b>10</b> ECU de Control de Aplicación
                                                        </p>
                                                        <p>
                                                            <b>11</b> Caudalímetro de aplicación
                                                        </p>
                                                        <p>
                                                            <b>12</b> Válvula reguladora de caudal
                                                        </p>
                                                        <p>
                                                            <b>13</b> Válvula de corte general (opcional)
                                                        </p>
                                                        <p>
                                                            <b>14</b> Válvula limitadora de presión
                                                        </p>
                                                        <p>
                                                            <b>15</b> Bomba de aplicación de producto
                                                        </p>
                                                        <p>
                                                            <b>16</b> Tanque de producto
                                                        </p>
                                                        <p>
                                                            <b>17</b> Sensor de nivel de batería
                                                        </p>
                                                        <p>
                                                            <b>18</b> Antena GPS
                                                        </p>
                                                        <p>
                                                            <b>19</b> Módulo de Telemetría M2M* (Opcional)
                                                        </p>
                                                    </Col>
                                                    <Col md="7" xs="12">
                                                        <img className="img-responsive" src={valor3_2} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>

                                                    </Col>
                                                </div>
                                                <div className="item">
                                                    <Col mdHidden={true} lgHidden={true} xs={12}
                                                         className="caracteristicas-descripcion">
                                                        <h4 className="tab-horizontal-item in"
                                                            onClick={() => this.changeHorizontalTab("pulverizadora", 2, 0)}>{this.context.t('Pulverizadora')}</h4>
                                                        <br/>
                                                    </Col>
                                                    <Col xs="12" md="4">
                                                        <img className="img-responsive" src={valor3_11} alt=""
                                                             style={{margin: "auto", clear: "both"}}/>
                                                    </Col>
                                                    <Col xs="12" md="8">
                                                        {/*<img className="img-responsive" src={valor3_12} alt=""*/}
                                                             {/*style={{margin: "auto", clear: "both"}}/>*/}
                                                        <ImgAmpliable photos={imagen} />
                                                    </Col>
                                                </div>

                                            </OwlCarousel>
                                        </Col>
                                    </Row>
                                </section>
                            </div>
                            <Iconos
                                icon1={icon1}
                                icon2={icon2}
                                icon3={icon3}
                                text1={this.context.t('Alta rentabilidad: Permite ahorrar hasta un 30% de insumos por hectárea. ')}
                                text2={this.context.t('Mayor precisión en las maniobras y operaciones.')}
                                text3={this.context.t('Expande la capacidad de trabajo de la máquina a horarios nocturnos, haciendo que la inversión pueda ser recuperada en un año de trabajo.')}
                            />

                        </div>

                        <Row>
                            <div className="video-guia" style={{display: "flex"}}>
                                <Col md={7} className="film-container bgGrey">
                                    <div className="descargas descarga-catalogo"
                                         style={{height: "100%", display: "flex", alignItems: "center"}}>
                                        <a target="_blank"
                                           href={process.env.PUBLIC_URL + "/archivos/sistemaWave/catalogoWave.pdf"}
                                           style={{margin: "0 auto"}}>
                                            <div className="btnDescarga">
                                                <img src={btnDescargaGrey} alt=""/>
                                                <div className="dscDescarga" style={{color: "#676868"}}>
                                                    <h3>{this.context.t('Catálogo')}</h3>
                                                    <h5>{this.context.t('Sistema Wave.')}</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Col>

                                <Col md={5}>
                                    <div className="descargas" style={{height: "100%"}}>
                                        <div className="bgGrey">
                                            <a target="_blank"
                                               href={process.env.PUBLIC_URL + "/archivos/sistemaWave/guiaRapidaWave.pdf"}>
                                                <div style={{height: "50%"}} className="btnDescarga">
                                                    <img src={btnDescargaGrey} alt=""/>
                                                    <div className="dscDescarga" style={{color: "#676868"}}>
                                                        <h3>{this.context.t('Guía Rápida')}</h3>
                                                        <h5>{this.context.t('Sistema Wave.')}</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="bgGrey2">
                                            <a target="_blank"
                                               href={process.env.PUBLIC_URL + "/archivos/sistemaWave/manualUsoWave.pdf"}>
                                                <div style={{height: "50%"}} className="bgGrisOsc">
                                                    <div className="btnDescarga">
                                                        <img src={btnDescarga} alt=""/>
                                                        <div className="dscDescarga">
                                                            <h3>{this.context.t('Manual de Uso')}</h3>
                                                            <h5>{this.context.t('Sistema Wave.')}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>

                        <Faqs faqs={faqs}/>

                    </section>
                </div>
                <SliderFinan id="financiacion" bgColor="white" color="#eb0029"/>
            </div>

        );
    }
}

export default SistemaWave;