import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import c from '../../constants';
import {Link} from "react-router-dom";

class PaginacionNoticias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cantPaginas: null,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        $.ajax({
            url: c.BASE_URL + "/paginacion",
            type: 'GET',
            cache: false,
            success: (data) => {
                this.setState({cantPaginas: data});

            }
        })
    }

    render() {
        let paginas = [];

        for (let i = 1; i <= this.state.cantPaginas ? this.state.cantPaginas : null; i++) {
            paginas.push(
                <li className={this.props.params == i ? "selected" : ""}>
                    <Link to={"/noticias/" + i}>
                        {i}
                    </Link>
                </li>
            )

        }

        return (
            <section className="paginacion">
                <Row>
                    <Col md={8} className="center-block">
                        <ul>
                            {paginas}
                        </ul>
                    </Col>
                </Row>

            </section>
        );
    }
}

export default PaginacionNoticias;