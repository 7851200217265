import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import '../../assets/css/LoaderNoticia.css';


class LoaderNoticia extends Component {

    render() {
        return (
            <section className="loader-noticia">
                <div className="titulo-loader">
                    <div className="h2-loader"></div>
                    <div className="imagen-loader"></div>
                </div>
                <Row style={{marginTop: "50px"}}>
                    <Col md={12}>
                        <div className="cuerpo-loader">
                            <div className="p-loader"></div>
                            <div className="p-loader" style={{width: "85%"}}></div>
                            <div className="p-loader" style={{width: "34%"}}></div>
                            <div className="p-loader" style={{width: "70%"}}></div>
                            <div className="p-loader" style={{width: "65%"}}></div>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default LoaderNoticia;