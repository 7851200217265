import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';

import Header from './layout/Header';
import Barra from './layout/Barra';
import Footer from './layout/Footer';
import BarraRes from "./layout/BarraRes";
import BarraResCel from "./layout/BarraResCel";

class App extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Sensor Tecnologia</title>
                    <meta name="twitter:card"
                          content="Sensor Tecnología es una empresa argentina perteneciente a Grupo Sensor que desde 1993 diseña, desarrolla y produce equipos electrónicos para la agricultura y el transporte."/>
                    <meta name="twitter:title" content="Sensor Tecnología"/>
                    <meta name="twitter:description"
                          content="Sensor Tecnología es una empresa argentina perteneciente a Grupo Sensor que desde 1993 diseña, desarrolla y produce equipos electrónicos para la agricultura y el transporte."/>
                    <meta name="twitter:image" content="http://www.sensortecnologia.com/vistas/images/sensor_tec.gif"/>
                    <meta property="og:type" content="website"/>
                    <meta property="fb:app_id" content="659565587823933"/>
                    <meta property="og:title"
                          content="Sensor Tecnología"/>
                    <meta property="og:description"
                          content="Sensor Tecnología es una empresa argentina perteneciente a Grupo Sensor que desde 1993 diseña, desarrolla y produce equipos electrónicos para la agricultura y el transporte."/>
                    <meta property="og:image:width" content="240"/>
                    <meta property="og:image:height" content="240"/>
                    <meta property="og:image"
                          content="http://www.sensortecnologia.com/vistas/images/sensor_tec.gif"/>
                    <meta property="og:url"
                          content="http://sensortecnologia.com/"/>
                </MetaTags>
                <Header/>
                <Barra/>
                <BarraRes/>
                <BarraResCel/>
                <div className="margen-superior">
                    {this.props.children}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default App;
