import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import {NavLink} from "react-router-dom";

//Images
import tecnoshaft from "../../assets/img/productos/tecnoshaft.png";
import pilotoAutomatico from "../../assets/img/productos/piloto2.png";
import multiplex30 from "../../assets/img/productos/multiplex3-0.png";
import wave from '../../assets/img/productos/sistemaWave/wave.png';

//sliders
import slide1 from "../../assets/img/bg-piloto.png";
// import slide1 from "../../assets/img/banner0.jpg";
import slide2 from "../../assets/img/slider-planta.png";
import slide3 from "../../assets/img/slider-tecnohaft.png";


import empresa from "../../assets/img/bg-empresa3.png";
import logo2 from "../../assets/img/logo2.png"

//CSS
import "../../assets/css/Home.css";

//Components
import Clientes from '../../componentes/elementos/Clientes';
import Noticias from "../elementos/Noticias";

class Home extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "Home - Sensor Tecnología";
        window.scrollTo(0, 0);

    }

    render() {
        return (
            <div>
                <section className="portada">
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        margin={0}
                        nav={true}
                        items={1}
                        dots={false}
                        navText=""
                        autoplay={true}
                        autoplayHoverPause={false}
                        autoplayTimeout={7000}
                        autoplaySpeed={2000}
                    >
                        <div class="item">
                            <NavLink to="/productos/piloto-automatico">
                                <div className="portada-item">
                                    <img src={slide1} alt=""/>
                                    <div className="portada-text">
                                        <h1>{this.context.t('Piloto Automático')}</h1>
                                        <h5>{this.context.t('Más tecnología, más rendimiento, más confiable')}</h5>
                                    </div>
                                </div>
                            </NavLink>
                            {/*<NavLink to="/productos/piloto-automatico">*/}
                                {/*<div className="portada-item">*/}
                                    {/*<img src={slide1} alt=""/>*/}
                                {/*</div>*/}
                            {/*</NavLink>*/}
                        </div>
                        <div class="item">
                            <div className="portada-item">
                                <img src={slide2}/>
                                <div className="portada-text">
                                    <h2>{this.context.t('Dominio del conocimiento')}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <NavLink to="/productos/tecnoshaft">
                                <div className="portada-item">
                                    <img src={slide3}/>
                                    <div className="portada-text">
                                        <h1>{this.context.t('Tecnoshaft')}</h1>
                                        <h5>{this.context.t('Más tecnología, más rendimiento, más confiable')}</h5>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </OwlCarousel>

                </section>
                <section className="black-bar">
                    <div className="container-limit">
                        <div className="container-categoria">
                            <div className="item-categoria">
                                <NavLink to="/productos/piloto-automatico" className="semibold">
                                    {this.context.t('Piloto Automático')}
                                </NavLink>
                            </div>
                            <div className="item-categoria">
                                <NavLink to="/categorias/soluciones-multiplex" className="semibold">
                                    {this.context.t('Sistema Multiplex')}
                                </NavLink>
                            </div>
                            <div className="item-categoria">
                                <NavLink to="/categorias/soluciones-electricas" className="semibold">
                                    {this.context.t('Soluciones Eléctricas')}
                                </NavLink>
                            </div>
                            <div className="item-categoria">
                                <NavLink to="/productos/modulo-solido" className="semibold">
                                    {this.context.t('Electrónica Avanzada')}
                                </NavLink>
                            </div>
                            <div className="item-categoria">
                                <NavLink to="/productos/consolas" className="semibold">
                                    {this.context.t('Consolas de comando')}
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="productos-destacados">
                    <div className="container-limit">
                        <Row>
                            <h2>{this.context.t('Productos Destacados')}</h2>
                        </Row>
                        <Row>
                            <Col md={6} sm={6}>
                                <div className="container-producto">
                                    <Link to="/productos/piloto-automatico">
                                        <h3>Piloto Automático</h3>
                                    </Link>
                                    <img src={pilotoAutomatico} alt=""/>
                                    <NavLink to="/productos/piloto-automatico" className="semibold">
                                        <Button>{this.context.t('Ver más')}</Button>
                                    </NavLink>
                                </div>
                            </Col>


                            <Col md={6} sm={6}>
                                <div className="container-producto">
                                    <Link to="/productos/tecnoshaft">
                                        <h3>Tecnoshaft</h3>
                                    </Link>
                                    <img src={tecnoshaft} alt=""/>
                                    <NavLink to="/productos/tecnoshaft" className="semibold">
                                        <Button>{this.context.t('Ver más')}</Button>
                                    </NavLink>
                                </div>
                            </Col>

                            <Col md={6} sm={6}>
                                <div className="container-producto">
                                    <Link to="/productos/sistema-wave">
                                        <h3>Sistema Wave</h3>
                                    </Link>
                                    <img src={wave} alt=""/>
                                    <NavLink to="/productos/sistema-wave" className="semibold">
                                        <Button>{this.context.t('Ver más')}</Button>
                                    </NavLink>
                                </div>
                            </Col>
                            <Col md={6} sm={6}>
                                <div className="container-producto">
                                    <Link to="/productos/multiplex-30">
                                        <h3>Multiplex 3.0</h3>
                                    </Link>
                                    <img src={multiplex30} alt=""/>
                                    <NavLink to="/productos/multiplex-30" className="semibold">
                                        <Button>{this.context.t('Ver más')}</Button>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <Noticias/>
                {/*<Consulta bgColor="#ec0928" color="white"/>*/}
                <section className="foto-sensor">
                    <img className="pull-right" src={logo2} alt=""/>
                </section>
                <Clientes/>
            </div>
        )
            ;
    }
}

export default Home;
