import React, {Component} from 'react';
import {Navbar, Nav, NavItem, Col, Row, NavDropdown, MenuItem} from 'react-bootstrap';
import {NavLink, Link} from 'react-router-dom';
import {Prompt} from 'react-router';

//Images
import logo from "../../assets/img/logo.png";

import dat from "../../assets/img/icon/dat.png";
import pilauto from "../../assets/img/icon/pilauto.png";
import monRen from "../../assets/img/icon/monRen.png";
import consola from "../../assets/img/icon/consolas.png";
import joystick from "../../assets/img/icon/joystick.png";
import tele from "../../assets/img/icon/telemetriaStrack.png";
import relayteras from "../../assets/img/icon/relayteras.png";
import cableadoElectrico from "../../assets/img/icon/cableadoElectrico.png";
import tecnohaft from "../../assets/img/icon/tecnohaft.png";
import parking from "../../assets/img/icon/parking.png";
import camaraVisionNocturna from "../../assets/img/icon/camaraVisionNocturna.png";
import multiplex12 from "../../assets/img/icon/multiplex1.2.png";
import multiplex15 from "../../assets/img/icon/multiplex1.5.png";
import multiplex25 from "../../assets/img/icon/multiplex2.5.png";
import multiplex30 from "../../assets/img/icon/multiplex3.0.png";
import moduloEstadoSolido from "../../assets/img/icon/moduloEstadoSolido.png";
import sistemaREC from "../../assets/img/icon/sistemaREC.png";
import sistemaPTP from "../../assets/img/icon/sistemaPTP.png";
import sensores from "../../assets/img/icon/sensores.png";
import multiplex20 from "../../assets/img/icon/multiplex2.0.png";
import sistemaWave from "../../assets/img/icon/sistemaWave.png";
import teclado from "../../assets/img/icon/teclado.png";

//CSS
import '../../assets/css/BarraResCel.css';

class BarraResCel extends Component {

    constructor() {
        super();
        let navExpanded = {};
        navExpanded.agricultura = false;
        navExpanded.transporte = false;
        navExpanded.transformacionVehicular = false;
        navExpanded.solucionesEspeciales = false;
        this.state = {navExpanded: navExpanded, navOpened: false, scrollTop: 0};
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        setTimeout(() => window.addEventListener('scroll', this.onScroll), 500);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll(e) {
        let scrollTop = e.srcElement.scrollingElement ? e.srcElement.scrollingElement.scrollTop : "";
        this.setState({scrollTop: scrollTop})
    }

    setNavExpanded(expanded, nombre) {
        let navExpanded = this.state.navExpanded;
        navExpanded[nombre] = expanded;
        this.setState({navExpanded: navExpanded});
    }

    closeNav() {
        let navExpanded = this.state.navExpanded;
        Object.keys(navExpanded).some((key) => {
            navExpanded[key] = false;
        });
        this.setState({navExpanded: navExpanded});
    }

    closeBarra() {
        this.setState({navOpened: !true});
    }

    toggleBarra() {
        if (this.state.navOpened == true) {
            this.setState({navOpened: false});
        } else {
            this.setState({navOpened: true});
        }


    }

    render() {
        return (
            <section className={`barraResCel ${this.state.scrollTop > 100 ? 'smaller' : ''}`}>
                <Prompt message={() => {
                    this.closeNav();
                    return true
                }}/>
                <div className="centerNav">
                    <Navbar expanded={this.state.navOpened}>
                        <Navbar.Header>
                            <Navbar.Toggle onClick={() => this.toggleBarra()}/>
                            <Navbar.Brand className="hidden-md hidden-lg hidden-sm">
                                <Link to="/">
                                    <img src={logo} alt=""/>
                                </Link>
                            </Navbar.Brand>
                        </Navbar.Header>
                        <Navbar.Collapse ref="navBarCollapse">
                            <Nav style={{paddingBottom: "8px"}} onSelect={() => this.closeBarra()}>

                                <NavDropdown open={this.state.navExpanded.agricultura}
                                             onToggle={(e, ev, s) => this.setNavExpanded(e, "agricultura")}
                                             eventKey={3}
                                             title="Agricultura" id="basic-nav-dropdown">

                                    <MenuItem eventKey={3.1}>
                                        <NavLink to="/categorias/agricultura-precision"
                                                 className="semibold" activeclassname="active">
                                            <b>Agricultura de Precisión</b>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/dat" activeclassname="active"
                                                 className="itemMenu">
                                            DAT
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/piloto-automatico"
                                                 activeclassname="active" className="itemMenu">
                                            Piloto Automático
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/monitor-rendimiento"
                                                 activeclassname="active" className="itemMenu">
                                            Monitor de Rendimiento
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/sistema-wave" activeclassname="active"
                                                 className="itemMenu">
                                            Sistema Wave
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/categorias/soluciones-fabricantes"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones para Fabricantes</b>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/cableado-electrico"
                                                 activeclassname="active" className="itemMenu">
                                            Cableados Eléctricos
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/consolas" activeclassname="active"
                                                 className="itemMenu">
                                            Consolas
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/joysticks" activeclassname="active"
                                                 className="itemMenu">
                                            Joysticks
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/s-trak" activeclassname="active"
                                                 className="itemMenu">
                                            Telemetría S-Trak
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/relayteras" activeclassname="active"
                                                 className="itemMenu">
                                            Relayteras
                                        </NavLink>
                                    </MenuItem>


                                </NavDropdown>

                                <NavDropdown open={this.state.navExpanded.transporte}
                                             onToggle={(e, ev, s) => this.setNavExpanded(e, "transporte")}
                                             eventKey={3}
                                             title="Transporte" id="transporte-nav-dropdown">

                                    <MenuItem eventKey={3.1}>
                                        <NavLink to="/categorias/productos-autonomos"
                                                 className="semibold" activeclassname="active">
                                            <b>Productos Autonomos</b>
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/tecnoshaft" activeclassname="active"
                                                 className="itemMenu">
                                            Tecnoshaft
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/parking" activeclassname="active"
                                                 className="itemMenu">
                                            Parking
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/parking" activeclassname="active"
                                                 className="itemMenu sub-item">
                                            Sensores de estacionamiento
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/parking" activeclassname="active"
                                                 className="itemMenu sub-item" style={{marginTop: "1px"}}>
                                            Cámaras de retroceso
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/s-trak" activeclassname="active"
                                                 className="itemMenu">
                                            Telemetría S-Trak
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/vision-nocturna"
                                                 activeclassname="active" className="itemMenu">
                                            Cámaras de Visión Nocturna
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/sistema-rec" activeclassname="active"
                                                 className="itemMenu">
                                            Sistema REC
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.3}>
                                        <NavLink to="/productos/adas" activeclassname="active"
                                                 className="itemMenu">
                                            Adas
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/categorias/soluciones-multiplex"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones Multiplex</b>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-12" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 1.2
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-15" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 1.5
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-20" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 2.0
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-25" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 2.5
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-30" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 3.0
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/modulo-solido" activeclassname="active"
                                                 className="itemMenu">
                                            Módulo de Estado Sólido
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.3}>
                                        <NavLink to="/categorias/soluciones-electricas"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones Eléctricas</b>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/cableado-electrico"
                                                 activeclassname="active" className="itemMenu">
                                            Cableados Eléctricos
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/teclados-paneles"
                                                 activeclassname="active" className="itemMenu">
                                            Teclados
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/relayteras" activeclassname="active"
                                                 className="itemMenu">
                                            Relayteras
                                        </NavLink>
                                    </MenuItem>

                                </NavDropdown>
                                <NavDropdown open={this.state.navExpanded.transformacionVehicular}
                                             onToggle={(e, ev, s) => this.setNavExpanded(e, "transformacionVehicular")}
                                             eventKey={3} title="Transformación Vehicular" id="trans-nav-dropdown">


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/categorias/soluciones-multiplex"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones Multiplex</b>
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-12" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 1.2
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-15" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 1.5
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-20" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 2.0
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-25" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 2.5
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/multiplex-30" activeclassname="active"
                                                 className="itemMenu">
                                            Multiplex 3.0
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/modulo-solido" activeclassname="active"
                                                 className="itemMenu">
                                            Módulo de Estado Sólido
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.3}>
                                        <NavLink to="/categorias/soluciones-electricas"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones Eléctricas</b>
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/cableado-electrico"
                                                 activeclassname="active" className="itemMenu">
                                            Cableados Eléctricos
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/teclados-paneles"
                                                 activeclassname="active" className="itemMenu">
                                            Teclados
                                        </NavLink>
                                    </MenuItem>

                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/relayteras" activeclassname="active"
                                                 className="itemMenu">
                                            Relayteras
                                        </NavLink>
                                    </MenuItem>


                                </NavDropdown>
                                <NavDropdown open={this.state.navExpanded.solucionesEspeciales}
                                             onToggle={(e, ev, s) => this.setNavExpanded(e, "solucionesEspeciales")}
                                             eventKey={3} title="Soluciones Especiales" id="soles-nav-dropdown">


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/categorias/soluciones-especiales"
                                                 className="semibold" activeclassname="active">
                                            <b>Soluciones Especiales</b>
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/joysticks" activeclassname="active"
                                                 className="itemMenu">
                                            Joysticks
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/s-trak" activeclassname="active"
                                                 className="itemMenu">
                                            Telemetría S-Trak
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/consolas" activeclassname="active"
                                                 className="itemMenu">
                                            Consolas
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/teclados-paneles"
                                                 activeclassname="active" className="itemMenu">
                                            Teclados
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/cableado-electrico"
                                                 activeclassname="active" className="itemMenu">
                                            Cableados Eléctricos
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/relayteras" activeclassname="active"
                                                 className="itemMenu">
                                            Relayteras
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/sensores" activeclassname="active"
                                                 className="itemMenu">
                                            Sensor Electrónico Indicador de Nivel
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/sistema-rec" activeclassname="active"
                                                 className="itemMenu">
                                            Sistema REC
                                        </NavLink>
                                    </MenuItem>


                                    <MenuItem eventKey={3.2}>
                                        <NavLink to="/productos/sistema-ptp" activeclassname="active"
                                                 className="itemMenu">
                                            Sistema PTP
                                        </NavLink>
                                    </MenuItem>


                                </NavDropdown>
                                <MenuItem style={{paddingBottom: "120px"}}>
                                    <NavLink to="/empresa" className="menuItem dropdown-modify"
                                             activeclassname="active">Sobre
                                        Sensor</NavLink>
                                </MenuItem>


                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </section>
        );
    }
}

export default BarraResCel;
