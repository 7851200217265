import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './componentes/App';
//import registerServiceWorker from './registerServiceWorker';
import {Switch} from 'react-router';
import {Router, Route} from 'react-router-dom'
import history from './history';
import I18n from "redux-i18n"
import {Provider} from 'react-redux'
import {store} from './store'
import {translations} from "./traducciones/traducciones"

// Import the CSS file, which webpack transfers to the build folder
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './assets/css/index.css';

// Import the components used as paginas
import Home from './componentes/paginas/Home';
import NotFound from './componentes/NotFound';
import Producto from './componentes/elementos/producto/Producto';
import ProductoStd from './componentes/elementos/producto/ProductoStandart';
import Empresa from './componentes/paginas/Empresa';
import Descargas from './componentes/paginas/Descargas';
import Soporte from './componentes/paginas/Soporte';
import AgriculturaPrecision from './componentes/categorias/AgriculturaPrecision';
import SolucionesFabricantes from './componentes/categorias/SolucionesFabricantes';
import ProductosAutonomos from './componentes/categorias/ProductosAutonomos';
import SolucionesElectricas from './componentes/categorias/SolucionesElectricas';
import SolucionesEspeciales from "./componentes/categorias/SolucionesEspeciales";
import Dat from "./componentes/productos/standarts/Dat";
import PilotoAutomatico from "./componentes/productos/destacados/PilotoAutomatico";
import MonitorRendimiento from "./componentes/productos/standarts/MonitorRendimiento";
import Tecnoshaft from "./componentes/productos/destacados/Tecnoshaft";
import CableadoElectrico from "./componentes/productos/standarts/CableadoElectrico";
import Consolas from "./componentes/productos/standarts/Consolas";
import Joystick from "./componentes/productos/standarts/Joystick";
import ModuloSolido from "./componentes/productos/standarts/ModuloSolido";
import Relayteras from "./componentes/productos/standarts/Relayteras";
import Strack from "./componentes/productos/standarts/Strack";
import SistemaWave from "./componentes/productos/destacados/SistemaWave";
import SistemaVisionNoc from "./componentes/productos/standarts/SistemaVisionNoc";
import Parking from "./componentes/productos/standarts/Parking";
import Multiplex15 from "./componentes/productos/standarts/Multiplex15";
import Multiplex12 from "./componentes/productos/standarts/Multiplex12";
import Multiplex20 from "./componentes/productos/standarts/Multiplex20";
import Multiplex25 from "./componentes/productos/standarts/Multiplex25";
import Multiplex30 from "./componentes/productos/destacados/Multiplex30";
import Adas from "./componentes/productos/standarts/Adas";
import Hololed from "./componentes/productos/standarts/Hololed";
import TecladosPaneles from "./componentes/productos/standarts/TecladosPaneles";
import SistemaPTP from "./componentes/productos/standarts/SistemaPTP";
import SistemaREC from "./componentes/productos/standarts/SistemaREC";
import Sensores from "./componentes/productos/standarts/Sensores";
import SolucionesMultiplex from "./componentes/categorias/SolucionesMultiplex";
import Contacto from "./componentes/paginas/Contacto";
import PagoProcesando from "./componentes/paginas/PagoProcesando";
import PagoConfirmado from "./componentes/paginas/PagoConfirmado";

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Noticia from "./componentes/elementos/Noticia";
import Noticias from "./componentes/elementos/NoticiasAll";


//Facebook Pixel
const advancedMatching = { em: ''}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

ReactPixel.init('1989653594691118', advancedMatching, options);

ReactPixel.pageView();

//Google Analytics
ReactGA.initialize('UA-114667828-1',{
    debug: true,
    titleCase: false,
});



ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.ga('trackerName.send', 'pageview', {'page': window.location.pathname});
history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

//ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
    //chau console.los metodo 4 de esta pagina https://stapp.space/how-to-remove-javascript-console-log-pros-2/
    (function () {
        var method;
        var noop = function noop() { };
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            console[method] = noop;
        }
    }());
}

ReactDOM.render(
    <Provider store={store}>
        <I18n translations={translations}>
            <Router history={history}>
                <App>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route path="/producto" component={Producto}/>
                        <Route path="/productoStd" component={ProductoStd}/>
                        <Route path="/empresa" component={Empresa}/>
                        <Route path="/descargas" component={Descargas}/>
                        <Route path="/soporte" component={Soporte}/>
                        <Route path="/contacto" component={Contacto}/>
                        <Route path="/pago-procesando" component={PagoProcesando}/>
                        <Route path="/pago-confirmado" component={PagoConfirmado}/>
                        <Route path="/contacto" component={Contacto}/>
                        <Route path="/categorias/agricultura-precision" component={AgriculturaPrecision}/>
                        <Route path="/categorias/soluciones-fabricantes" component={SolucionesFabricantes}/>
                        <Route path="/categorias/productos-autonomos" component={ProductosAutonomos}/>
                        <Route path="/categorias/soluciones-multiplex" component={SolucionesMultiplex}/>
                        <Route path="/categorias/soluciones-electricas" component={SolucionesElectricas}/>
                        <Route path="/categorias/soluciones-especiales" component={SolucionesEspeciales}/>
                        <Route path="/productos/dat" component={Dat}/>
                        <Route path="/productos/piloto-automatico" component={PilotoAutomatico}/>
                        <Route path="/productos/monitor-rendimiento" component={MonitorRendimiento}/>
                        <Route path="/productos/sistema-wave" component={SistemaWave}/>
                        <Route path="/productos/cableado-electrico" component={CableadoElectrico}/>
                        <Route path="/productos/consolas" component={Consolas}/>
                        <Route path="/productos/joysticks" component={Joystick}/>
                        <Route path="/productos/modulo-solido" component={ModuloSolido}/>
                        <Route path="/productos/relayteras" component={Relayteras}/>
                        <Route path="/productos/s-trak" component={Strack}/>
                        <Route path="/productos/tecnoshaft" component={Tecnoshaft}/>
                        <Route path="/productos/vision-nocturna" component={SistemaVisionNoc}/>
                        <Route path="/productos/parking" component={Parking}/>
                        <Route path="/productos/multiplex-12" component={Multiplex12}/>
                        <Route path="/productos/multiplex-15" component={Multiplex15}/>
                        <Route path="/productos/multiplex-20" component={Multiplex20}/>
                        <Route path="/productos/multiplex-25" component={Multiplex25}/>
                        <Route path="/productos/multiplex-30" component={Multiplex30}/>
                        <Route path="/productos/teclados-paneles" component={TecladosPaneles}/>
                        <Route path="/productos/sistema-ptp" component={SistemaPTP}/>
                        <Route path="/productos/sistema-rec" component={SistemaREC}/>
                        <Route path="/productos/sensores" component={Sensores}/>
                        <Route path="/productos/adas" component={Adas}/>
                        <Route path="/productos/hololed" component={Hololed}/>
                        <Route path="/noticia/:id" component={Noticia}/>
                        <Route path="/noticias/:page?" component={Noticias}/>
                        {/*<Route path="/monitorPedidos" component={MonitorPedidos} onEnter={checkAuth(store)}/>*/}
                        {/*<Route path="/pedido/:id" component={Pedido} onEnter={checkAuth(store)}/>*/}
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </App>
            </Router>
        </I18n>
    </Provider>,
    document.getElementById('root')
);

//registerServiceWorker();
