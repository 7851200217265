import React, {Component} from 'react';
import styled from 'styled-components';
import bg from '../../../assets/img/financiacion/bgPiloto.png';
import dude from '../../../assets/img/financiacion/dude.png';
import piloto from '../../../assets/img/financiacion/piloto.png';

const Container = styled.section`
    background-color: black;
    background-image: url(${bg});
    background-size: 90%;
    background-repeat: no-repeat;
    width: 100%;
    height: 850px;
    padding-top: 160px;
    
    @media(max-width:710px){
        height: inherit;
    }
    @media(max-width: 542px){
        padding-top: 60px;
    }
`;

const H3 = styled.h3`
    color: white;
    font-size: 80px;
    font-family: 'Gotham';
    margin-top:30px;
    margin-bottom: 50px;
    font-weight: 100;
    b {
        font-family: 'Gotham';
        font-weight: 600; 
    }
    
    @media(max-width: 710px){
        font-size: 60px;
    }
    
    @media(max-width: 542px){
        font-size: 30px;
    }
`;

const BarraChat = styled.div`
    position: relative;
    width: 100%;
    background: rgba(239,55,55,1);
    background: -moz-linear-gradient(left, rgba(239,55,55,1) 0%, rgba(111,26,26,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(239,55,55,1)), color-stop(100%, rgba(111,26,26,1)));
    background: -webkit-linear-gradient(left, rgba(239,55,55,1) 0%, rgba(111,26,26,1) 100%);
    background: -o-linear-gradient(left, rgba(239,55,55,1) 0%, rgba(111,26,26,1) 100%);
    background: -ms-linear-gradient(left, rgba(239,55,55,1) 0%, rgba(111,26,26,1) 100%);
    background: linear-gradient(to right, rgba(239,55,55,1) 0%, rgba(111,26,26,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef3737', endColorstr='#6f1a1a', GradientType=1 );
    p{
        font-family: "Myriad Pro";
        font-weight: 100;
        font-size: 31px;
        color: white;
        max-width: 390px;
        padding-top: 20px;
        text-shadow: 1px 1px 10px black;
    }
`;

const Button = styled.button`
    background-color: #ef3737;
    border: none;
    color: white;
    font-family: "Myriad Pro";
    font-weight: 100;
    padding: 5px 19px 5px 22px;
    margin-bottom: 23px;
    font-size: 16px;
    outline: none;
    img{
        padding-right: 15px;
    }
    -webkit-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    transition: all .2s ease-in-out;
    :hover{
        transform:scale(1.2);
    }
`;

const ContainerLimit = {
    maxWidth:"1150px",
    margin:"0 auto",
    paddingLeft: "25px",
    paddingRight: "15px"
};

const ImgCaja = styled.img`
    position: absolute;
    right: 0;
    top: -290px;
    @media(max-width: 1100px){
        top: -100px;
        max-height: 350px;    
    }
    
    @media(max-width: 710px){
        position:relative;
        top:10px;
    }
    
    
    
`;

class BannerFinanciacion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <Container id={this.props.id}>
                <div style={ContainerLimit}>
                <H3>MEJORAMOS <b>TU FINANCIACIÓN</b></H3>
                </div>
                <BarraChat>
                    <div style={ContainerLimit}>
                        <p>
                            Hacé click acá para <b>chatear</b> con un asistente
                        </p>
                        <Button onClick={() => this.props.onClick()}>
                            <img src={dude} alt=""/> <span>Recibí asistencia</span>
                        </Button>
                    </div>
                    <ImgCaja src={piloto} className="img-responsive"/>
                </BarraChat>

            </Container>
        );
    }
}

export default BannerFinanciacion;