import styled from "styled-components";

export const Container = {
    background: 'rgba(239,55,55,1)',
    background: '-moz-linear-gradient(top, rgba(239,55,55,1) 0%, rgba(199,46,46,1) 100%)',
    background: '-webkit-gradient(left top, left bottom, color-stop(0%, rgba(239,55,55,1)), color-stop(100%, rgba(199,46,46,1)))',
    background: '-webkit-linear-gradient(top, rgba(239,55,55,1) 0%, rgba(199,46,46,1) 100%)',
    background: '-o-linear-gradient(top, rgba(239,55,55,1) 0%, rgba(199,46,46,1) 100%)',
    background: '-ms-linear-gradient(top, rgba(239,55,55,1) 0%, rgba(199,46,46,1) 100%)',
    background: 'linear-gradient(to bottom, rgba(239,55,55,1) 0%, rgba(199,46,46,1) 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#ef3737", endColorstr="#c72e2e", GradientType=0 )',
    width: '100%',
    padding: '0'

};

export const Header = styled.section`
    display: flex;
    @media(max-width: 775px){
        .logo25{
            height: 114px !important;
        }
    }
`;

export const Contenido = styled.section`
    color: white;
    text-align: center;
    padding: 15px;
    h2{
        font-family: "Gotham";
        font-weight: 600;
        font-size: 35px;
        text-shadow: 2px 2px 10px #575757;
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;

export const P = styled.p`
    font-family: "Myriad Pro";
    max-width: 500px;
    font-weight: 100;
    margin: 0 auto;
    font-size: 17px;
`;

export const Button = styled.button`
    height: 38px;
    background-color: white;
    color: #606060;
    font-family: 'Myriad Pro';
    font-weight: 100;
    border:none;
    font-size:16px;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: auto;
    align-self: center;
    -webkit-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    box-shadow: 4px 4px 33px -10px rgba(0,0,0,0.75);
    transition: all .2s ease-in-out;
    
    :hover{
        transform:scale(1.2);
    }
`;

export const Info = styled.p`
    margin: 10px auto;
    margin-bottom: 0px;
    max-width: 350px;
    font-family: "Myriad Pro";
    font-weight: 100;
    font-size: 10px;
`;

export const Input = styled.input`
    display: inherit;
    margin: 35px auto;
    width: 100%;
    max-width: 608px;
    background: none;
    border: 1px solid white;
    height: 45px;
    color: white;
    font-weight: 100;
    padding: 10px;
    font-size: 25px;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: white;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: white;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: white;
    }
`;

export const Footer = styled.section`
    background-color:black;
    color:white;
    margin-top: 50px;
    display:flex;
    font-size: 16px;
    div{
       padding-left: 110px;
       margin: 35px auto;
    }
    @media(max-width: 625px){
        div{
            margin-left:auto;
        }
    }
    p{
        font-family: "Myriad Pro";
        font-weight: 100;
        margin: 0;
    }
    b{
        margin:0;
    }
    u{
        text-decoration-color: red;
        text-decoration: none;
        border-bottom: 1px solid red;
        letter-spacing: 1px;
    }
    img{
        position: absolute;
        display: flex;
        width: 300px;
        bottom: 0;  
    }
    
    @media(max-width: 775px){
        img{
            width: 250px;
        }
    }
    @media(max-width: 517px){
        img{
            width: 150px;
        }
    }
    @media(max-width: 436px){
        img{
            width: 100px;
        }
    }

`;


export const Cerrar = {
    position: 'absolute',
    top: '8px',
    left: '15px',
    color: 'white',
    fontWeight: '100',
    fontSize: '18px',
    opacity: '0.65',
    cursor: 'pointer',
    zIndex: '1',
}

