import React, {Component} from 'react';
import {Row, Col, FormGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import $ from 'jquery';
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import '../../assets/css/Contacto.css';

import FieldGroup from '../../componentes/camposTexto/FieldGroup';
import FieldSelect from '../../componentes/camposTexto/FieldSelect';
import Consulta from '../../componentes/elementos/Consulta';

import tel from "../../assets/img/iconWhite/tel5.png";
import face from "../../assets/img/iconWhite/face5.png";
import li from '../../assets/img/iconWhite/linkedin3.png';
import ig from '../../assets/img/iconWhite/ig3.png';
import whatsapp from '../../assets/img/iconWhite/whatsapp4.png';
import mail from '../../assets/img/iconWhite/mail4.png';
import spinner from '../../assets/img/spinner.gif';


class Contacto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                origen: "Contacto",
                nombre: "",
                email: "",
                consulta: "",
            },
            error: null,
            enviado: null,
            spinner: null

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    componentDidMount() {
        document.title = "Contacto - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: process.env.PUBLIC_URL + "/php/sendMail.php",
            // url: 'http://localhost/Sensor/php/sendMail.php',
            type: 'POST',
            data: this.state.form,
            // dataType: 'json',
            dataType: 'html',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function () {
                this.setState({spinner: true});
            }.bind(this),
            cache: false,
            success: function (data) {
                this.setState({enviado: true});
                this.setState({error: false});
                this.setState({spinner: false});
                var datos = {
                    content_category: 'contacto',
                    content_name: 'formulario-enviado'
                };
                ReactPixel.track('Lead', datos);

                ReactGA.event({
                    action: 'consultar',
                    category: 'contacto',
                    label: 'formulario-enviado'
                });
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                this.setState({error: true});
                this.setState({enviado: false});
                this.setState({spinner: false});
            }.bind(this)
        });
    }


    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    triggerAnalytics() {
        var data = {
            content_category: 'clics',
            content_name: 'clic-vias-de-contacto'
        };
        ReactPixel.track('Lead', data);

        ReactGA.event({
            action: 'consultar',
            category: 'clics',
            label: 'clic-vias-contacto'
        });
    }

    render() {
        return (
            <section id="contacto">
                <section className="contacto-datos">
                    <div className="container-limit">
                        <div className="titulo">
                            <h2>Contacto</h2>
                            <p>
                                Contáctenos a través de cualquiera de los siguientes medios y obtenga respuesta a todas
                                sus dudas.
                            </p>
                            <div style={{height: "5px", backgroundColor: "white", width: "60px", margin: "20px auto"}}/>
                        </div>

                        <Row>
                            <Col md={6}>
                                <section className="contacto-formulario">
                                    <Row>
                                        <div className="formulario">
                                            <div className="container-limit">
                                                <div className="form-limit">
                                                    <form
                                                        onSubmit={(e) => this.submitForm(e)}
                                                    >
                                                        <FieldGroup
                                                            id="nombre"
                                                            type="text"
                                                            placeholder="Nombre"
                                                            disabled={false}
                                                            value={this.state.form.nombre}
                                                            onChange={(e) => this.changeForm(e)}
                                                            required={true}
                                                        />
                                                        <FieldGroup
                                                            id="email"
                                                            type="email"
                                                            placeholder="E-mail"
                                                            disabled={false}
                                                            value={this.state.form.email}
                                                            onChange={(e) => this.changeForm(e)}
                                                            required={true}
                                                        />
                                                        <br/>
                                                        <FormGroup controlId="consulta">
                                                            <FormControl componentClass="textarea"
                                                                         placeholder="Consulta"
                                                                         style={{height: '150px'}}
                                                                         value={this.state.form.consulta}
                                                                         onChange={(e) => this.changeForm(e)}
                                                                         required={true}
                                                            />
                                                        </FormGroup>
                                                        {this.state.spinner
                                                            ?
                                                            <img src={spinner}
                                                                 style={{display: "block", margin: "20px auto"}}
                                                                 alt=""/>
                                                            :
                                                            <Button className="center-block btn btn-lg" type="submit">
                                                                Enviar
                                                            </Button>
                                                        }
                                                        <br/>
                                                        <b hidden={!this.state.error} style={{color: "red"}}>Ocurrió un
                                                            error, el
                                                            mensaje no se ha podido enviar</b>
                                                        <b hidden={!this.state.enviado} style={{color: "red"}}>Mensaje
                                                            enviado
                                                            correctamente</b>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </section>
                            </Col>
                            <Col md={6} className="linksDeContacto">
                                <div>
                                    <a href="" onClick={() => this.triggerAnalytics()}>
                                        <img src={whatsapp} alt=""/>
                                        <span>3476692615</span>
                                    </a>
                                    <span className="spanDerecha">WhatsApp</span>
                                </div>
                                <div>
                                    <a target="_blank" href="https://www.facebook.com/sensortecnologia/"
                                       onClick={() => this.triggerAnalytics()}>
                                        <img src={face} alt=""/><span>Sensor Tecnología</span>
                                    </a>
                                    <span className="spanDerecha">Facebook</span>
                                </div>
                                <div>
                                    <a target="_blank" href="https://www.instagram.com/sensortecnologia/"
                                       onClick={() => this.triggerAnalytics()}>
                                        <img src={ig} alt=""/><span>sensortecnologia</span>
                                    </a>
                                    <span className="spanDerecha">Instagram</span>
                                </div>
                                <div>
                                    <a target="_blank" href="https://www.linkedin.com/company/16217851/"
                                       onClick={() => this.triggerAnalytics()}>
                                        <img src={li} alt=""/><span>Sensor Tecnología</span>
                                    </a>
                                    <span className="spanDerecha">LinkedIn</span>
                                </div>

                                <div>
                                    <a href="tel:+543476461416" onClick={() => this.triggerAnalytics()}>
                                        <img src={tel} alt=""/>
                                        <span>+54-3476 461</span>
                                    </a>
                                    <span className="spanDerecha">Teléfono</span>
                                </div>

                                <div>
                                    <a href="mailto:ventas@sensortecnologia.com"
                                       onClick={() => this.triggerAnalytics()}>
                                        <img src={mail} alt=""/>
                                        <span>ventas@sensortecnologia.com</span>
                                    </a>
                                    <span className="spanDerecha">E-mail</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bannerEmpresa">
                </section>
            </section>
        );
    }
}

export default Contacto;