import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import '../../assets/css/LoaderNoticia.css';


class LoaderNoticias extends Component {

    render() {
        return (
            <section className="loader-noticias">
                <div className="titulo-loader">
                    <div className="imagen-loader"></div>
                    <div className="h2-loader"></div>
                    <div className="imagen-loader"></div>
                    <div className="h2-loader"></div>
                    <div className="imagen-loader"></div>
                    <div className="h2-loader"></div>
                    <div className="imagen-loader"></div>
                    <div className="h2-loader"></div>

                </div>
            </section>
        );
    }
}

export default LoaderNoticias;