import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import {withRouter, Link} from 'react-router-dom'

import '../../assets/css/Noticia.css';

import facebook from "../../assets/img/iconRed/facebookSR.png";
import whatsapp from "../../assets/img/iconRed/whatsappSR.png";
import twitter from "../../assets/img/iconRed/twitterSR.png";
import linkedin from "../../assets/img/iconRed/linkedin.png";

import c from '../../constants';
import history from '../../history';
import LoaderNoticia from "../Loader/LoaderNoticia";
import LoaderNoticias from "../Loader/LoaderNoticias";

class Noticia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noticias: null,
            noticiaPpal: null,
            fetching: null
        }

    }

    componentDidMount() {
        document.title = "Noticia - Sensor Tecnología";
        window.scrollTo(0, 0);
        $.ajax({
            url: c.BASE_URL + "/noticias",
            type: 'GET',
            cache: false,
            before: () => {
                this.setState({fetching: true})
            },
            success: (data) => {
                this.setState({noticias: data});
                this.setState({fetching: false})
            },
            error: (error) => {
            }
        });

        $.ajax({
            url: c.BASE_URL + "/noticia/" + this.props.match.params.id,
            type: 'GET',
            cache: false,
            success: (data) => {
                this.setState({noticiaPpal: data[0]});
            },
            error: (error) => {
            }
        });
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.match.params.id != this.props.match.params.id) {
            window.scrollTo(0, 0);
            $.ajax({
                url: c.BASE_URL + "/noticias",
                type: 'GET',
                cache: false,
                success: (data) => {
                    this.setState({noticias: data});
                },
                error: (error) => {
                }
            });

            $.ajax({
                url: c.BASE_URL + "/noticia/" + nextProps.match.params.id,
                type: 'GET',
                cache: false,
                success: (data) => {
                    this.setState({noticiaPpal: data[0]});
                },
                error: (error) => {
                }
            });
        }
    }

    goToNoticia(id) {
        history.push("/noticia/" + id);
    }

    render() {
        //URL from current page
        const url = window.location.href;
        //URL patterns for Social media sites share functionalities
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        const twitterUrl = `https://twitter.com/home?status=${url}`;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        const whatsAppUrl = `https://wa.me/?text=${url}`;

        const meta = {
            title: noticiaPpal ? JSON.parse(noticiaPpal.titulo).es : "Noticias Sensor",
            description: noticiaPpal ? JSON.parse(noticiaPpal.breve_descripcion).es : "",
            canonical: url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'sensor,sensortecnologia,agricultura,techagro,findtech,agricultura de precision'
                }
            }
        };

        let noticias = this.state.noticias ? this.state.noticias.map((noticia) => {
            return (
                <div className="mini-noticia">
                    <img
                        src={'http://gruposensor.com/upload/noticias/' + noticia.idnoticia + '/imagenes/small/' + noticia.img[0]}
                        alt=""
                        onClick={() => this.goToNoticia(noticia.idnoticia)}/>
                    <div className="container-mini-noticia">
                        <h4>{JSON.parse(noticia.titulo).es}</h4>
                        <div className="breve-descripcion"
                             dangerouslySetInnerHTML={{__html: JSON.parse(noticia.breve_descripcion).es.substring(0, 120) + "[...]"}}></div>
                    </div>
                </div>
            )
        }) : null;

        let noticiaPpal = this.state.noticiaPpal;
        let fechaNoticia = () => {
            let fechaOriginal = noticiaPpal ? noticiaPpal.fechanoticia : null;
            let fecha = "";
            if (fechaOriginal) {
                fecha = fechaOriginal.split("-");
                return fecha[2] + "-" + fecha[1] + "-" + fecha[0];
            }

        };

        return (
            <section className="section-noticia">
                <MetaTags>
                    <title>Sensor Tecnologia | Noticia</title>
                    <meta name="twitter:card"
                          content={noticiaPpal ? JSON.parse(noticiaPpal.breve_descripcion).es.substring(0, 120) + "[...]" : ""}/>
                    <meta name="twitter:title"
                          content={noticiaPpal ? JSON.parse(noticiaPpal.titulo).es : "Noticias Sensor"}/>
                    <meta name="twitter:description" content={noticiaPpal ? JSON.parse(noticiaPpal.cuerpo).es : ""}/>
                    <meta name="twitter:image"
                          content={'http://gruposensor.com/upload/noticias/' + (noticiaPpal ? noticiaPpal.idnoticia : "") + '/imagenes/big/' + (noticiaPpal ? noticiaPpal.img[0] : "")}/>
                    <meta property="og:type" content="website"/>
                    <meta property="fb:app_id" content="319209985386032"/>
                    <meta property="og:title"
                          content={noticiaPpal ? JSON.parse(noticiaPpal.titulo).es : "Noticias Sensor"}/>
                    <meta property="og:description"
                          content={noticiaPpal ? JSON.parse(noticiaPpal.breve_descripcion).es.substring(0, 120) + "[...]" : ""}/>
                    <meta property="og:image:width" content="240"/>
                    <meta property="og:image:height" content="240"/>
                    <meta property="og:image"
                          content={'http://gruposensor.com/upload/noticias/' + (noticiaPpal ? noticiaPpal.idnoticia : "") + '/imagenes/big/' + (noticiaPpal ? noticiaPpal.img[0] : "")}/>
                    <meta property="og:url"
                          content={"http://sensortecnologia.com/sensor-test/noticia/" + (noticiaPpal ? noticiaPpal.idnoticia : "")}/>
                </MetaTags>
                <div className="container-limit">
                    <Row>
                        <Col md={9} className="noticia">
                            {
                                this.state.fetching == true || this.state.fetching == null ? <LoaderNoticia/> :
                                    <div>
                                        <h3 style={{display: !noticiaPpal ? "block" : "none", color: "red"}}>No
                                            existe
                                            noticia</h3>
                                        <div style={{display: !noticiaPpal ? "none" : "block"}}>
                                            <p className="fecha">{fechaNoticia()}</p>
                                            <h2 className="tituloNoticia">{noticiaPpal ? JSON.parse(noticiaPpal.titulo).es : ""}</h2>
                                            <img className="big-img"
                                                 src={'http://gruposensor.com/upload/noticias/' + (noticiaPpal ? noticiaPpal.idnoticia : "") + '/imagenes/big/' + (noticiaPpal ? noticiaPpal.img[0] : "")}/>
                                            <div className="redesSociales">
                                                <a target="_blank" href={facebookUrl}><img src={facebook}/></a>
                                                <a target="_blank" href={twitterUrl}><img src={twitter}/></a>
                                                <a target="_blank" href={linkedinUrl}><img src={linkedin}/></a>
                                                <a target="_blank" href={whatsAppUrl}><img src={whatsapp}/></a>
                                            </div>

                                            <div className="linea-gris"></div>

                                            <p className="copeteNoticia">
                                                <div className="breve-descripcion"
                                                     dangerouslySetInnerHTML={{__html: noticiaPpal ? JSON.parse(noticiaPpal.cuerpo).es : ""}}>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                            }
                        </Col>
                        <Col md={3} className="masNoticias">
                            <div className="slider-noticias">
                                {
                                    this.state.fetching == true || this.state.fetching == null ? <LoaderNoticias/> :
                                        <div>
                                            <h2>Más noticias</h2>
                                            {noticias}
                                        </div>

                                }
                                <Link to={"/noticias"} style={{color:"white", marginLeft:"15px"}}>Ver todas ></Link>
                            </div>

                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}

export default withRouter(Noticia);