import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

//CSS
import '../../../assets/css/ProductoStandart.css';


//assets
import dobleProducto from '../../../assets/img/productos/dobleProducto2.png';
import portada from '../../../assets/img/productos/sensores.png';
import btnDescarga from '../../../assets/img/productos/btnDescarga.png';
import btnDescargaOsc from '../../../assets/img/productos/btnDescargaGrey.png';
import hr from '../../../assets/img/productos/hr.png';

//Import Components
import Galeria from '../../elementos/Galeria';
import SliderFinan from "../../elementos/SliderFinan";
import logo25 from '../../../assets/img/logo25prod.png';


//Import Imagenes Galeria
import foto1 from '../../../assets/img/galeria/sensores/foto1.png';
import foto2 from '../../../assets/img/galeria/sensores/foto2.png';
import foto3 from '../../../assets/img/galeria/sensores/foto3.png';
import foto4 from '../../../assets/img/galeria/sensores/foto4.png';

import valor1 from '../../../assets/img/valores/sensores/valor1.png';
import valor2 from '../../../assets/img/valores/sensores/valor2.gif';


const photos = [
    {src: foto1, width: 4, height: 3},
    {src: foto2, width: 4, height: 3},
    {src: foto3, width: 4, height: 3},
    {src: foto4, width: 4, height: 3}
];

class Sensores extends Component {
    componentDidMount() {
        document.title = "Sensores - Sensor Tecnología";
        window.scrollTo(0, 0);
    }

    realiceSuConsulta(){
        var data =  {
            content_category: 'clics',
            content_name: 'bt-realice-consulta'
        };
        ReactPixel.track( 'Lead', data );

        ReactGA.event({
            action:'consultar',
            category: 'clics',
            label: 'bt-realice-consulta'
        });
    }

    render() {
        return (
            <div>
                <section className="productos productos-std">
                    <div className="home-producto">
                        <Row>
                            <Col md={6}>
                                <div className="img-producto">
                                    <img className="img-responsive" src={portada} alt=""/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="tit-producto">
                                    <h1 style={{fontSize:"55px"}}>
                                        <span>{this.context.t('Sensor Electrónico Indicador de Nivel')}</span>
                                        <img src={logo25} alt=""/>
                                        </h1>
                                    <img src={hr} alt=""/>
                                    <p>{this.context.t('Más resistente, más específico, más intuitivo.')}</p>
                                    <p className="tit-dsc">{this.context.t('Sensor de nivel de tanque.')}</p>
                                    <p className="dsc">{this.context.t('Este producto es ideal para usar en ambientes y medios altamente agresivos. Es compatible con líquidos espumosos o que tienden a depositar sedimentos. Debido a la sobre-presión que admite es óptimo para usar en aplicaciones móviles y vehículares, donde el equipamiento es sometido a vibraciones y aceleraciones considerables.')}
                                    </p>
                                    <br/><br/>
                                    <Col md={6} className="no-padding-left">
                                        <Link to="/contacto" onClick={() => this.realiceSuConsulta()}>
                                            <Button>{this.context.t('Realice su consulta')}</Button>
                                        </Link>
                                    </Col>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="home-gallery">
                        <Galeria photos={photos}/>
                    </div>

                    <div className="productos-caracteristicas">
                        <div className="container-limit">
                            <Row>


                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Más prestaciones')}</h3>
                                        <div className="red-line3"></div>
                                        <p className="sinBorde">
                                            {this.context.t('La boquilla G 1 1⁄2” macho brinda posibilidades casi ilimitadas de fácil implementación. Permite el acceso rápido al sensor para inspección y mantenimiento. Este dispositivo puede instalarse horizontal o verticalmente sin afectar la precisión.')}
                                        </p>
                                    </div>

                                </Col>
                                <Col md={6} style={{paddingTop:"60px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor1} alt=""/>
                                    </div>
                                </Col>
                            </Row>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            <Row>
                                <Col md={6}>
                                    <div className="caracteristicas-descripcion">
                                        <h3>{this.context.t('Electrónica de última generación')}</h3>
                                        <div className="red-line3"></div>

                                        <ul className="lista-trilla">
                                            <li>Gran precisión.</li>
                                            <li>La mejor relación costo-efectividad.</li>
                                            <li>Alta resistencia química.</li>
                                            <li>Excelente rango de sobrepresión.</li>
                                            <li>Diseño robusto y compacto.</li>
                                            <li>Tolerancia al depósito de sedimentos.</li>
                                            <li>Compatible con líquidos espumosos.</li>
                                            <li>Independencia de la geometría del tanque.</li>
                                            <li>Inmunidad a ruido eléctrico.</li>
                                            <li>Resistencia a polvo y líquidos IP67.</li>
                                            <li>Compatibilidad dual 12~24 V.</li>
                                            <li>Protección contra pulsos eléctricos.</li>
                                            <li>Medición filtrada (anti-vibración).</li>
                                            <li>Datos de calibración y trazabilidad embebidos.</li>
                                            <li>Conversor altura-volumen integrado (opcional).</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6} style={{paddingTop:"100px"}}>
                                    <div className="img-graficos">
                                        <img className="img-responsive" src={valor2} alt=""/>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>

                    <Row>
                        <div className="descargas">
                            <Row>
                                <Col md={12} className="bgGrey">
                                    <a target="_blank"
                                       href={process.env.PUBLIC_URL + "/archivos/sensores/sensoresDeLiquido.pdf"}>
                                        <div className="btnDescarga" style={{paddingTop: "5%", paddingBottom: "5%"}}>
                                            <img src={btnDescargaOsc} alt=""/>
                                            <div className="dscDescarga" style={{color: "#676868"}}>
                                                <h3>{this.context.t('Catálogo')}</h3>
                                                <h5>{this.context.t('Sensores')}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Row>


                </section>

            </div>
        )
    }
}

export default Sensores;